import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';


const UpdateAds = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let {
    productsAjex,
    fetchProductsListAjex,
    categoriesAjex, fetchCategoriesAjex, plansAjex, fetchProductsAjex, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)

  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/advertisements/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const AdvertisementLocation = [
    { name: t('HOME'), value: 'HOME' },
    { name: t('PAGES'), value: 'PAGES' },
    // { name: 'Private', value: 'PRIVATE' },
  ];
  const AdvertisementService = [
    { name: t('NONE'), value: 'NONE' },
    { name: t('brand_name'), value: 'BRAND' },
    { name: t('BRAND_PRODUCT'), value: 'BRAND_PRODUCT' },
  ];
  const items = [
    { label: <Link to={'/ads'}>{t('ads')}</Link> },
    { label: <Link to={`/ads/edit/${id}`} className='p-2 border-round'>{t('Update')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/advertisements/edit/page/${id}`)
    if (data?.Success === true) {
      setData(data?.Response);
      console.log(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      AdvertisementLocation: Data?.AdvertisementLocation || '',
      AdvertisementService: Data?.AdvertisementService || '',
      AdvertisementImage: selectedImage,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, {
          AdvertisementLocation: values?.AdvertisementLocation,
          AdvertisementService: values?.AdvertisementService,
          IDLink: formik.values.AdvertisementService === "NONE" ? null : values?.IDLink,
          AdvertisementImage: values?.AdvertisementImage,
          IDAdvertisement: id
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/ads')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });
  useEffect(() => {
    formik.setFieldValue('AdvertisementImage', selectedImage);
  }, [selectedImage]);


  useEffect(() => {
    formik.setValues({
      AdvertisementLocation: Data?.AdvertisementLocation || '',
      AdvertisementService: Data?.AdvertisementService || '',

    });
    formik.setFieldValue('AdvertisementService', Data?.AdvertisementService);
    if (Data?.AdvertisementService == 'BRAND') {
      fetchBrandsAjex(Data?.IDLink)
      formik.setFieldValue('IDBrand', Data?.IDLink);
    } else if (Data?.AdvertisementService == 'BRAND_PRODUCT') {
      fetchBrandsAjex(Data?.IDLink)
      formik.setFieldValue('IDBrandProduct', Data?.IDLink);
      formik.setFieldValue('IDBrand', Data?.IDBrand);
      fetchProductsAjex(Data?.AdvertisementLocation, Data?.IDLink)
    }


  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid  mt-5  justify-content-center align-items-center">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
            <div className=" shadow-4 mt-3      flex justify-content-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
              <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                {formik.values.AdvertisementImage ? (
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(formik.values.AdvertisementImage)}
                    alt={formik.values.AdvertisementImage.name}
                    width='300px'
                    height='250px'
                    className=' mx-auto '
                    style={{ objectFit: 'contain' }}
                  />
                ) :
                  <>
                    <img
                      loading="lazy"
                      src={Data.AdvertisementImage}
                      alt={'default-image'}
                      className=' rounded-circle mx-auto '
                      style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                    />
                  </>
                }

              </div>
              <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />

                <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                  <i className='pi pi-image color-wight'></i>
                </label>
              </div>
            </div>
            <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{t('image')}</label>

          </div>
        </div>


        <div className="grid  mt-5  ">

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="AdvertisementLocation" className="font-bold block mb-2">
              {t('type')}
            </label>
            <Dropdown
              id="AdvertisementLocation"
              name="AdvertisementLocation"
              value={formik.values.AdvertisementLocation}
              options={AdvertisementLocation}
              optionLabel="name"
              onChange={(e) => {
                formik.setFieldValue("AdvertisementLocation", e.value)
                fetchBrandsAjex(e.value)
                fetchCategoriesAjex(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
              className="w-full p-inputtext-sm"
              required
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="AdvertisementService" className="font-bold block mb-2">
              {isLang === "en" ? 'Go To' : 'اذهب إلى'}

            </label>
            <Dropdown
              id="AdvertisementService"
              name="AdvertisementService"
              value={formik.values.AdvertisementService}
              options={AdvertisementService}
              optionLabel="name"
              onChange={(e) => {
                formik.setFieldValue("AdvertisementService", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a type' : 'اختر النوع'}
              className="w-full p-inputtext-sm"
              required

            />
          </div>

          {
            formik?.values?.AdvertisementService === "BRAND" &&
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
              <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('brand_name')}</label>
              <Dropdown filter
                options={brandsAjex?.map(item => ({
                  name: item.BrandName,
                  value: item.IDBrand,
                }))}
                id="IDBrand"
                name="IDBrand"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDBrand} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDBrand", e.value)
                  formik.setFieldValue("IDLink", e.value)
                }}

                onBlur={formik.handleBlur}
                placeholder={t('brand_placeholder')}

                className="w-full p-inputtext-sm"
              />
            </div>
          }

          {
            formik?.values?.AdvertisementService === "BRAND_PRODUCT" &&
            <>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('brand_name')}</label>
                <Dropdown filter
                  options={brandsAjex?.map(item => ({
                    name: item.BrandName,
                    value: item.IDBrand,
                  }))}
                  id="IDBrand"
                  name="IDBrand"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDBrand} // Add this line 
                  onChange={(e) => {
                    fetchProductsListAjex(e.value)

                    formik.setFieldValue("IDBrand", e.value)

                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t('brand_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>


              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('products')}</label>
                <Dropdown filter
                  options={productsAjex?.map(item => ({
                    name: item.BrandProductTitle,
                    value: item.IDBrandProduct,
                  }))}
                  id="IDBrandProduct"
                  name="IDBrandProduct"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDBrandProduct} // Add this line 
                  onChange={(e) => {
                    formik.setFieldValue("IDBrandProduct", e.value)
                    formik.setFieldValue("IDLink", e.value)
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t('products_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
            </>
          }
        </div>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="danger" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/ads'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateAds