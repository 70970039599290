
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';


const LogData = () => {
    let { id, type } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                  <InputText type="search"    className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/action/backlog`;
        let data = await axios.post(url, { IDPage: page + 1, IDLink: id, BackLogType: type },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.ActionBackLog);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchData();
    }, [isLang, page, id, type])

    const items = [
        { label: <Link  className='p-2 text-500' to={`${localStorage.getItem('logPath')}`}>  {t('BACK')}  </Link> },
        { label: <Link to={`/log/${id}/${type}`} className='p-2 border-round'> {t('log')}   </Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.created_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    return (
        <div className='mb-8 '>
            <div className="card">
                <BreadCrumb model={items} home={home} />

                <DataTable className="custom-datatable"
                    scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    showGridlines
                    rowHover
                    globalFilter={globalFilter}
                    header={header}>
                    <Column  field="UserName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="UserPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="ActionBackLogType" header={t('type')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="ActionBackLogDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="RoleName" header={t('role')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="created_at" header={t('created_at')} body={date} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

        </div>
    );
};


export default LogData


