import { Col, Image, Modal, Row } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ViewContract = ({ modalDone, setModalDone, id }) => {

    const { t } = useTranslation();

    const [documents, setDocuments] = useState([]);

    const fetchDocuments = async () => {
        setDocuments([]);
        if (id) {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/contracts/documents/${id}`);
                if (data?.Success === true) {
                    setDocuments(data?.Response);
                }
            } catch (error) {
                console.error('Error fetching documents:', error);
            }
        }
    };

    useEffect(() => {
        if (id) {
            fetchDocuments();
        }
    }, [id]);



    return (
        <Modal
            visible={modalDone}
            width="900px"
            title={t('contract')}
            onCancel={() => setModalDone(false)}
            footer={null}
            centered
        >
            <Row gutter={[16, 16]} className='overflow-hidden mb-4'>
                {documents?.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={8} >
                        <div className="image-item ">
                            <Image src={item.BrandContractDocumentPath} alt="document"
                                width="100%"
                                height={350}
                                style={{ objectFit: 'cover', borderRadius: '10px' }}
                            />
                        </div>
                    </Col>
                ))}
            </Row>
        </Modal>
    );
};

export default ViewContract;
