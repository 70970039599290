import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
import { Dropdown } from 'primereact/dropdown';
import AddGoogleMap from 'GoogleMap/AddGoogleMap';
import { InputNumber } from 'primereact/inputnumber';

const AddBranch = () => {
  let { t } = useTranslation()
  let { isLang, userLocationMap, setLoctionMap, setMarkers } = useContext(LocalizationContext);
  const [handelOpenPasswordNew, setHandelOpenPasswordNew] = useState('password');

  const extractCoordinates = (e) => {
    setLoctionMap(null)
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = e.match(regex);

    if (match) {
      setLoctionMap(null)
      const newMarker = { lat: Number(match[1]), lng: Number(match[2]) };
      console.log(newMarker);
      setMarkers([newMarker]);
      setLoctionMap(newMarker)

    } else {
      console.error("Invalid Google Maps URL");
    }
  };

  const [openMap, setOpenMap] = useState(false);
  const hideMapDialog = (data) => {
    setOpenMap(!openMap)
  }

  const { id } = useParams()
  let { fetchCountry,
    fetchCity,
    fetchArea,
    country,
    city,
    area, fetchBrandsAjex, brandsAjex } = useContext(FetchApi)

  const url = `${process.env.REACT_APP_API_URL}/branches/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={`/branch`}>{t('branchs')}   </Link> },
    { label: <Link to={`/branch/add`} className='p-2 border-round'>  {t('branch_add')}  </Link> }
  ];


  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      IDBrand: null,
      IDArea: null,
      BranchAddressEn: '',
      BranchAddressAr: '',
      BranchPhone: '',
      UserName: '',
      UserPhone: '',
      UserPhoneFlag: '+20',
      UserPassword: '',
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          IDBrand: values?.IDBrand,
          IDArea: values?.IDArea,
          BranchAddressEn: values?.BranchAddressEn,
          BranchAddressAr: values?.BranchAddressAr,
          BranchPhone: values?.BranchPhone,
          BranchLatitude: userLocationMap?.lat,
          BranchLongitude: userLocationMap?.lng,
          UserName: values?.UserName,
          UserPhone: values?.UserPhone,
          UserPhoneFlag: values?.UserPhoneFlag,
          UserPassword: values?.UserPassword,

        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/branch`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
  // Handle multi-image select

  useEffect(() => {
    fetchCity(1)
    fetchBrandsAjex()
    return () => {
      fetchBrandsAjex()
    }
  }, [isLang])
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid   ">


          <div className="lg:col-6 md:col-12 sm:col-12  mt-3">
            <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
            <Dropdown filter
              options={city?.map(item => ({
                name: item.CityName,
                value: item.IDCity,
              }))}
              id="IDCity"
              name="IDCity"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCity} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCity", e.value)
                fetchArea(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('city_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12  mt-3">
            <label htmlFor="IDArea" className="font-bold block mb-2"> {t('tabel-header-areas-1')}</label>
            <Dropdown filter
              options={area?.map(item => ({
                name: item.AreaName,
                value: item.IDArea,
              }))}
              id="IDArea"
              name="IDArea"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDArea} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDArea", e.value)

              }}
              onBlur={formik.handleBlur}
              placeholder={t('area_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12  mt-3">
            <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('brand_name')}</label>
            <Dropdown filter
              options={brandsAjex?.map(item => ({
                name: item.BrandName,
                value: item.IDBrand,
              }))}
              id="IDBrand"
              name="IDBrand"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDBrand} // Add this line 
              onChange={(e) => formik.setFieldValue("IDBrand", e.value)}
              onBlur={formik.handleBlur}
              placeholder={t('brand_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>


          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BranchPhone" className="font-bold block mb-2">{isLang === "en" ? 'Branch phone' : 'هاتف الفرع'}</label>
            <InputText
              name="BranchPhone"
              id="BranchPhone"
              className="w-full"
              inputClassName="p-inputtext-sm"
              onChange={(e) => {
                formik.setFieldValue("BranchPhone", e.target.value)
              }}
              onBlur={formik.handleBlur} 
              mode="decimal"
              useGrouping={false}
              dir="ltr" 
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BranchPhone" className="font-bold block mb-2">{t('tooltip_location')}</label>
            <div className="flex flex-row gap-2" >

              <Link className='location_branch  ' onClick={() => hideMapDialog()}>
                <Button label={t('location')} size='small' icon='pi pi-map-marker' tooltip={t('tooltip_location')} tooltipOptions={{ position: t('tooltip') }} severity='success' outlined className="mr-2" />
              </Link>

              <InputText
                type='text'
                className="w-full p-inputtext-sm"
                onChange={(e) => extractCoordinates(e.target.value)}
                placeholder={isLang === "en" ? "Paste Google Maps URL here" : 'الصق عنوان URL الخاص بخرائط جوجل هنا'}
              />
            </div>
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="UserName" className="font-bold block mb-2">  {isLang === "en" ? "User name" : 'الاسم بالكامل'}    </label>
            <InputText name='UserName' id="UserName" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12  mt-3 input__Col ">
            <label htmlFor="UserPhone" className="font-bold block mb-2">{isLang === "en" ? 'User phone' : 'هاتف المستخدم'}  </label>
            <InputNumber
              name="UserPhone"
              id="UserPhone"
              className="w-full"
              inputClassName="p-inputtext-sm"
              onValueChange={(e) => {
                formik.setFieldValue("UserPhone", '+20' + e.target.value)
              }}
              onBlur={formik.handleBlur}
              mode="decimal"
              useGrouping={false}
              dir="ltr"
              pattern="\d*"
              maxLength={10}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3  input__Col ">
            <label htmlFor="UserPassword" className="font-bold block mb-2">
              {isLang === "en" ? ' Password' : 'كلمة المرور الجديدة'}
            </label>
            <div className="password_open">
              <InputText
                id="UserPassword"
                name="UserPassword"
                type={handelOpenPasswordNew}
                value={formik.values.UserPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full  p-inputtext-sm"
                dir='ltr'
              />
              {
                handelOpenPasswordNew === "password" ?
                  <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPasswordNew('text')}  ></i> :
                  <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPasswordNew('password')}  ></i>
              }
            </div>
            {formik.touched.UserPassword && formik.errors.UserPassword ? (
              <div className="text-red-500">{formik.errors.UserPassword}</div>
            ) : null}
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BranchAddressEn" className="font-bold block mb-2">{t('address_en')}  </label>
            <InputTextarea
              name='BranchAddressEn'
              id="BranchAddressEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-3 input__Col" >
            <label htmlFor="BranchAddressAr" className="font-bold block mb-2">{t('address_ar')}  </label>
            <InputTextarea
              name='BranchAddressAr'
              id="BranchAddressAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />

          </div>
        </div>

        <div className="grid   ">

        </div>
        <div className="grid     ">

        </div>

        <AddGoogleMap
          openMap={openMap}
          hideMapDialog={hideMapDialog}
          isLang={isLang}
        />

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/branch`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>

      </form>

    </div>
  )
}

export default AddBranch