import { Breadcrumb, Card, Descriptions, Typography, Spin, Image, Row, Col, Empty } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const { Title, Text } = Typography;

const UpdateProducts = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true); // حالة التحميل

  const fetchData = async () => {
    setLoading(true); // بدء التحميل
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/edit/page/${id}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    });
    if (data?.Success) {
      setData(data?.Response);
      setDocuments(data?.Response?.BrandProductGallery);
    }
    setTimeout(() => {
      setLoading(false); // انتهاء التحميل
    }, 500);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };
  useEffect(() => {
    scrollToTop();
    fetchData();
  }, [i18n.language]);

  const formik = useFormik({
    initialValues: {
      BrandProductTitleEn: data?.BrandProductTitleEn || '',
      BrandProductTitleAr: data?.BrandProductTitleAr || '',
      BrandProductPrice: data?.BrandProductPrice || '',
      BrandProductPoints: data?.BrandProductPoints || '',
      BrandProductStartDate: data?.BrandProductStartDate || '',
      BrandProductEndDate: data?.BrandProductEndDate || '',
      BrandProductDescEn: data?.BrandProductDescEn || '',
      BrandProductDescAr: data?.BrandProductDescAr || '',
      BrandProductDiscountType: data?.BrandProductDiscountType || '',
      BrandProductDiscount: data?.BrandProductDiscount || '',
    },
  });

  useEffect(() => {
    
    formik.setValues({
      BrandProductTitleEn: data?.BrandProductTitleEn || '',
      BrandProductTitleAr: data?.BrandProductTitleAr || '',
      BrandProductPrice: data?.BrandProductPrice || '',
      BrandProductDiscount: data?.BrandProductDiscount || '',
      BrandProductPoints: data?.BrandProductPoints || '',
      BrandProductStartDate: data?.BrandProductStartDate || '',
      BrandProductEndDate: data?.BrandProductEndDate || '',
      BrandProductDescEn: data?.BrandProductDescEn || '',
      BrandProductDescAr: data?.BrandProductDescAr || '',
      BrandProductDiscountType: data?.BrandProductDiscountType || '',
    });
  }, [data, formik.setValues]);

  return (
    <div className="mb-8 cart_p0">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/products">{t('products')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('viewOffer')}</Breadcrumb.Item>
      </Breadcrumb>
 
      {loading ? (
        <Spin tip="Loading..." size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} />
      ) : (
        <Card bordered={false} className="mt-4">
          <Title level={3} style={{ color: '#F1592A' }}>{t('product_details')}</Title>
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }}
            contentStyle={{ fontSize: '14px', color: '#333' }}
          >
            <Descriptions.Item label={t('label_product_name_en')}>
              <Text>{formik.values.BrandProductTitleEn || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('label_product_name_ar')}>
              <Text>{formik.values.BrandProductTitleAr || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('price')}>
              <Text>{formik.values.BrandProductPrice || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('ClientRewardPoints')}>
              <Text>{formik.values.BrandProductPoints || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('label_product_Desc_en')}>
              <Text>{formik.values.BrandProductDescEn || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('label_product_Desc_ar')}>
              <Text>{formik.values.BrandProductDescAr || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>

          <Title level={3} style={{ color: '#F1592A' }}>{t('dates')}</Title>
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }}
            contentStyle={{ fontSize: '14px', color: '#333' }}
          >
            <Descriptions.Item label={t('start_date')}>
              <Text>{formik.values.BrandProductStartDate || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('end_date')}>
              <Text>{formik.values.BrandProductEndDate || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>



          <Title level={3} style={{ color: '#F1592A' }}>{t('discount')}</Title>
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }}
            contentStyle={{ fontSize: '14px', color: '#333' }}
          >
            <Descriptions.Item label={t('Discounttype')}>
              <Text>
                {formik.values.BrandProductDiscountType?.charAt(0).toUpperCase() +
                  formik.values.BrandProductDiscountType?.replace(/_/g, ' ').slice(1)?.toLowerCase() || 'N/A'}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('discount')}>
              <Text>{formik.values.BrandProductDiscount || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>

          {/* عرض معرض الصور مباشرة */}
          <Title level={3} style={{ color: '#F1592A' }}>{t('BrandProductGallery')}</Title>
          {documents.length === 0 ? (
            <Empty description={t('empty')} style={{ marginTop: '20px' }} />
          ) : (
            <Row gutter={[30, 30]} className="mt-4">
              (
              {documents?.map((item) => (
                <Col key={item.IDBrandGallery} xs={24} sm={12} md={8} lg={6}>
                  <Image
                    src={item.BrandProductPath}
                    alt="Brand image"
                    width="100%"
                    height={200}
                    style={{ objectFit: 'cover', borderRadius: '10px' }}
                    preview
                  />
                </Col>
              ))}
            </Row>
          )}

        </Card>
      )}
    </div>
  );
};

export default UpdateProducts;
