import { useContext, useEffect } from 'react';

import LogoSvg from "../../constants/LogoSvg";
import { FetchApi } from 'context/FetchApi';

const useRoutes = () => {
   

    const routes = [
        {
            path: "/",
            nameEn: "Dashboard",
            nameAr: "لوحة تحـــكم",
            icon: <i className="pi pi-chart-pie"></i>,  

        },
        {
            path: `/brands/profile/${localStorage.getItem('IDBrand')}`,
            nameAr: "العلامات التجارية",
            nameEn: "Brand profile",
            icon: <i className="pi pi-box"></i>, 
        },
        {
            path: `/reviews/${localStorage.getItem('IDBrand')}`,
            nameAr: "التعليقات",
            nameEn: "Reviews",
            icon: <i className="pi pi-comments"></i>, 
        },
        {
            path: `/brands/contract/${localStorage.getItem('IDBrand')}`,
            nameEn: "Contracts",
            nameAr: "عقـــود",
            icon: <i className="pi pi-file"></i>,  
        },
        {
            path: `/brands/gallery/${localStorage.getItem('IDBrand')}`,
            nameEn: "Gallerys",
            nameAr: "معرض",
            icon: <i className="pi pi-images"></i>, 
        },
        {
            path: `/brands/contacts/${localStorage.getItem('IDBrand')}`,
            nameEn: "Contacts",
            nameAr: "جهات الاتصال",
            icon: <i className="pi pi-phone"></i>, 
        },
        {
            path: "/branch",
            nameAr: "فــروع",
            nameEn: "Branch",
            icon: <i className="pi pi-map"></i>, 
        }, 

        {
            path: "/products",
            nameAr: "عــروض",
            nameEn: "Offers",
            icon: <i className="pi pi-gift"></i>, 

        },
    ];
    return {
        routes
    }
}


export default useRoutes;
