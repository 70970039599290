import axios from 'axios';
import img from 'constants/Img';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const RegisterClient = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const url = `http://backend.giveyapp.com/api/admin/clients/register`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    let {
        nationalitiesAjex,
        fetchnationalitiesAjex,
        fetchCity,
        fetchArea,
        city,
        area } = useContext(FetchApi)
    const ClientGender = [
        { name: 'Male', value: 'MALE' },
        { name: 'Female', value: 'FEMALE' },
        // { name: 'Private', value: 'PRIVATE' },
    ];
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');

    const ClientPrivacy = [
        { name: 'Yes', value: 1 },
        { name: 'No', value: 0 },
    ];

    const items = [
        { label: <Link to={'/client'}>{t('client_name')}   </Link> },
        { label: <Link to={'/client/add'} className='p-2 border-round'>  {t('register')}  </Link> }
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => {
        setSelectedImage(event.target.files[0]);
    };

    const [selectedNID, setSelectedNID] = useState(null);
    const handleSelectNID = (event) => {
        setSelectedNID(event.target.files[0]);
    };

    const [selectedNPassportID, setSelectedNPassportID] = useState(null);
    const handleSelectNPassportID = (event) => {
        setSelectedNPassportID(event.target.files[0]);
    };

    const [selectedNIDBack, setSelectedNIDBack] = useState(null);
    const handleSelectNIDBack = (event) => {
        setSelectedNIDBack(event.target.files[0]);
    };
    const home = { icon: 'pi pi-home', url: '/' };

    const formik = useFormik({
        initialValues: {
            ClientPhoneFlag: '+20',
            LoginBy: 'MANUAL',
            ClientAppLanguage: 'en',
            ClientName: '',
            ClientPhone: '',
            ClientSecondPhone: '',
            ClientNationalID: '',
            ClientPassword: '',
            ClientBirthDate: '',
            ClientGender: '',
            ClientPrivacy: 1,
            IDArea: '',
            ClientNameArabic: '',
            ClientPassport: '',
            ClientIDAddress: '',
            ClientCurrentAddress: '',
            ClientNationality: '',
            ClientPicture: selectedImage,
            ClientNationalIDImage: selectedNID,
            ClientNationalIDImageBack: selectedNIDBack,
            ClientPassportImage: selectedNPassportID
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            try {
                setLoading(true);
                let { data } = await axios.post(`https://backend.giveyapp.com/api/admin/clients/register`, values,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate(`/client/request/${data?.Response?.IDClient}`)
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        formik.setFieldValue('ClientPicture', selectedImage);
    }, [selectedImage]);

    useEffect(() => {
        formik.setFieldValue('ClientNationalIDImage', selectedNID);
    }, [selectedNID]);

    useEffect(() => {
        formik.setFieldValue('ClientPassportImage', selectedNPassportID);
    }, [selectedNPassportID]);

    useEffect(() => {
        formik.setFieldValue('ClientNationalIDImageBack', selectedNIDBack);
    }, [selectedNIDBack]);


    useEffect(() => {

        fetchCity(1)
        fetchnationalitiesAjex()
        return () => {
            fetchCity(1)
        }
    }, [isLang])
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
                <div className="grid  mt-5  ">
                    <div className='lg:col-3 md:col-12 sm:col-12 mt-2 flex justify-content-center align-items-center flex-column gap-4'>
                        <div className="mt-3   flex justify-content-center  m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                            {formik.values.ClientPicture ? (
                                <img
                                    loading="lazy"
                                    src={URL.createObjectURL(formik.values.ClientPicture)}
                                    alt={formik.values.ClientPicture.name}
                                    className=' border-round mx-auto '
                                    style={{ width: '300px', height: '200' }}
                                />
                            ) :
                                <>
                                    <img
                                        loading="lazy"
                                        src={img.DefaultImage}
                                        alt={'default-image'}
                                        className=' border-round mx-auto '
                                        style={{ width: '300px', maxHeight: '172' }}
                                    />
                                </>
                            }
                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input"
                                    accept="image/*"
                                    onChange={handleImageSelect}
                                    style={{ display: 'none' }}
                                />

                                <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                                    <i className='pi pi-image color-wight'></i>
                                </label>
                            </div>
                        </div>
                        <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{t('Client_Picture')}</label>
                    </div>
                    <div className='lg:col-3 md:col-12 sm:col-12 mt-2 flex justify-content-center align-items-center flex-column gap-4'>
                        <div className="mt-3  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                            {formik.values.ClientNationalIDImage ? (
                                <img
                                    loading="lazy"
                                    src={URL.createObjectURL(formik.values.ClientNationalIDImage)}
                                    alt={formik.values.ClientNationalIDImage.name}
                                    className=' border-round mx-auto '
                                    style={{ width: '300px', height: '200' }}
                                />
                            ) :
                                <>
                                    <img
                                        loading="lazy"
                                        src={img.DefaultImage}
                                        alt={'default-image'}
                                        className=' border-round mx-auto '
                                        style={{ width: '300px', maxHeight: '172' }}
                                    />
                                </>
                            }
                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input2"
                                    accept="image/*"
                                    onChange={handleSelectNID}
                                    style={{ display: 'none' }}
                                />

                                <label htmlFor="file-input2" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                                    <i className='pi pi-image color-wight'></i>
                                </label>
                            </div>

                        </div>
                        <label htmlFor="ClientNationalIDImage" className="font-bold text-xl block mb-2">{t('NationalID_front')}</label>
                    </div>
                    <div className='lg:col-3 md:col-12 sm:col-12 mt-2 flex justify-content-center align-items-center flex-column gap-4'>
                        <div className="mt-3  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                            {formik.values.ClientNationalIDImageBack ? (
                                <img
                                    loading="lazy"
                                    src={URL.createObjectURL(formik.values.ClientNationalIDImageBack)}
                                    alt={formik.values.ClientNationalIDImageBack.name}
                                    className=' border-round mx-auto '
                                    style={{ width: '300px', height: '200' }}
                                />
                            ) :
                                <>
                                    <img
                                        loading="lazy"
                                        src={img.DefaultImage}
                                        alt={'default-image'}
                                        className=' border-round mx-auto '
                                        style={{ width: '300px', maxHeight: '172' }}
                                    />
                                </>
                            }
                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input4"
                                    accept="image/*"
                                    onChange={handleSelectNIDBack}
                                    style={{ display: 'none' }}
                                />

                                <label htmlFor="file-input4" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                                    <i className='pi pi-image color-wight'></i>
                                </label>
                            </div>

                        </div>
                        <label htmlFor="ClientPassportImage" className="font-bold text-xl block mb-2">{t('NationalID_back')}</label>
                    </div>
                    <div className='lg:col-3 md:col-12 sm:col-12 mt-2 flex justify-content-center align-items-center flex-column gap-4'>
                        <div className="mt-3  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
                            {formik.values.ClientPassportImage ? (
                                <img
                                    loading="lazy"
                                    src={URL.createObjectURL(formik.values.ClientPassportImage)}
                                    alt={formik.values.ClientPassportImage.name}
                                    className=' border-round mx-auto '
                                    style={{ width: '300px', height: '200' }}
                                />
                            ) :
                                <>
                                    <img
                                        loading="lazy"
                                        src={img.DefaultImage}
                                        alt={'default-image'}
                                        className=' border-round mx-auto '
                                        style={{ width: '300px', maxHeight: '172' }}
                                    />
                                </>
                            }
                            <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                                <input
                                    type="file"
                                    id="file-input3"
                                    accept="image/*"
                                    onChange={handleSelectNPassportID}
                                    style={{ display: 'none' }}
                                />

                                <label htmlFor="file-input3" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                                    <i className='pi pi-image color-wight'></i>
                                </label>
                            </div>

                        </div>
                        <label htmlFor="ClientPassportImage" className="font-bold text-xl block mb-2">{t('ClientPassport_Image')}</label>
                    </div>

                </div>
                <div className="grid  mt-5  ">

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientName" className="font-bold block mb-2">{isLang === "en" ? 'Full name in english   ' : 'الاسم الكامل باللغة الانجليزية'}</label>
                        <InputText
                            name='ClientName'
                            id="ClientName"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientNameArabic" className="font-bold block mb-2">{isLang === "en" ? 'Full name in arabic ' : 'الاسم الكامل باللغة العربية'}</label>
                        <InputText
                            name='ClientNameArabic'
                            id="ClientNameArabic"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientPhone" className="font-bold block mb-2">{t('phone')}</label>
                        <InputNumber
                            name="ClientPhone"
                            id="ClientPhone"
                            className="w-full"
                            inputClassName="p-inputtext-sm"
                            onValueChange={(e) => {
                                formik.setFieldValue("ClientPhone", '+20' + e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            mode="decimal"
                            useGrouping={false}
                            dir="ltr"
                            pattern="\d*"
                            maxLength={10}
                            required
                        />
                    </div>


                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientSecondPhone" className="font-bold block mb-2">{t('Second_Phone')}</label>
                        <InputNumber
                            name="ClientSecondPhone"
                            id="ClientSecondPhone"
                            className="w-full"
                            inputClassName="p-inputtext-sm"
                            onValueChange={(e) => {
                                formik.setFieldValue("ClientSecondPhone", '+20' + e.target.value)
                            }}
                            onBlur={formik.handleBlur}
                            mode="decimal"
                            useGrouping={false}
                            dir="ltr"
                            pattern="\d*"
                            maxLength={10}
                        />

                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientEmail" className="font-bold block mb-2">{t('email')}</label>
                        <InputText
                            name='ClientEmail'
                            id="ClientEmail"
                            type='email'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            dir='ltr'
                             
                        />

                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <div className="input_form">
                            <label htmlFor="ClientPassword" className="font-bold block mb-2">{t('password')}</label>

                            <div className="password_open">
                                <InputText
                                    id="ClientPassword"
                                    name="ClientPassword"
                                    type={handelOpenPassword}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder={'enter your password'}
                                    className="w-full  p-inputtext-sm"
                                    dir='ltr'
                                    required

                                />
                                {
                                    handelOpenPassword === "password" ?
                                        <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                        <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>

                                }
                            </div>

                        </div>
                    </div>



                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >

                        <div className={`  input__Col    ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
                            <label htmlFor="ClientBirthDate" className="font-bold block mb-2">{t('BirthDate')}</label>
                            <Calendar
                                id="ClientBirthDate"
                                name="ClientBirthDate"
                                value={formik.values.ClientBirthDate ? new Date(formik.values.ClientBirthDate) : null}
                                placeholder={isLang === "en" ? ' Client Birth-Date' : '  تاريخ ميلاد   '}
                                onChange={(e) => {
                                    const formattedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                                    if (formattedDate !== null) {
                                        formik.setFieldValue("ClientBirthDate", formattedDate);
                                    }
                                }}
                                showIcon
                                className='w-full'
                                dateFormat="yy-mm-dd"  // Specify your desired date format here
                                required

                            />
                        </div>
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientGender" className="font-bold block mb-2">
                            {isLang === "en" ? ' Gender  ' : '  النوع '}
                        </label>
                        <Dropdown
                            id="ClientGender"
                            name="ClientGender"
                            value={formik.values.ClientGender}
                            options={ClientGender}
                            optionLabel="name"
                            onChange={(e) => formik.setFieldValue("ClientGender", e.value)}
                            onBlur={formik.handleBlur}
                            placeholder={isLang === "en" ? 'Select a Gender' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            required
                        />
                        {/* {!formik.values.ClientGender && <small className="p-error">This field is required.</small>} */}

                    </div>


                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
                        <Dropdown filter
                            value={formik.values.IDCity} // Add this line 
                            options={city?.map(item => ({
                                name: item.CityName,
                                value: item.IDCity,
                            }))}
                            id="IDCity"
                            name="IDCity"
                            optionLabel="name"
                            optionValue="value"
                            onChange={(e) => {
                                fetchArea(e.value)
                                formik.setFieldValue("IDCity", e.value)

                            }}
                            onBlur={formik.handleBlur}
                            placeholder={t('city_placeholder')}
                            className="w-full p-inputtext-sm"
                            required
                        />

                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="IDArea" className="font-bold block mb-2"> {t('tabel-header-areas-1')}</label>
                        <Dropdown filter
                            options={area?.map(item => ({
                                name: item.AreaName,
                                value: item.IDArea,
                            }))}
                            id="IDArea"
                            name="IDArea"
                            optionLabel="name"
                            optionValue="value"
                            value={formik.values.IDArea} // Add this line 
                            onChange={(e) => {
                                formik.setFieldValue("IDArea", e.value)
                            }}
                            onBlur={formik.handleBlur}
                            placeholder={t('area_placeholder')}
                            className="w-full p-inputtext-sm"
                            required
                        />

                    </div>


                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="IDNationality" className="font-bold block mb-2">{t('ClientNationality')}</label>
                        <Dropdown filter
                            options={nationalitiesAjex?.map(item => ({
                                name: isLang === "en" ? item.NationalityNameEn : item.NationalityNameAr,
                                value: item.IDNationality,
                            }))}
                            id="IDNationality"
                            name="IDNationality"
                            optionLabel="name"
                            optionValue="value"
                            value={formik.values.IDNationality} // Add this line 
                            onChange={(e) => {
                                formik.setFieldValue("IDNationality", e.value)
                            }}
                            onBlur={formik.handleBlur}
                            placeholder={t('ClientNationality')}
                            className="w-full p-inputtext-sm"
                            required
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientPassport" className="font-bold block mb-2">{t('Passport')}</label>
                        <InputText
                            name='ClientPassport'
                            id="ClientPassport"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            
                        />

                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientCurrentAddress" className="font-bold block mb-2">{t('addres')}</label>
                        <InputTextarea
                            name='ClientCurrentAddress'
                            id="ClientCurrentAddress"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientIDAddress" className="font-bold block mb-2">{t('ClientIDAddress')}</label>
                        <InputTextarea
                            name='ClientIDAddress'
                            id="ClientIDAddress"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            required
                        />
                    </div>


                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientNationalID" className="font-bold block mb-2">{t('NationalID')}</label>

                        <InputNumber
                            name="ClientNationalID"
                            id="ClientNationalID"
                            className="w-full"
                            inputClassName="p-inputtext-sm"
                            onBlur={formik.handleBlur}
                            onValueChange={formik.handleChange}
                            mode="decimal"
                            useGrouping={false}
                            dir="ltr"
                            pattern="\d*"
                            maxLength={14}
                            required
                        />
                    </div>


                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                        <label htmlFor="ClientPrivacy" className="font-bold block mb-2">
                            {isLang === "en" ? '   Privacy  ' : '  خصوصية '}
                        </label>
                        <Dropdown filter
                            id="ClientPrivacy"
                            name="ClientPrivacy"
                            value={formik.values.ClientPrivacy}
                            options={ClientPrivacy}
                            optionLabel="name"
                            onChange={(e) => formik.setFieldValue("ClientPrivacy", e.value)}
                            onBlur={formik.handleBlur}
                            placeholder={isLang === "en" ? 'Select a ClientPrivacy' : 'اختر النوع'}
                            className="w-full p-inputtext-sm"
                            required
                        />
                    </div>
                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={'/client'}>
                        <Button label={t('cancel')} raised severity="warning" type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div>
    )
}

export default RegisterClient