import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, Space, Table, Tag, Tooltip, notification } from 'antd';
import axios from 'axios';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const Products = () => { 
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagesNum, setPagesNum] = useState(0);
  const inputRef = useRef(null);

  const fetchData = async (searchKey = '') => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/brands/products`, {
        IDPage: page,
        SearchKey: searchKey,
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      });
      setData(response?.data?.Response?.BrandProducts || []);
      setPagesNum(response?.data?.Response?.Pages || 0);
    } catch (error) {
      notification.error({ message: t('Error'), description: t('Data fetching failed') });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };
  useEffect(() => {
    scrollToTop()
    fetchData();
  }, [i18n.language, page]);

  const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // Clear input
    scrollToTop()

  };

  const columns = [
    {
      title: t('tabel-header-Product-1'),
      dataIndex: 'BrandProductTitle',
      key: 'BrandProductTitle',
      align: 'center',
      width: 200, // Adjust width
      ellipsis: true,
    },
    {
      title: t('tabel_brand_header_2'),
      dataIndex: 'BrandProductDesc',
      key: 'BrandProductDesc',
      align: 'center',
      width: 300, // Adjust width for description
      ellipsis: true,
    },
    {
      title: t('tabel-header-Product-2'),
      dataIndex: 'SubCategoryName',
      key: 'SubCategoryName',
      align: 'center',
      width: 150, // Adjust width
    },
    {
      title: t('price'),
      dataIndex: 'BrandProductPrice',
      key: 'BrandProductPrice',
      align: 'center',
      width: 100, // Adjust width
    },
    {
      title: t('discount'),
      key: 'BrandProductDiscount',
      align: 'center',
      width: 150, // Adjust width
      render: (rowData) => (
        <span>
          {rowData?.BrandProductDiscountType === "VALUE" ? `${rowData?.BrandProductDiscount} ${t('currency')}` : `${rowData?.BrandProductDiscount} %`}
        </span>
      ),
    },
    {
      title: t('ClientRewardPoints'),
      dataIndex: 'BrandProductPoints',
      key: 'BrandProductPoints',
      align: 'center',
      width: 150, // Adjust width
    },
    {
      title: t('tabel_header_contract_1'),
      key: 'BrandProductStartDate',
      align: 'center',
      width: 150, // Adjust width
      render: (rowData) => <span>{format(new Date(rowData?.BrandProductStartDate), 'dd-MM-yyyy')}</span>,
    },
    {
      title: t('tabel_header_contract_2'),
      key: 'BrandProductEndDate',
      align: 'center',
      width: 150, // Adjust width
      render: (rowData) => <span>{format(new Date(rowData?.BrandProductEndDate), 'dd-MM-yyyy')}</span>,
    },
    {
      title: t('status'),
      key: 'BrandProductStatus',
      align: 'center',
      width: 100, // Adjust width
      render: (rowData) => (
        <Tag color={getSeverity(rowData.BrandProductStatus)}>
          {t(rowData?.BrandProductStatus === 'ACTIVE' ? 'active' : rowData?.BrandProductStatus === 'DEACTIVATED' ? 'deactivated' : 'pending')}
        </Tag>
      ),
    },
    {
      key: 'actions',
      align: 'center',
      width: 100, // Adjust width
      render: (rowData) => (
        <Space size="middle">
          <Tooltip title={t('viewOffer')}>
            <Link to={`/products/edit/${rowData.IDBrandProduct}`}>
              <Button icon={<EditOutlined />} />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const getSeverity = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'green';
      case 'DEACTIVATED':
        return 'red';
      case 'PENDING':
        return 'orange';
      default:
        return 'blue';
    }
  };

  return (
    <div>
      <Space className='flex justify-content-between mb-5'>
        <Button onClick={resetData} type="default" icon={<i className="pi pi-history" />}>
          {t('reset')}
        </Button>
        <Input.Search
          placeholder={t('search_placeholder')}
          enterButton
          allowClear
          onSearch={(value) => fetchData(value)}
          ref={inputRef}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="IDBrandProduct"
        pagination={false}
        bordered
        scroll={{ x: '100%' }}
      />
      <Pagination
        current={page}
        total={pagesNum * 10}
        pageSize={10}
        onChange={(page) => setPage(page)}
        style={{ marginTop: '16px', textAlign: 'right' }}
      />
    </div>
  );
};

export default Products;
