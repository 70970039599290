
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'primereact/paginator';
import { FetchApi } from 'context/FetchApi';
import { format } from 'date-fns';


const UserData = () => {
  let { role } = useContext(FetchApi)

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteusersDialog, setDeleteusersDialog] = useState(false);
  const [IdDeleteusers, setIdDeleteusers] = useState(null);
  const [Data, setData] = useState(null);
  const [deleteLoadind, setdeleteLoadind] = useState(false);
  const inputRef = useRef(null);

  // logcation path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  // Add new users
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/users/add">
          <Button severity="warning" label={isLang === "en" ? "Add user" : 'إضافة مستخدم '} icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="danger" icon="pi pi-history" size='small' />

      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        {
          role?.EDIT_USERS == 1 &&
          <>
            <Button icon="pi pi-trash" rounded outlined text raised severity="danger" onClick={() => confirmDeleteusers(rowData)} />
            <Link to={`/users/edit/${rowData.IDUser}`}>
              <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
            </Link>
          </>
        }
        {
          localStorage.getItem('Role') == 1 &&
          <Link to={`/log/${rowData.IDUser}/USER`}>
            <Button icon="pi pi-file" rounded outlined text raised severity="secondary" />
          </Link>
        }
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0"> </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          className="w-full p-inputtext-sm"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchData(e.target.value);
            }
            if (e.target.value == '') {
              fetchData(e.target.value);
            }
          }}
          placeholder={t('search_placeholder')}
          ref={inputRef}
        />
      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/users`;
    let data = await axios.post(url, { IDPage: page + 1, SearchKey },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Users);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchData();
  }, [isLang, page])



  // delete users
  const confirmDeleteusers = async (users) => {
    setDeleteusersDialog(true);
    setIdDeleteusers(users?.IDUser)
  };
  const deleteusers = async (rowData) => {
    setdeleteLoadind(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/users/status`, {
      IDUser: IdDeleteusers,
      UserStatus: 'DELETED'
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

        setTimeout(() => {
          setDeleteusersDialog(false);
          fetchData()
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const hideDeleteusersDialog = () => setDeleteusersDialog(false);
  const deleteusersDialogFooter = (
    <div className='btn_gapAr flex justify-content-center align-items-center'>
      <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
      <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
    </div>
  );
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
    // { label: t('pending'), value: 'PENDING' },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/users/status`, {
      IDUser: rowData?.IDUser,
      UserStatus: e.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.UserStatus === 'ACTIVE' ? t('active') : rowData?.UserStatus === 'INACTIVE' ? t('inactive') : t('pending')

    return editingRow === rowData?.IDUser ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.UserStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.UserStatus === 'ACTIVE' ? t('active') : rowData?.UserStatus === 'INACTIVE' ? t('inactive') : t('pending')}`}

        className="p-inputtext-sm "
      />
    ) : (
      <Tag

        severity={getSeverity(rowData.UserStatus)}
        onClick={() => {
          if (role?.EDIT_USERS == 1) {
            setEditingRow(rowData?.IDUser)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>{editingStatus}   </span>
          {
            (role?.EDIT_USERS == 1) &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';

      case 'ACTIVE':
        return 'success';

      case 'PENDING':
        return 'warning';

      case '':
        return 'info';
    }
  };
  const CreateDate = (rowData) => {
    const formattedDate = format(new Date(rowData?.CreateDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };
  const resetData = () => {
    fetchData(); 
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
  }

  return (
    <div className='mb-8 '>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          role?.ADD_USERS === 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }

        <DataTable className="custom-datatable"
          scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="UserName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="UserEmail" header={t('email')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="UserPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="UserStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="RoleName" header={t('role')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {/* <Column   field="UserRank" header={isLang === "en" ? "User Rank" : 'العنوان بالعربي '} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column field="CreateDate" header={t('CreateDate')} body={CreateDate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {
            (role?.EDIT_USERS == 1 || localStorage.getItem('Role') == 1) &&
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          }

        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
          </span>
        </div>
      </Dialog>
    </div>
  );
};

export default UserData;
