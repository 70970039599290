import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';

const ClientBonanza = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [Data2, setData2] = useState(null);

  const items = [
    { label: <Link to={'/client'}>{t('client_name')}   </Link> },
    { label: <Link to={`/client/bonanza/${id}`} className='px-3 py-2 border-round'>{t('Clients_bonanza')}</Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  let location = useLocation()

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      {
        location.pathname === `/client/details/${id}` &&
        <h4 className="m-0">{t('Clients_bonanza')}</h4>
      }
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const fetchData = async () => {
    
    const url = `${process.env.REACT_APP_API_URL}/bonanza/clients`;
    let data = await axios.post(url, { IDPage: page + 1, IDClient: id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.ClientBonanza);
    setData2(data?.data?.Response);
    setPagesNum(data?.data?.Response?.Pages)
  }

  useEffect(() => {
    fetchData();
  }, [isLang, page])

  return (
    <div >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="mt-3 mb-5">

        <BreadCrumb model={items} home={home} />
      </div>

      {
        location.pathname !== `/client/details/${id}` &&
        <div className="grid  p-1 ">

  
            <div className="lg:col-3 md:col-12 sm:col-12 mt-2   ">
              <div className="client_summary Sales_summary1   border-round shadow-3 py-2  px-4 flex flex-row  gap-4 align-items-center">
                <div className="icons">
                  <i className='pi pi-users'></i>
                </div>
                <div className="content flex flex-column">
                  <span className='number'>{t('Clients_bonanza')}</span>
                  <span className='header'>{Data2?.ClientBonanzaNumber?Data2?.ClientBonanzaNumber:'0'}</span>
                </div>
              </div>
            </div> 
        </div>
      }
      <div className="card">
        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="ClientName" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="ClientPhone" header={t('phone')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="BonanzaTitle" header={t('title')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="ClientLeftPoints" header={t('ClientLeftPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="ClientRightPoints" header={t('ClientRightPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="ClientTotalPoints" header={t('ClientTotalPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column  field="ClientProductValue" header={isLang === "en" ? ' Product value' : 'قيمة منتج  '} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column  field="ClientVisitNumber" header={isLang === "en" ? ' Visit number' : 'إجمالي الزيارات'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column  field="BrandVisit" header={isLang === "en" ? 'Brand visit' : 'زيارة العلامة التجارية'} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column  field="BonanzaReferralNumber" header={t('ReferralNumber')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />

      </div>

    </div>
  )
}

export default ClientBonanza