
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import SocialPlanProducts from '../SocialPlanProducts/SocialPlanProducts';

const PlanProducts = () => {
  let { role } = useContext(FetchApi)
  const inputRef = useRef(null);

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const [modalDone, setModalDone] = useState(false);
  const [dataSocialMedia, setdataSocialMedia] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);

  const handelSocialMedai = (data) => {
    setdataSocialMedia(data)
    setModalDone(true)

  }
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);

  // Add new plans
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/plan-products/add">
          <Button severity="warning" label={t('add_plan_product')} icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        {
          role?.EDIT_PLAN_PRODUCTS == 1 &&
          <Link to={`/plan-products/edit/${rowData.IDPlanProduct}`}>
            <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
          </Link>
        }
        <Button text raised onClick={() => handelSocialMedai(rowData)} className="disabled-button  " icon="pi pi-megaphone " severity="help" rounded outlined tooltip={t('product_SocialMedia')} tooltipOptions={{ position: t('tooltip') }} />
        {
          localStorage.getItem('Role') == 1 &&
          <Link to={`/log/${rowData.IDPlanProduct}/PLAN_PRODUCT`}>
            <Button icon="pi pi-file" rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} text raised />
          </Link>
        }
      </div>
    );
  };
  // location path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
      {/* <h4 className="m-0">{isLang === "en" ? "Search by name or mobile number or email " : 'بحث بالاسم او رقم جوال او بريد الالكتروني'} </h4> */}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/plans/products`;
    let data = await axios.post(url, { IDPage: page + 1, SearchKey },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setPagesNum(data?.data?.Response?.Pages)
    setData(data?.data?.Response?.PlanProducts);
  }
  useEffect(() => {
    fetchData();
  }, [isLang, page])

  const [editingRow, setEditingRow] = useState(null);
  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'InActive', value: 'INACTIVE' },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/plans/products/status`, {
      IDPlanProduct: rowData?.IDPlanProduct,
      PlanProductStatus: e.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {
      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.PlanProductStatus === "ACTIVE" ? t('active') : rowData?.PlanProductStatus === "INACTIVE" ? t('inactive') : t('pending');

    return editingRow === rowData?.IDPlanProduct ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.PlanProductStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.PlanProductStatus === "ACTIVE" ? t('active') : rowData?.PlanProductStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.PlanProductStatus)}
        onClick={() => {
          if (role?.EDIT_PLAN_PRODUCTS == 1) {
            setEditingRow(rowData?.IDPlanProduct)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>
            {`${rowData?.PlanProductStatus === "ACTIVE" ? t('active') : rowData?.PlanProductStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
          </span>
          {
            (role?.EDIT_PLAN_PRODUCTS == 1) &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };


  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';
      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'warning';
      case '':
        return 'info';
    }
  };
  const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
}
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          role?.ADD_PLAN_PRODUCTS == 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }
        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="PlanProductName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanProductPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanProductDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanProductPrice" header={t('price')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {/* <Column  field="PlanProductPoints" header={t('points')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          {/* <Column  field="PlanProductReferralPoints" header={t('ReferralPoints')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column  field="PlanProductUplinePoints" header={t('UplinePoints')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column field="PlanProductRewardPoints" header={isLang === "en" ? 'Reward Points' : "نقاط مكافأة"} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="AgencyNumber" header={t('AgencyNumber')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="CardNumber" header={t('card')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanName" header={t('PlanName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanProductAddress" header={t('addres')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="PlanProductStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>
        <div className="card">
          <Paginator dir='ltr'
            first={page * 10} // Calculate the first record index based on the page
            rows={10}
            totalRecords={pagesNum * 10}
            onPageChange={(e) => setPage(e.page)}
            template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
          />

        </div>
      </div>

      <SocialPlanProducts
        modalDone={modalDone}
        setModalDone={setModalDone}
        dataSocialMedia={dataSocialMedia}
        role={role}
      />

    </div>
  );
};


export default PlanProducts
