import { Layout, theme } from 'antd';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { GeneralData } from '../context/General';
import Navbar from 'Components/Navabar/Navbar';
import SideBar from 'Components/SideBar/SideBar';
import Footers from 'Components/Footer/Footer';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

function BetterWay() {
  const { token: { borderRadiusLG } } = theme.useToken();
  let { collapsed, setCollapsed } = useContext(GeneralData);
  const { i18n } = useTranslation();
  return (
    <>
      <Layout>
        <SideBar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Layout
          className="site-layout"
          style={{
            [i18n.language === 'ar' ? 'marginRight' : 'marginLeft']: collapsed ? 60 : 200,  // Adjust the margin for the content area
            transition: 'margin 0.2s ease-in-out',
          }}
        >
          <Navbar />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: '100vh',
              background: '#fff',
              borderRadius: borderRadiusLG,
              overflow: 'initial'
            }}
          >
            <Outlet />
          </Content>
          <Footers />
        </Layout>
      </Layout>
    </>
  )
}

export default BetterWay
