import { Breadcrumb, Card, Descriptions, Typography, Spin } from 'antd';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import ShowGoogleMap from 'GoogleMap/ShowGoogleMap';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const { Title, Text } = Typography;

const UpdateBranch = () => {
  const { id } = useParams();
  const { isLang, setLoctionMap } = useContext(LocalizationContext);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // حالة التحميل

  const { fetchCity, fetchArea, city, area } = useContext(FetchApi);

  const fetchData = async () => {
    setLoading(true); // بدء التحميل
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/branches/edit/page/${id}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tokenBetter')}`,
      }
    });
    if (response.data?.Success) {
      setData(response.data?.Response);
      setUser(response.data?.Response?.BranchUser);
      fetchCity(1);
      fetchArea(response.data?.Response?.IDCity);
    }
    setTimeout(() => {
      setLoading(false); // انتهاء التحميل
    }, 500);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };
  useEffect(() => {
    scrollToTop()
    fetchData('');
  }, [i18n.language]);

  useEffect(() => {
    setLoctionMap(null);
    if (data) {
      const newMarker = { lat: data?.BranchLatitude, lng: data?.BranchLongitude };
      setLoctionMap(newMarker);
    }
  }, [data]);

  return (
    <div className="mb-8 cart_p0">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/branch">{t('branchs')}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('branch_edit')}</Breadcrumb.Item>
      </Breadcrumb>

      {/* عرض مؤثر التحميل عند تحميل البيانات */}
      {loading ? (
        <Spin tip="Loading..." size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} />
      ) : (
        <Card bordered={false} className="mt-4">
          <Title level={3} style={{ color: '#F1592A' }}>{t('branch_details')}</Title>
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }}
            contentStyle={{ fontSize: '14px', color: '#333' }}
          >
            <Descriptions.Item label={t('tabel-header-cities-1')}>
              <Text>{city?.find(item => item.IDCity === data?.IDCity)?.CityName || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('tabel-header-areas-1')}>
              <Text>{area?.find(item => item.IDArea === data?.IDArea)?.AreaName || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('address_en')}>
              <Text>{data?.BranchAddressEn || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('address_ar')}>
              <Text>{data?.BranchAddressAr || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('phone')}>
              <Text>{data?.BranchPhone || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>

          <Title level={3} style={{ color: '#F1592A' }}>{t('contact_details')}</Title>
          <Descriptions
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }}
            contentStyle={{ fontSize: '14px', color: '#333' }}
          >
            <Descriptions.Item label={isLang === "en" ? "User name" : 'الاسم بالكامل'}>
              <Text>{user?.UserName || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={isLang === "en" ? 'User phone' : 'هاتف المستخدم'}>
              <Text>{user?.UserPhone || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>

          <Title level={3} style={{ color: '#F1592A' }}>{t('location_details')}</Title>
          <ShowGoogleMap isLang={isLang} />

        </Card>
      )}
    </div>
  );
};

export default UpdateBranch;
