import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
const AddSubCategoryAccount = () => {
    let { isLang } = useContext(LocalizationContext);
    let { categoriesAccountAjex, fetchCategoriesAccountAjex } = useContext(FetchApi)
  
    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/subcategories/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
  
  
    const items = [
      { label: <Link to={'/subcategory_account'}>  {t('subcategories_title')}  </Link> },
      { label: <Link to={'/subcategory_account/add'} className='p-2 border-round'> {t('subcategories_add')}   </Link> }
    ];
  
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => {
      setSelectedImage(event.target.files[0]);
    };
    const home = { icon: 'pi pi-home', url: '/' };
  
    const formik = useFormik({
      initialValues: {
        SubCategoryNameEn: '',
        SubCategoryNameAr: '', 
        IDCategory: ''
      },
      onSubmit: async (values, { resetForm }) => {
        try {
          setLoading(true);
          let { data } = await axios.post(url, values,
            {
              headers: {
                // 'Content-Type': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
              },
            })
  
          if (data?.Success) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
            setTimeout(() => {
              setLoading(false);
              navigate('/subcategory_account')
              resetForm();
            }, 1000);
          } else if (!data?.Success) {
            toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
            setLoading(false);
          } else if (data?.status === 400) {
            toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
  
            setLoading(false);
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
          }
  
        } catch ({ response }) {
          console.error(response.data.message);
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
          setLoading(false);
        }
      }
    });
  
 
    useEffect(() => {
  
      fetchCategoriesAccountAjex()
  
      return () => {
        fetchCategoriesAccountAjex()
      }
    }, [isLang])
    return (
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
  
        <BreadCrumb model={items} home={home} />
        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
    
          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="SubCategoryNameEn" className="font-bold block mb-2">{t('label_subcategories_nameEn')}</label>
              <InputText
                name='SubCategoryNameEn'
                id="SubCategoryNameEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SubCategoryNameAr" className="font-bold block mb-2">{t('label_subcategories_nameAr')}</label>
              <InputText
                name='SubCategoryNameAr'
                id="SubCategoryNameAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                dir='ltr'
              />
            </div>
          </div>
          <div className="grid  mt-3  ">
            <div className="lg:col-6 md:col-12 sm:col-12      ">
              <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('categories_title')}</label>
              <Dropdown filter  
                options={categoriesAccountAjex?.map(item => ({
                  name: item.CategoryName,
                  value: item.IDCategory,
                }))}
                id="IDCategory"
                name="IDCategory"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDCategory} // Add this line 
                onChange={(e) => formik.setFieldValue("IDCategory", e.value)}
                onBlur={formik.handleBlur}
                placeholder={t('Category_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div>
  
          </div>
          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button raised icon="pi pi-check" loading={loading} severity="warning" label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={'/subcategory_account'}>
              <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>
    )
  }
export default AddSubCategoryAccount
