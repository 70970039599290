import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
const BonanzaBrand = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const visitNum = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteusersDialog, setDeleteusersDialog] = useState(false);
  const [Data, setData] = useState(null);
  let { role } = useContext(FetchApi)

  const items = [
    { label: <Link to={'/bonanza'}>{t('bonanza_name')}</Link> },
    { label: <Link to={`/bonanza/brands/${id}`} className='px-3 py-2 border-round'>{t('brand_bonanza')}</Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };


  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">  </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );

  const fetchData = async () => {

    const url = `${process.env.REACT_APP_API_URL}/bonanza/brands`;
    let data = await axios.post(url, {
      IDBonanza: id
    },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response);
  }

  useEffect(() => {
    fetchData();
  }, [isLang])

  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 1 },
    { label: t('inactive'), value: 0 },
  ];

  const onStatusChange = async (rowData, e) => {

    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/bonanza/brands/status`, {
      IDBrand: Number(rowData?.IDBrand),
      IDBonanza: Number(id),
      BonanzaStatus: 1
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success === true) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BonanzaBrandDeleted === 1 ? t('active') : rowData?.BonanzaBrandDeleted === 0 ? t('inactive') : t('pending')

    return editingRow === rowData?.IDBrand ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.BonanzaBrandDeleted)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.BonanzaBrandDeleted === 1 ? t('active') : rowData?.BonanzaBrandDeleted === 0 ? t('inactive') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.BonanzaBrandDeleted)}
        onClick={() => {
          if (role?.EDIT_BONANZA == 1) {
            setEditingRow(rowData?.IDBrand)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>
            {`${rowData?.BonanzaBrandDeleted === 1 ? t('active') : rowData?.BonanzaBrandDeleted === 0 ? t('inactive') : t('pending')}`}
          </span>
          {
            role?.EDIT_BONANZA == 1 &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center image_prime p-4' >

      <Image
        preview
        src={rowData.BrandLogo}
        alt={rowData.BrandLogo}
        width='100px'
        height='100px'
        className='   overflow-hidden  '
        style={{ objectFit: 'cover', borderRadius: '50%' }}
      />
      {/* <img src={rowData.BrandLogo} alt={rowData.BrandLogo} className="shadow-2" style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }} /> */}
    </div>
  };


  const getSeverity = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case '':
        return 'info';
    }
  };


  const [brandDetailes, setBrandDetailes] = useState(null);
  const [deleteLoadind, setdeleteLoadind] = useState(false);


  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        <Button icon="pi pi-pencil" rounded outlined text raised severity="warning" onClick={() => confirmDeleteusers(rowData)} />
      </div>
    );
  };
  // delete users
  const confirmDeleteusers = async (users) => {
    setBrandDetailes(null)
    setDeleteusersDialog(true);
    setBrandDetailes(users)
  };
  const deleteusers = async () => {
    setdeleteLoadind(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/bonanza/brands/status`, {
      IDBonanza: id,
      IDBrand: brandDetailes?.IDBrand,
      BonanzaStatus: 0,
      BonanzaBrandVisitNumber: visitNum?.current.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

        setTimeout(() => {
          setDeleteusersDialog(false);
          fetchData()
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const hideDeleteusersDialog = () => setDeleteusersDialog(false);
  const deleteusersDialogFooter = (
    <div className='btn_gapAr flex justify-content-center align-items-center'>
      <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
      <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
    </div>
  );
  return (
    <div >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="mt-3 mb-5">

        <BreadCrumb model={items} home={home} />
      </div>
      <div className="card">
        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="BrandLogo" header={t('logo')} body={imageBodyTemplate}></Column>
          <Column field="BrandName" header={t('tabel_brand_header_1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BonanzaBrandDeleted" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BonanzaBrandVisitNumber" header={t('visits')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {
            role?.EDIT_BONANZA == 1 &&
            <Column body={actionBodyTemplate} header={isLang === "en" ? 'Change visit number' : ''} exportable={false} style={{ minWidth: '12rem' }}></Column>
          }
        </DataTable>
        <Dialog
          dir={isLang == "en" ? 'ltr' : 'rtl'}
          visible={deleteusersDialog}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang == "en" ? 'Change visits num' : 'تأكيد الحذف'}
          modal
          footer={deleteusersDialogFooter}
          onHide={hideDeleteusersDialog}>
          <InputText
            name='BonanzaTitleEn'
            id="BonanzaTitleEn"
            type='text'
            className="w-full  p-inputtext-sm"
            ref={visitNum}
            defaultValue={brandDetailes?.BonanzaBrandVisitNumber}
          />
        </Dialog>

      </div>

    </div>
  )
}

export default BonanzaBrand
