import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import ViewContract from './ViewContract';
import { FetchApi } from 'context/FetchApi';
import { Button, Table, Select, Input, Tag, Tooltip, Breadcrumb, Space, Pagination, notification } from 'antd';

const BrandContract = () => {
  const { id } = useParams();
  const { isLang } = useContext(LocalizationContext);
  const { t, i18n } = useTranslation();
  const { role } = useContext(FetchApi);
  const [data, setData] = useState([]);
  const [modalDone, setModalDone] = useState(false);
  const [dataContract, setDataContract] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handleContract = (record) => {
    setDataContract(record.IDBrandContract);
    setModalDone(true);
  };

  const columns = [
    {
      title: t('tabel_header_contract_1'),
      dataIndex: 'BrandContractStartDate',
      render: (text, record) => format(new Date(record.BrandContractStartDate), 'dd-MM-yyyy'),
      align: 'center',
      width: 200,
      ellipsis: true,
    },
    {
      title: t('tabel_header_contract_2'),
      dataIndex: 'BrandContractEndDate',
      render: (text, record) => format(new Date(record.BrandContractEndDate), 'dd-MM-yyyy'),
      align: 'center',
      width: 200,
    },
    {
      title: isLang === "en" ? 'Number Months' : 'أشهر عقد',
      dataIndex: 'BrandContractMonths',
      align: 'center',
      width: 200,
    },
    {
      title: t('tabel_header_contract_3'),
      dataIndex: 'BrandContractAmount',
      align: 'center',
      width: 200,
    },
    {
      title: t('status'),
      dataIndex: 'BrandContractStatus',
      render: (text, record) => (
        <Tag color={getSeverity(record.BrandContractStatus)}>{t(getStatusLabel(record.BrandContractStatus))}</Tag>
      ),
      align: 'center',
      width: 200,
    },
    {
      title: '',
      render: (_, record) => (
        <Space>
          <Tooltip title={t('contract')}>
            <Button icon={<i className="pi pi-file" />} onClick={() => handleContract(record)} />
          </Tooltip>
          {role?.EDIT_BRANDS === 1 && (
            <Link to={`/brands/contract/${id}/edit/${record.IDBrandContract}`}>
              <Button icon={<i className="pi pi-pencil" />} />
            </Link>
          )}
        </Space>
      ),
      align: 'center',
      width: 100,
    },
  ];

  const fetchData = async (searchKey = '') => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/brands/contracts`, {
        IDPage: page,
        IDBrand: id,
        SearchKey: searchKey,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('tokenBetter')}`,
        },
      });
      setData(response.data.Response.Brands);
      setTotalPages(response.data.Response.Pages);
    } catch (error) {
      notification.error({ message: t('Error fetching data') });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };
  useEffect(() => {
    scrollToTop()
    fetchData(globalFilter);
  }, [page, i18n.language]);

  const getSeverity = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'EXPIRED':
        return 'error';
      case 'PENDING':
        return 'warning';
      case 'DEACTIVATED':
        return 'default';
      default:
        return 'default';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'active';
      case 'EXPIRED':
        return 'expired';
      case 'PENDING':
        return 'pending';
      case 'DEACTIVATED':
        return 'deactivated';
      default:
        return 'pending';
    }
  };

  const handleSearch = (value) => {
    setGlobalFilter(value);
    fetchData(value);
  };

  return (
    <div>
      <Input.Search
        placeholder={t('search_placeholder')}
        onSearch={handleSearch}
        style={{ marginBottom: 16, width: 200 }}
      />
      <Table
        columns={columns}
        dataSource={data}
        rowKey="IDBrandContract"
        pagination={false}
        bordered
        scroll={{ x: '100%' }}
      />
      <Pagination
        current={page}
        total={totalPages * 10}
        pageSize={10}
        onChange={(page) => setPage(page)}
        style={{ marginTop: 16, textAlign: 'center' }}
      />
      <ViewContract modalDone={modalDone} setModalDone={setModalDone} id={dataContract} />
    </div>
  );
};

export default BrandContract;
