import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { LocalizationContext } from 'context/LangChange';
import { Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';

const libraries = ['places'];

const ShowGoogleMap = ({ hideMapDialog, openMap, isLang }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '500px',
    borderRadius: '8px',
  };
  const { userLocationMap } = useContext(LocalizationContext);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk',
    libraries,
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <Modal
      visible={openMap}
      onCancel={hideMapDialog}
      title={isLang === 'en' ? 'Google map' : 'تحديد موقعك'}
      footer={null}
      width="1000px"
      style={{ direction: isLang === 'en' ? 'ltr' : 'rtl' }}
    >
      <div>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={userLocationMap}
        >
          {userLocationMap && <Marker position={userLocationMap} />}
        </GoogleMap>
      </div>
    </Modal>
  );
};

export default ShowGoogleMap;
