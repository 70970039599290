import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const AddNationalities = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/nationalities/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()


    const items = [
        { label: <Link to={'/nationalities'}>{t('nationalities')}   </Link> },
        { label: <Link to={'/nationalities/add'} className='p-2 border-round'>  {t('add_nationalities')}  </Link> }
    ];

    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => {
        setSelectedImage(event.target.files[0]);
    };
    const home = { icon: 'pi pi-home', url: '/' };

    const formik = useFormik({
        initialValues: {
            NationalityNameEn: '',
            NationalityNameAr: '',
        },
        onSubmit: async (values, { resetForm }) => {

            try {
                setLoading(true);
                let { data } = await axios.post(url, values,
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        navigate('/nationalities')
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        formik.setFieldValue('nationalitiesIcon', selectedImage);
    }, [selectedImage]);
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                <div className="grid  mt-5  ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="NationalityNameEn" className="font-bold block mb-2">{t('nationalities_en')}</label>
                        <InputText
                            name='NationalityNameEn'
                            id="NationalityNameEn"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>

                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <label htmlFor="NationalityNameAr" className="font-bold block mb-2">{t('nationalities_ar')}</label>
                        <InputText
                            name='NationalityNameAr'
                            id="NationalityNameAr"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>

                </div>

                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={'/nationalities'}>
                        <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div>
    )
}


export default AddNationalities
