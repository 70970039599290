import { notification, Table } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SummaryClient from './SummaryClient';

const BrandContacts = () => {
    const { id } = useParams(); 
    const { t, i18n } = useTranslation();

    const [data, setData] = useState(null);
    const [sales, setSales] = useState(null);
    const [globalFilter, setGlobalFilter] = useState("");


    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/brands/contacts`;
        try {
            const response = await axios.post(url, { IDBrand: id }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('tokenBetter')}`,
                }
            });
            setData(response?.data?.Response?.BrandContacts);
            setSales(response?.data?.Response?.Sales);
            console.log(response?.data?.Response?.Sales);

        } catch (error) {
            notification.error({ message: t('Error'), description: 'Data fetching failed' });
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enables smooth scrolling
        });
    };

    useEffect(() => {
        scrollToTop()
        fetchData();
    }, [i18n.language]);


    const columns = [
        {
            title: t('BrandContactName'),
            dataIndex: 'BrandContactName',
            key: 'BrandContactName',
            align: 'center',
        },
        {
            title: t('BrandContactPhone'),
            dataIndex: 'BrandContactPhone',
            key: 'BrandContactPhone',
            align: 'center',
        },
        {
            title: t('title'),
            dataIndex: 'BrandContactTitle',
            key: 'BrandContactTitle',
            align: 'center',
        },
    ];

    return (
        <div>
            <SummaryClient data={sales} />
            <div className="mt-4">
                <Table
                    columns={columns}
                    dataSource={data?.filter((item) =>
                        item.BrandContactName.toLowerCase().includes(globalFilter.toLowerCase())
                    )}
                    rowKey="IDBrandContact"
                    bordered
                    pagination={{ pageSize: 10 }}
                    scroll={{ x: '100%' }}
                />

            </div>
        </div>
    );
};

export default BrandContacts;
