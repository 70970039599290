import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AddPlanData = () => {

  let { fetchSubCategoriesAjex, fetchBrandsAjex } = useContext(FetchApi)
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/plans/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [openImanes, setOpenImanes] = useState(false);

  //!hide images modal 
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)

  const items = [
    { label: <Link to={`/plans`}>{t('title_plans')}   </Link> },
    { label: <Link to={`/plans/add`} className='p-2 border-round'>  {t('add_plans')}  </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  const cities = [
    { name: 'السبت', value: 'SATURDAY' },
    { name: 'الأحد', value: 'SUNDAY' },
    { name: 'الاثنين', value: 'MONDAY' },
    { name: 'الثلاثاء', value: 'TUESDAY' },
    { name: 'الأربعاء', value: 'WEDNESDAY' },
    { name: '  الخميس', value: 'THURSDAY' },
    { name: 'جمعة', value: 'FRIDAY' },
  ];
  const formik = useFormik({
    initialValues: {
      PlanNameEn: '',
      PlanNameAr: '',
      PlanDescEn: '',
      PlanDescAr: '',
      PlanStartDate: '',
      ChequeValue: '',
      LeftBalanceNumber: '',
      RightBalanceNumber: '',
      ChequeEarnTime: '',
      LeftMaxOutNumber: '',
      RightMaxOutNumber: '',
      ChequeMaxOut: '',
      ChequeEarnDay: '',
      // PlanGallery: selectMultiImage,
    },
    onSubmit: async (values, { resetForm }) => {
      const selectedDaysString = values?.ChequeEarnDay.join(',');
      console.log(selectedDaysString);

      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          PlanNameEn: values?.PlanNameEn,
          PlanNameAr: values?.PlanNameAr,
          // PlanDescEn: values?.PlanDescEn,
          // PlanDescAr: values?.PlanDescAr,
          ChequeValue: values?.ChequeValue,
          LeftBalanceNumber: values?.LeftBalanceNumber,
          RightBalanceNumber: values?.RightBalanceNumber,
          LeftMaxOutNumber: values?.LeftMaxOutNumber,
          RightMaxOutNumber: values?.RightMaxOutNumber,
          // PlanStartDate: values?.PlanStartDate,
          ChequeMaxOut: values?.ChequeMaxOut,
          ChequeEarnDay: selectedDaysString,
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/plans`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
  // Handle multi-image select
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  useEffect(() => {

    fetchSubCategoriesAjex()
    fetchBrandsAjex()

    return () => {
      fetchSubCategoriesAjex()
      fetchBrandsAjex()

    }
  }, [isLang])


  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [months, setMonths] = useState(0);
  const [days, setDays] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(0);

  const handleHoursChange = (e) => {
    const { value } = e;
    setHours(value);
    calculateTotalTime(value, minutes, months, days);
  };

  const handleMinutesChange = (e) => {
    const { value } = e;
    setMinutes(value);
    calculateTotalTime(hours, value, months, days);
  };

  const handleMonthsChange = (e) => {
    const { value } = e;
    setMonths(value);
    calculateTotalTime(hours, minutes, value, days);
  };

  const handleDaysChange = (e) => {
    const { value } = e;
    setDays(value);
    calculateTotalTime(hours, minutes, months, value);
  };

  const calculateTotalTime = (hours, minutes, months, days) => {
    const total = hours * 60 + minutes + months * 30 * 24 * 60 + days * 24 * 60;
    setTotalMinutes(total);
    formik.setFieldValue("ChequeEarnTime", total);
  };


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>


        <div className="grid">

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanNameEn" className="font-bold block mb-2">{t('label_Plan_name_en')}</label>
            <InputText
              name='PlanNameEn'
              id="PlanNameEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="PlanNameAr" className="font-bold block mb-2">{t('label_Plan_name_ar')}</label>
            <InputText
              name='PlanNameAr'
              id="PlanNameAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanDescEn" className="font-bold block mb-2">{t('label_Plan_desc_en')}</label>
            <InputText
              name='PlanDescEn'
              id="PlanDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="PlanDescAr" className="font-bold block mb-2">{t('label_Plan_desc_ar')}</label>
            <InputText
              name='PlanDescAr'
              id="PlanDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div> */}
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="LeftBalanceNumber" className="font-bold block mb-2">{t('LeftBalancePoint')}</label>
            <InputText
              name='LeftBalanceNumber'
              id="LeftBalanceNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="RightBalanceNumber" className="font-bold block mb-2">{t('RightBalancePoint')}</label>
            <InputText
              name='RightBalanceNumber'
              id="RightBalanceNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          {/* <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-3 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="PlanStartDate" className="font-bold block mb-2">{t('start_date')}</label>
            <Calendar
              id="PlanStartDate"
              name="PlanStartDate"
              value={formik.values.PlanStartDate}
              placeholder={isLang === "en" ? ' start date' : '  تاريخ بدأ   '}
              onChange={(e) => {
                formik.setFieldValue("PlanStartDate", e.value)
              }}
              showIcon
              className='w-full'
            />
          </div> */}
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="LeftMaxOutNumber" className="font-bold block mb-2">{t('LeftMaxOutPoint')}</label>
            <InputText
              name='LeftMaxOutNumber'
              id="LeftMaxOutNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="RightMaxOutNumber" className="font-bold block mb-2">{t('RightMaxOutPoint')}</label>
            <InputText
              name='RightMaxOutNumber'
              id="RightMaxOutNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeValue" className="font-bold block mb-2">{t('ChequeValue')}</label>
            <InputText
              name='ChequeValue'
              id="ChequeValue"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeMaxOut" className="font-bold block mb-2">{t('ChequeMaxOut')}</label>
            <InputText
              name='ChequeMaxOut'
              id="ChequeMaxOut"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">{t('gallery')} </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div> */}
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeEarnDay" className="font-bold block mb-2">{t('ChequeEarnDay')}</label>
            <MultiSelect
              value={formik.values.ChequeEarnDay}
              onChange={(e) => {
                formik.setFieldValue('ChequeEarnDay', e.value);
              }}
              options={cities}
              optionLabel="name"
              placeholder="Select Days"
              maxSelectedLabels={7}
              display="chip"
              className="w-full p-inputtext-sm"
            />
          </div>


          {/*<div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}>
            <label htmlFor="ChequeEarnTime" className="font-bold block mb-2">{t('ChequeEarnTime')}</label>
            <div className='flex gap-3 flex-column'>
              <div className='  grid'>
                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                  <label htmlFor="Months">{t('days')}</label>
                  <InputNumber
                    id="months"
                    value={months}
                    onChange={(e) => handleMonthsChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                  <label htmlFor="days">{t('days')}</label> 
                  <InputNumber
                    id="days"
                    value={days}
                    onChange={(e) => handleDaysChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>

              </div>
            </div>
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}>
            <label htmlFor="ChequeEarnTime" className="font-bold block mb-2">{t('ChequeEarnTime')}</label>
            <div className='flex gap-3 flex-column'>
              <div className='  grid'>
                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                  <label htmlFor="hours">{t('hours')}</label>
                  <InputNumber
                    id="hours"
                    value={hours}
                    onChange={(e) => handleHoursChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                  <label htmlFor="minutes">{t('minutes')}</label>
                  <InputNumber
                    id="minutes"
                    value={minutes}
                    onChange={(e) => handleMinutesChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
              </div>
            </div>
          </div> */}

        </div>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/plans`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>


        <Dialog
          visible={openImanes}
          style={{ width: '50rem' }}
          modal
          onHide={hideImagesProjectsDialog}
          header={isLang === "en" ? "Multi Images" : "صور متعددة"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
        >

          {/*Add DataTable to display data} */}
          <div className="mt-3 mb-3 flex justify-content-center">
            <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
              <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
              <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
            </label>
          </div>

          <input
            type='file'
            id='fileInput'
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => {
              formik.setFieldValue('PlanGallery', [...selectMultiImage, event.target.files[0]]);
              handleMultiImageSelect(event);
            }}
          />

          {/*  Display selected images */}
          <div className="grid  mt-2   ">
            {selectMultiImage.map((image, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col relative  ">
                <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(image)} 
                    alt={'brnad iamge'}
                    width='300px'
                    height='100%'
                    className=' mx-auto overflow-hidden '
                    style={{ objectFit: 'cover' }}
                  />
                  {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-14rem w-100  border-round-xl' /> */}
                  <Button
                    size="small"
                    type="button"
                    severity='danger'
                    raised
                    className='btn__porfile bg_delete  absolute '
                    rounded
                    icon="pi pi-trash"
                    style={{ right: '15px' }}
                    onClick={() => handleDeleteImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>



          <button type='button' style={{ display: 'none' }} onClick={() => document.getElementById('fileInput').click()}>
            Choose Images
          </button>
        </Dialog>
      </form>
    </div>
  )
}
export default AddPlanData
