import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import SummaryClient from './SummaryClient';
import { Paginator } from 'primereact/paginator';
import { TieredMenu } from 'primereact/tieredmenu';
import { Image } from 'primereact/image';


const ClientData = () => {
  let { isLang } = useContext(LocalizationContext);
  let { plansAjex,
    fetchProductsAjex,
    positionsAjex,
    fetchPositionsAjex, fetchCity, fetchArea, city, area, fetchplansProductsAjex, role } = useContext(FetchApi)
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);

  const [inputBalance, setInputBalance] = useState(0);
  const [balanceValue, setBalanceValue] = useState(null);
  const [balanceDialogVisible, setBalanceDialogVisible] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const hideBalanceDialog = (data) => {
    setBalanceValue(data?.IDClient);
    setBalanceDialogVisible(!balanceDialogVisible);
  };

  const setBalanceDialogFooter = (
    <div className='btn_gapAr'>
      <Button label={isLang === "en" ? 'No' : 'لا'} icon="pi pi-times" onClick={hideBalanceDialog} size='small' />
      <Button label={isLang === "en" ? 'Yes' : 'نــعم'} loading={balanceLoading} raised icon="pi pi-check" size='small' onClick={() => setBalance()} />
    </div>
  );
  const setBalance = async () => {
    setBalanceLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/clients/balance`, {
        IDClient: balanceValue,
        NewBalance: inputBalance
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      });

      const { data } = response;

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData();

        setTimeout(() => {
          setBalanceDialogVisible(false);
          setBalanceLoading(false);
        }, 1000);
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setBalanceLoading(false);
    }
  };
  // -------------------------------------------------------------------------------------------------------------------------------------------------------
  const [inputrewardpoints, setInputrewardpoints] = useState(0);
  const [rewardpointsValue, setrewardpointsValue] = useState(null);
  const [rewardpointsDialogVisible, setrewardpointsDialogVisible] = useState(false);
  const [rewardpointsLoading, setrewardpointsLoading] = useState(false);

  const hiderewardpointsDialog = (data) => {
    setrewardpointsValue(data?.IDClient);
    setrewardpointsDialogVisible(!rewardpointsDialogVisible);
  };

  const setrewardpointsDialogFooter = (
    <div className='btn_gapAr'>
      <Button label={isLang === "en" ? 'No' : 'لا'} icon="pi pi-times" size='small' onClick={hiderewardpointsDialog} />
      <Button label={isLang === "en" ? 'Yes' : 'نــعم'} loading={rewardpointsLoading} raised icon="pi pi-check" size='small' onClick={() => setrewardpoints()} />
    </div>
  );

  const setrewardpoints = async () => {
    setrewardpointsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/clients/rewardpoints`, {
        IDClient: rewardpointsValue,
        NewPoints: inputrewardpoints
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      });

      const { data } = response;

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData();

        setTimeout(() => {
          setrewardpointsDialogVisible(false);
          setrewardpointsLoading(false);
        }, 1000);
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setrewardpointsLoading(false);
    }
  };
  //  ========================================================================================================================================================
  const [viewDialogVisible, setViewDialogVisible] = useState(false);
  const [viewData, setViewData] = useState(null);
  const hideViewDialog = (data) => {
    setViewDialogVisible(!viewDialogVisible)
    setViewData(data)
  };
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [Data, setData] = useState(null);
  const [summary, setSummary] = useState(null);

  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/client/register">
          <Button label={t('register')} raised severity="warning" icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />
      </div>
    );
  };
  // handel btn action in tabel 
  const menu = useRef(null);
  const [idClients, setIdClients] = useState(null);
  const editClientsPermission = role?.EDIT_CLIENTS === 1;

  const items = [

    {
      label: <Link to={`/client/details/${idClients?.IDClient}`}>{t('client_details')}</Link>,
      icon: 'pi pi-user-edit',
    },
    {
      label: t('View_Client'),
      icon: 'pi pi-eye',
      command: () => {
        hideViewDialog(idClients)
      },
    },
    

    {
      label: <Link to={`/client/ledger/${idClients?.IDClient}`}>{t('ledgerClients')}</Link>,
      icon: 'pi pi-dollar',
    },

    ...(localStorage.getItem('Role') == 1
      ? [
        {
          label: <Link to={`/log/${idClients?.IDClient}/CLIENT`}>{t('log')}</Link>,
          icon: 'pi pi-users'
        },
      ]
      : []),

    ...(editClientsPermission ?
      [
        {
          label: t('NewBalance'),
          icon: 'pi pi-money-bill ',
          command: () => {
            hideBalanceDialog(idClients)
          },
        },
        {
          label: t('NewPoints'),
          icon: 'pi pi-bitcoin',
          command: () => {
            hiderewardpointsDialog(idClients)
          },
        },
        ...(idClients?.IDParentClient ? [
          {
            label: <Link to={`/client/rename/${idClients?.IDClient}`}>{t('Clients_rename')}</Link>,
            icon: 'pi pi-user-edit',
          },
        ]
          : [
            // Items to show when EDIT_CLIENTS permission is 0 or not given
          ]),
      ]
      : []),
      {
        label: <Link to={`/client/chat/list/${idClients?.IDClient}`}>{t('chat')}</Link>,
        icon: 'pi pi-comments',
      },
  ];

  const actionBodyTemplate = (rowData) => {

    return (
      <div className="flex   justify-content-center align-content-center">
        <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
        <Button
          size='small' text raised severity='warning' icon='pi pi-ellipsis-h'
          onClick={(e) => {
            menu.current.toggle(e)
            setIdClients(rowData);
          }} />

      </div>
    );
  };
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])
  const [globalFilter, setGlobalFilter] = useState(null);
  const [uplineSearchKey, setUplineSearchKey] = useState(null);
  const [referralSearchKey, setReferralSearchKey] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [byFilter, setByFilter] = useState(null);

  const clientDeleted = [
    { label: isLang === "en" ? 'Contract uploaded' : 'تم تحميل العقد', value: 1 },
    { label: isLang === "en" ? "Contract not uploaded   " : 'لم يتم تحميل العقد', value: 0 }
  ];

  const OptionsFilter = [
    { label: isLang === "en" ? 'Client  ' : 'العميل', value: 1 },
    { label: isLang === "en" ? 'Upline  ' : 'العميل', value: 2 },
    { label: isLang === "en" ? 'Referral  ' : 'المرشح', value: 3 },
    { label: isLang === "en" ? 'Start & End Date  ' : 'تاريخ بدايه و نهايه', value: 4 },
    { label: isLang === "en" ? 'Governorate' : 'محافظه', value: 5 },
    { label: isLang === "en" ? 'Status' : 'الحاله', value: 6 },
    { label: isLang === "en" ? ' Contract upload' : 'تحميل العقد', value: 7 },
    { label: isLang === "en" ? 'Product  ' : 'المنتج', value: 8 },
    { label: isLang === "en" ? 'Position  ' : "منصب", value: 9 },
  ];
  const header = (
    <div className="flex flex-column gap-3">
      <div className="grid  flex justify-content-between flex-row-reverse ">
        <div className="lg:col-2 md:col-12 sm:col-12     ">
          <Dropdown filter
            value={byFilter}
            options={OptionsFilter?.map(item => ({
              name: item.label,
              value: item.value,
            }))}
            id="IDProduct"
            name="IDProduct"
            optionLabel="name"
            optionValue="value"
            onChange={(e) => {
              setByFilter(e.value)
            }}
            placeholder={isLang === "en" ? 'Filter by' : 'اختر المنتج'}
            className="p-inputtext-sm w-full"
          />
        </div>
        {
          byFilter === 1 &&
          <div className="lg:col-2 md:col-12 sm:col-12     ">
            <span className="p-input-icon-left w-full">
              <i className="pi pi-search" />
              <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? 'Search by client  ' : 'البحث حسب اسم العميل   '} />
            </span>
          </div>
        }
        {
          byFilter === 2 &&
          <div className="lg:col-2 md:col-12 sm:col-12">
            <span className="p-input-icon-left  w-full">
              <i className="pi pi-search" />
              <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setUplineSearchKey(e.target.value)} placeholder={isLang === "en" ? 'Search by Upline ' : 'البحث عن طريق اسم الخط،       '} />
            </span>
          </div>
        }
        {
          byFilter === 3 &&
          <div className="lg:col-2 md:col-12 sm:col-12">
            <span className="p-input-icon-left  w-full">
              <i className="pi pi-search" />
              <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setReferralSearchKey(e.target.value)} placeholder={isLang === "en" ? 'Search by Referral  ' : 'البحث عن طريق اسم المرشح          '} />
            </span>
          </div>
        }
        {
          byFilter === 4 &&
          <div className='flex flex-row w-100 lg:col-9 md:col-12 sm:col-12 '>
            <div className="lg:col-2 md:col-12 sm:col-12     ">
              <div className={` filter_by_date ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
                <Calendar
                  value={selectedStartDate ? new Date(selectedStartDate) : null}
                  placeholder={isLang === "en" ? ' Start date' : '  تاريخ بدا   '}
                  onChange={(e) => {
                    const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                    setSelectedStartDate(selectedDate)
                  }}
                  showIcon
                  className="p-inputtext-sm w-full h-100"
                />
              </div>
            </div>
            <div className="lg:col-2 md:col-12 sm:col-12     ">
              <div className={`filter_by_date ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
                <Calendar
                  value={selectedEndDate ? new Date(selectedEndDate) : null}
                  placeholder={isLang === "en" ? ' End date' : '  تاريخ نهاية   '}
                  onChange={(e) => {
                    const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                    setSelectedEndDate(selectedDate)
                  }}
                  showIcon
                  minDate={selectedStartDate ? new Date(selectedStartDate) : null}
                  className="p-inputtext-sm w-full"

                />
              </div>
            </div>
            <div className="lg:col-2 md:col-12 sm:col-12 flex justify-content-center align-items-center    ">
              <Button className='w-full' text raised onClick={() => filterDataByDate(selectedStartDate, selectedEndDate)} label={isLang === "en" ? 'Search by date' : 'البحث حسب التاريخ'} outlined severity="warning" icon="pi pi-calendar-plus" size='small' />
            </div>
          </div>
        }
        {
          byFilter === 5 &&
          <div className='flex flex-row w-100 lg:col-9 md:col-12 sm:col-12 '>
            <div className="lg:col-2 md:col-12 sm:col-12 ">
              <Dropdown filter
                value={selectedArea}
                options={city?.map(item => ({
                  name: item.CityName,
                  value: item.IDCity,
                }))}
                id="IDCity"
                name="IDCity"
                optionLabel="name"
                optionValue="value"
                onChange={(e) => {
                  fetchArea(e.value)
                  setSelectedArea(e.value)
                  filterDataByCity(e.value)
                }}
                placeholder={t('city_placeholder')}
                className="p-inputtext-sm w-full"
              />
            </div>
            <div className="lg:col-2 md:col-12 sm:col-12     ">
              <Dropdown filter
                value={selectedCity}
                options={area?.map(item => ({
                  name: item.AreaName,
                  value: item.IDArea,
                }))}
                id="IDArea"
                name="IDArea"
                optionLabel="name"
                optionValue="value"
                onChange={(e) => {
                  setSelectedCity(e.value)
                  filterDataByArea(e.value)
                }}
                placeholder={isLang === "en" ? 'Select a governorate' : 'اختر منطقة'}
                className="p-inputtext-sm w-full"
              />
            </div>
          </div>
        }
        {
          byFilter === 6 &&
          <div className="lg:col-2 md:col-12 sm:col-12     ">
            <Dropdown filter
              options={statusOptions}
              value={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.value)
                filterDataByStatus(e.value)
              }}
              placeholder={isLang === "en" ? 'Select a status' : 'حدد الحالة'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }
        {
          byFilter === 7 &&
          <div className="lg:col-2 md:col-12 sm:col-12     ">
            <Dropdown filter
              options={clientDeleted}
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e.value)
                filterDataByDeleted(e.value)
              }}
              placeholder={isLang === "en" ? 'Select contract upload or not' : 'حدد تحميل العقد أم لا'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }
        {
          byFilter === 8 &&
          <div className="lg:col-2 md:col-12 sm:col-12     ">
            <Dropdown filter
              value={selectedProduct}
              options={plansAjex?.map(item => ({
                name: item.PlanProductName,
                value: item.IDPlanProduct,
              }))}
              id="IDProduct"
              name="IDProduct"
              optionLabel="name"
              optionValue="value"
              onChange={(e) => {
                setSelectedProduct(e.value)
                filterDataByIDProduct(e.value)
              }}
              placeholder={isLang === "en" ? 'Select a product' : 'اختر المنتج'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }
        {
          byFilter === 9 &&
          <div className="lg:col-2 md:col-12 sm:col-12     ">
            <Dropdown filter
              value={selectedPosition}
              options={positionsAjex?.map(item => ({
                name: item.PositionTitle,
                value: item.IDPosition,
              }))}
              id="IDPosition"
              name="IDPosition"
              optionLabel="name"
              optionValue="value"
              onChange={(e) => {
                setSelectedPosition(e.value)
                filterDataByIDPosition(e.value)
              }}
              placeholder={isLang === "en" ? 'Select a position' : 'اختر منصب'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }
      </div>
    </div>
  );

  const fetchData = async () => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDPage: page + 1 },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
    setSummary(data?.data?.Response);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchplansProductsAjex()
    fetchProductsAjex()
    fetchPositionsAjex()
  }, [isLang])

  useEffect(() => {
    fetchData();
  }, [isLang, page])
  // For Client Name - Email - App ID - Phone
  const filterData = async () => {
    if (globalFilter) {
      setUplineSearchKey(null)
      setReferralSearchKey(null)
    }
    if (uplineSearchKey) {
      setGlobalFilter(null)
      setReferralSearchKey(null)
    }
    if (referralSearchKey) {
      setUplineSearchKey(null)
      setGlobalFilter(null)
    }

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { SearchKey: globalFilter, UplineSearchKey: uplineSearchKey, ReferralSearchKey: referralSearchKey },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  //ACTIVE  - BLOCKED  - INACTIVE
  const filterDataByStatus = async (status) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { ClientStatus: status },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  // 1 to not show deleted
  const filterDataByDeleted = async (deleted) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { ClientContractCompleted: deleted },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  // filter by area
  const filterDataByArea = async (area) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDArea: area },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  // filter by city
  const filterDataByCity = async (city) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDCity: city },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  // filter by start date , end date
  const filterDataByDate = async (start, end) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { StartDate: start, EndDate: end },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  // filter by area
  const filterDataByPlan = async (plan) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDPlan: plan },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }

  // filter by IDProduct
  const filterDataByIDProduct = async (id) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDProduct: id },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }

  // filter by IDPosition
  const filterDataByIDPosition = async (id) => {

    const url = `${process.env.REACT_APP_API_URL}/clients`;
    let data = await axios.post(url, { IDPosition: id },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Clients);
  }
  useEffect(() => {
    fetchData();
  }, [isLang])
  useEffect(() => {
    filterData();
  }, [isLang, globalFilter, uplineSearchKey, referralSearchKey])
  useEffect(() => {
    fetchCity(1)
  }, [isLang])

  const [editingRow, setEditingRow] = useState(null);

  const onStatusChange = async (rowData, e) => {

    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/clients/status`, {
      IDClient: rowData?.IDClient,
      ClientStatus: e.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success === true) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
    { label: t('BLOCKED'), value: 'BLOCKED' },
    { label: t('NOT_VERIFIED'), value: 'NOT_VERIFIED' },
  ];
  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.ClientStatus === "ACTIVE" ? t('active') : rowData?.ClientStatus === "INACTIVE" ? t('inactive') : rowData?.ClientStatus === "BLOCKED" ? t('BLOCKED') : rowData?.ClientStatus === "NOT_VERIFIED" ? t('NOT_VERIFIED') : rowData?.ClientStatus === "PENDING" && t('pending')

    return editingRow === rowData?.IDClient ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.ClientStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.ClientStatus === "ACTIVE" ? t('active') : rowData?.ClientStatus === "INACTIVE" ? t('inactive') : rowData?.ClientStatus === "BLOCKED" ? t('BLOCKED') : rowData?.ClientStatus === "NOT_VERIFIED" ? t('NOT_VERIFIED') : rowData?.ClientStatus === "PENDING" && t('pending')}`}
        className="p-inputtext-sm   "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.ClientStatus)}
        onClick={() => {
          if (editClientsPermission) {
            setEditingRow(rowData?.IDClient)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>
            {`${rowData?.ClientStatus === "ACTIVE" ? t('active') : rowData?.ClientStatus === "INACTIVE" ? t('inactive') : rowData?.ClientStatus === "BLOCKED" ? t('BLOCKED') : rowData?.ClientStatus === "NOT_VERIFIED" ? t('NOT_VERIFIED') : rowData?.ClientStatus === "PENDING" && t('pending')}`}
          </span>
          {
            editClientsPermission &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  const statusBodyTemplate1 = (rowData) => {
    return editingRow === rowData?.ClientPrivacy ? (
      <>

      </>
    ) : (
      <Tag
        value={`${rowData?.ClientPrivacy === 0 ? t('Privacy') : rowData?.ClientPrivacy === 1 ? t('NotPrivacy') : t('BLOCKED')}`}
        severity={getSeverity2(rowData.ClientPrivacy)}
        rounded
      />
    );
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center'>

      <Image
        preview
        loading="lazy"
        src={rowData.ClientPicture}
        alt={'brnad iamge'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round'
        style={{ objectFit: 'cover' }}
      />    </div>
  };
  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';

      case 'NOT_VERIFIED':
        return 'danger';

      case 'ACTIVE':
        return 'success';
      case 'PENDING':
        return 'warning';

      case 'BLOCKED':
        return 'info';
    }
  };

  const getSeverity2 = (status) => {
    switch (status) {
      case 1:
        return 'danger';

      case 0:
        return 'success';

      case 'BLOCKED':
        return 'info';
    }
  };

  const resetData = () => {
    setGlobalFilter(null)
    setUplineSearchKey(null)
    setReferralSearchKey(null)
    setSelectedStatus(null)
    setSelectedClient(null)
    setSelectedArea(null)
    setSelectedPosition(null)
    setSelectedProduct(null)
    setSelectedCity(null)
    setSelectedPlan(null)
    setSelectedStartDate(null)
    setSelectedEndDate(null)
    setData(null)
    fetchData()

  }
  const CreateDate = (rowData) => {
    const formattedDate = format(new Date(rowData?.RegisterDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };


  return (
    <div className='mb-8'>
      <SummaryClient data={summary} />

      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card mt- ">
        {
          role?.ADD_CLIENTS == 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }
        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          size='normal'
          header={header}
        >
          <Column field="ClientPicture" header={t('ClientPicture')} style={{ maxWidth: '10rem', textAlign: 'center' }} body={imageBodyTemplate}></Column>
          <Column field="ClientName" header={t('name')} style={{ textAlign: 'center' }}></Column>
          <Column field="ClientBalance" header={t('ledgerClients')} style={{ textAlign: 'center' }}></Column>
          <Column field="ReferralClient" header={t('ReferralClient')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="ClientRewardPoints" header={t('ClientRewardPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {/* <Column field="ClientRightPoints" header={t('ClientRightPoints')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column field="ClientLeftPoints"    header={t('ClientLeftPoints')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column> */}
          <Column field="ClientTotalNumber" header={isLang === "en" ? 'Total persons' : 'مجموع الاشخاص'} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="ClientTotalPoints" header={t('ClientTotalPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {/* <Column field="PlanName"    header={t('PlanName')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column> */}
          {/* <Column field="ClientPrivacy"   header={t('ClientPrivacy')} body={statusBodyTemplate1} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column> */}
          <Column field="CreateDate" header={t('CreateDate')} body={CreateDate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="ClientStatus" header={t('status')} body={statusBodyTemplate} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} header={t('action')}  ></Column>
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
        <Dialog
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          visible={balanceDialogVisible}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? 'Add E_Cash' : 'إضـــافة رصيد'}
          modal
          footer={setBalanceDialogFooter}
          onHide={hideBalanceDialog}

        >
          <div className="flex justify-content-start gap-3 align-items-center">
            <InputText className='w-full mt-3' onChange={(e) => setInputBalance(e?.target?.value)} />
          </div>
        </Dialog>

        <Dialog
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          visible={viewDialogVisible}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? 'Client Information' : 'معلومات العميل'}
          modal
          onHide={hideViewDialog}
        >
          <div className='flex flex-column gap-3'>

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientAppID')} : </strong>
              <span>{viewData?.ClientAppID}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('PositionTitle')} : </strong>
              <span>{viewData?.PositionTitle}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              <strong>{t('ClientPhone')} : </strong>
              <span>{viewData?.ClientPhone}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              <strong>{t('name')} : </strong>
              <span>{viewData?.ClientName}</span>
            </div>
            <hr />
            {/* 
            <div className='flex flex-row gap-3'>
              <strong>{t('name_ar')} : </strong>
              <span>{viewData?.ClientNameArabic}</span>
            </div>
            <hr /> */}

            <div className='flex flex-row gap-3'>
              <strong>{t('ReferralClient')} : </strong>
              <span>{viewData?.ReferralClient}</span>
            </div>
            <hr />


            <div className='flex flex-row gap-3'>
              <strong>{isLang === "en" ? 'Referral phone  ' : "  هاتف المرشح"} : </strong>
              <span>{viewData?.ReferralPhone}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              <strong>{t('ReferralNumber')} : </strong>
              <span>{viewData?.ReferralNumber}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              <strong>{isLang === "en" ? 'Upline  name' : "متواصل شبكي للوالدين"} : </strong>
              <span>{viewData?.ParentClient}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{isLang === "en" ? 'Upline phone  ' : "شبكة هاتف الوالدين"} : </strong>
              <span>{viewData?.ParentPhone}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              {/* <strong>{t('ClientLeftNumber')} : </strong> */}
              <strong>{isLang === "en" ? 'Left persons' : 'الأشخاص علي اليسار'} : </strong>

              <span>{viewData?.ClientLeftNumber}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              {/* <strong>{t('ClientRightNumber')} : </strong> */}
              <strong>{isLang === "en" ? 'Right persons' : 'الأشخاص علي اليمين'} : </strong>

              <span>{viewData?.ClientRightNumber}</span>
            </div>
            <hr />
            <div className='flex flex-row gap-3'>
              <strong>{isLang === "en" ? 'Total persons' : 'مجموع الاشخاص'} : </strong>
              {/* <strong>{t('ClientTotalNumber')} : </strong> */}
              <span>{viewData?.ClientTotalNumber}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientRightPoints')} : </strong>
              <span>{viewData?.ClientRightPoints}</span>
            </div>
            <hr />


            <div className='flex flex-row gap-3'>
              <strong>{t('ClientLeftPoints')} : </strong>
              <span>{viewData?.ClientLeftPoints}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientTotalPoints')} : </strong>
              <span>{viewData?.ClientTotalPoints}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientRewardPoints')} : </strong>
              <span>{viewData?.ClientRewardPoints}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('Passport')} : </strong>
              <span>{viewData?.ClientPassport}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('NationalID')} : </strong>
              <span>{viewData?.ClientNationalID}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientEmail')} : </strong>
              <span>{viewData?.ClientEmail}</span>
            </div>
            <hr />



            <div className='flex flex-row gap-3'>
              <strong>{t('Second_Phone')} : </strong>
              <span>{viewData?.ClientSecondPhone}</span>
            </div>
            <hr />



            <div className='flex flex-row gap-3'>
              <strong>{t('ClientGender')} : </strong>
              <span>{viewData?.ClientGender}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientIDAddress')} : </strong>
              <span>{viewData?.ClientCurrentAddress}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('addres')} : </strong>
              <span>{viewData?.ClientIDAddress}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('ClientNationality')} : </strong>
              <span>{viewData?.ClientNationality}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('BirthDate')} : </strong>
              <span>{viewData?.ClientBirthDate}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('CityName')} : </strong>
              <span>{viewData?.CityName}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('AreaName')} : </strong>
              <span>{viewData?.AreaName}</span>
            </div>
            <hr />



            <div className='flex flex-row gap-3'>
              <strong>{isLang === "en" ? 'Product name ' : 'اسم المنتج'} : </strong>
              <span>{viewData?.PlanName}</span>
            </div>




          </div>
        </Dialog>

        <Dialog
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          visible={rewardpointsDialogVisible}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? 'Add reward points' : 'إضافة نقاط مكافأة '}
          modal
          footer={setrewardpointsDialogFooter}
          onHide={hiderewardpointsDialog}
        >
          <div className="flex justify-content-start gap-3 align-items-center">
            <InputText className='w-full mt-3' onChange={(e) => setInputrewardpoints(e?.target?.value)} />
          </div>
        </Dialog>

      </div>

    </div>
  )
}

export default ClientData
