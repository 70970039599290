import axios from 'axios';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
import { useTranslation } from 'react-i18next';
import { LocalizationContext } from 'context/LangChange';
import { useEffect } from 'react';

const NetworkRequest = () => {
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()
    let { setCity, plansProductsAjex, fetchplansProductsAjex, plansAjex, fetchProductsAjex } = useContext(FetchApi)

    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/clients/network/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
        { label: <Link to={'/client'}>{t('client_name')}   </Link> },
        { label: <Link to={`/client/request/${id}`} className='p-2 border-round'>  {isLang === "en" ? 'Request' : 'طلبات'}  </Link> }
    ];
    const statusList = [
        { name: t('active'), value: '1' },
        { name: t('inactive'), value: '0' },
    ];

    const PlanNetworkPosition = [
        { name: isLang === "en" ? 'Left' : 'يسار', value: 'LEFT' },
        { name: isLang === "en" ? 'Right' : 'يمين', value: 'RIGHT' },
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
        initialValues: {
            IDClient: id,
            IDPlanProduct: '',
            ParentClient: '',
            ReferralClient: '',
            PlanNetworkPosition: '',
            IDPlan: ''
        },
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            try {
                setLoading(true);
                let { data } = await axios.post(url, {
                    IDClient: id,
                    IDPlan: values?.IDPlan,
                    IDPlanProduct: values?.IDPlanProduct,
                    ParentClient: values?.ParentClient,
                    ReferralClient: values?.ReferralClient,
                    PlanNetworkPosition: values?.PlanNetworkPosition,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })
                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);
                        setCity([])
                        navigate('/client')
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });


    useEffect(() => {
        fetchplansProductsAjex()
        return () => {
            fetchplansProductsAjex()
        }
    }, [isLang])

    return (
        <>
            <div className='mb-8'>
                <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

                <BreadCrumb model={items} home={home} />
                <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

                    <div className="grid  mt-5  ">
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <label htmlFor="ParentClient" className="font-bold block mb-2"> {t('ParentClient')}   </label>
                            <InputText
                                name='ParentClient'
                                id="ParentClient"
                                type='text'
                                className="w-full  p-inputtext-sm"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder='enter phone , app id , email'
                                required
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
                            <label htmlFor="ReferralClient" className="font-bold block mb-2">   {t('ReferralClient')}  </label>
                            <InputText required name='ReferralClient' id="ReferralClient" type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                                onBlur={formik.handleBlur} />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
                            <label htmlFor="PlanNetworkPosition" className="font-bold block mb-2">
                                {isLang === "en" ? '   Plan network position  ' : '  تخطيط موقف الشبكة '}
                            </label>
                            <Dropdown filter   required
                                id="PlanNetworkPosition"
                                name="PlanNetworkPosition"
                                value={formik.values.PlanNetworkPosition}
                                options={PlanNetworkPosition}
                                optionLabel="name"
                                onChange={(e) => formik.setFieldValue("PlanNetworkPosition", e.value)}
                                onBlur={formik.handleBlur}
                                placeholder={isLang === "en" ? 'Select a position' : 'حدد مكان '}
                                className="w-full p-inputtext-sm"
                            />
                        </div>
                        <div className="lg:col-6 md:col-12 sm:col-12   mt-2   ">
                            <label htmlFor="IDPlan" className="font-bold block mb-2">{t('PlanName')} </label>
                            <Dropdown filter   required
                                options={plansProductsAjex?.map(item => ({
                                    name: item.PlanName,
                                    value: item.IDPlan,
                                }))}
                                id="IDPlan"
                                name="IDPlan"
                                optionLabel="name"
                                optionValue="value"
                                value={formik.values.IDPlan} // Add this line 
                                onChange={(e) => {
                                    formik.setFieldValue("IDPlan", e.value)
                                    fetchProductsAjex(e.value)
                                }}
                                onBlur={formik.handleBlur}
                                placeholder={t('plan_placeholder')}
                                className="w-full p-inputtext-sm"
                            />
                        </div>
                        {
                            formik.values.IDPlan &&
                            <div div className="lg:col-6 md:col-12 sm:col-12">
                                <label htmlFor="IDPlanProduct" className="font-bold block mb-2">{t('Plan product')}</label>
                                <Dropdown filter  required
                                    options={plansAjex?.map(item => ({
                                        name: item.PlanProductName,
                                        value: item.IDPlanProduct,
                                    }))}
                                    id="IDPlanProduct"
                                    name="IDPlanProduct"
                                    optionLabel="name"
                                    optionValue="value"
                                    value={formik.values.IDPlanProduct} // Add this line 
                                    onChange={(e) => {
                                        formik.setFieldValue("IDPlanProduct", e.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('product_placeholder')}
                                    className="w-full p-inputtext-sm"
                                />
                            </div>
                        }

                    </div>

                    <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                        <div className="btn_submit">
                            <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} size='small' className='mt-3' />
                        </div>
                        <Link to={'/client'}>
                            <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
                        </Link>
                    </div>
                </form >
            </div >
        </>
    )
}


export default NetworkRequest
