import AddGoogleMap from 'GoogleMap/AddGoogleMap';
import AddGallery from 'Page/Upload/AddGallery';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AddPlanProducts = () => {
  const { id } = useParams()
  let { plansProductsAjex, fetchplansProductsAjex } = useContext(FetchApi)
  let { multiImage, setMultiImage, isLang } = useContext(LocalizationContext);

  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/plans/products/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const [openImanes, setOpenImanes] = useState(false);

  const items = [
    { label: <Link to={`/plan-products`}>{t('plan_product')}   </Link> },
    { label: <Link to={`/plan-products/add`} className='p-2 border-round'>  {t('add_plan_product')}  </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  //!hide vido= 
  const [openPlanProductVideos, setOpenPlanProductVideos] = useState(false);
  const [PlanProductVideos, setPlanProductVideos] = useState([]);

  //!hide PlanProductVideos modal 
  const hidePlanProductVideosProjectsDialog = (e) => setOpenPlanProductVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedPlanProductVideos = [...formik.values.PlanProductVideos];
    updatedPlanProductVideos[index] = value;
    formik.setFieldValue('PlanProductVideos', updatedPlanProductVideos);
  };

  // Handle adding a new scope
  const handleAddScope = () => {
    const updatedPlanProductVideos = [...formik.values.PlanProductVideos, ''];
    console.log(updatedPlanProductVideos);
    formik.setFieldValue('PlanProductVideos', updatedPlanProductVideos);
    setPlanProductVideos(updatedPlanProductVideos);
  };
  let { userLocationMap, setLoctionMap, setMarkers } = useContext(LocalizationContext);

  const formik = useFormik({
    initialValues: {
      PlanProductNameEn: '',
      PlanProductNameAr: '',
      PlanProductDescEn: '',
      PlanProductDescAr: '',
      AgencyNumber: '',
      PlanProductPrice: '',
      PlanProductPoints: '',
      PlanRewardPoints: '',
      PlanProductLatitude: userLocationMap?.lat,
      PlanProductLongitude: userLocationMap?.lng,
      // PlanProductReferralPoints: '',
      // PlanProductUplinePoints: '',
      CardNumber: '',
      PlanProductPhone: '',
      PlanProductAddressEn: '',
      PlanProductAddressAr: '',
      PlanProductGallery: multiImage,
      PlanProductVideos: PlanProductVideos,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          setMultiImage([])
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/plan-products`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    fetchplansProductsAjex()
    return () => {
      fetchplansProductsAjex()
    }
  }, [isLang])
  const [openMap, setOpenMap] = useState(false);
  const hideMapDialog = (data) => {
    setOpenMap(!openMap)
  }
  const extractCoordinates = (e) => {
    setLoctionMap(null)
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
    const match = e.match(regex);

    if (match) {
      setLoctionMap(null)
      const newMarker = { lat: Number(match[1]), lng: Number(match[2]) };
      console.log(newMarker);
      setMarkers([newMarker]);
      setLoctionMap(newMarker)

    } else {
      console.error("Invalid Google Maps URL");
    }
  };
  useEffect(() => {
    formik.setFieldValue('PlanProductGallery', multiImage);
  }, [multiImage]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mb-4">
          <label htmlFor="BrandNameEn" className="font-bold block mb-2"> {t('Gallery')} </label>
          <AddGallery />
        </div>

        <div className="grid">

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductNameEn" className="font-bold block mb-2">{t('label_Product_name_en')}</label>
            <InputText
              name='PlanProductNameEn'
              id="PlanProductNameEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="PlanProductNameAr" className="font-bold block mb-2">{t('label_Product_name_ar')}</label>
            <InputText
              name='PlanProductNameAr'
              id="PlanProductNameAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductDescEn" className="font-bold block mb-2">{t('label_Product_desc_en')}</label>
            <InputTextarea
              name='PlanProductDescEn'
              id="PlanProductDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="PlanProductDescAr" className="font-bold block mb-2">{t('label_Product_desc_ar')}</label>
            <InputTextarea
              name='PlanProductDescAr'
              id="PlanProductDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='PlanProductPrice'
              id="PlanProductPrice"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPoints" className="font-bold block mb-2">{t('UplinePoints')}</label>
            <InputText
              name='PlanProductPoints'
              id="PlanProductPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="AgencyNumber" className="font-bold block mb-2">{t('AgencyNumber')}</label>
            <InputText
              name='AgencyNumber'
              id="AgencyNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="CardNumber" className="font-bold block mb-2">{t('card')}</label>
            <InputText
              name='CardNumber'
              id="CardNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12     mt-3 ">
            <label htmlFor="IDPlan" className="font-bold block mb-2">{isLang === "en" ? " Plan " : 'خطة'}</label>
            <Dropdown filter
              options={plansProductsAjex?.map(item => ({
                name: item.PlanName,
                value: item.IDPlan,
              }))}
              id="IDPlan"
              name="IDPlan"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDPlan} // Add this line 
              onChange={(e) => formik.setFieldValue("IDPlan", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a Plan' : 'حدد خطة'}
              className="w-full p-inputtext-sm"
            />
          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductReferralPoints" className="font-bold block mb-2">{t('ReferralPoints')}</label>
            <InputText
              name='PlanProductReferralPoints'
              id="PlanProductReferralPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductUplinePoints" className="font-bold block mb-2">{t('UplinePoints')}</label>
            <InputText
              name='PlanProductUplinePoints'
              id="PlanProductUplinePoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div> */}
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanRewardPoints" className="font-bold block mb-2">{isLang === "en" ? 'Reward points' : 'نقاط مكافأة'}</label>
            <InputText
              name='PlanRewardPoints'
              id="PlanRewardPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductAddressEn" className="font-bold block mb-2">{isLang === "en" ? ' Address in english' : 'العنوان بالانجليزية'}</label>
            <InputTextarea
              name='PlanProductAddressEn'
              id="PlanProductAddressEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductAddressAr" className="font-bold block mb-2">{isLang === "en" ? ' Address in arabic' : 'العنوان بالعربية'}</label>
            <InputTextarea
              name='PlanProductAddressAr'
              id="PlanProductAddressAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>


          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="PlanProductPhone" className="font-bold block mb-2">{t('phone')}</label>
            <InputNumber
              name="PlanProductPhone"
              id="PlanProductPhone"
              className="w-full"
              inputClassName="p-inputtext-sm"
              onValueChange={(e) => {
                formik.setFieldValue("PlanProductPhone", '+20' + e.target.value)
              }}
              onBlur={formik.handleBlur}
              mode="decimal"
              useGrouping={false}
              dir="ltr"
              pattern="\d*"
              maxLength={10}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col "> </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">{t('gallery')} </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={isLang === "en" ? "Product Videos" : 'فيديوهات المنتج'} type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenPlanProductVideos(!openImanes)} />
            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BranchPhone" className="font-bold block mb-2">{isLang === "en" ? 'Products location' : 'موقع المنتجات'}</label>
            <div className="flex flex-row gap-2">

              <Link className='location_branch  ' onClick={() => hideMapDialog()}>
                <Button label={t('location')} size='small' icon='pi pi-map-marker' tooltip={t('location')} tooltipOptions={{ position: t('tooltip') }} severity='success' outlined className="mr-2" />
              </Link>

              <InputText
                type='text'
                className="w-full p-inputtext-sm"
                onChange={(e) => extractCoordinates(e.target.value)}
                placeholder={isLang === "en" ? "Paste Google Maps URL here" : 'الصق عنوان URL الخاص بخرائط جوجل هنا'}
              />
            </div>
          </div>
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/plan-products`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>



        {/*****Model Add Multi PlanProductVideos */}
        <Dialog visible={openPlanProductVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hidePlanProductVideosProjectsDialog}>

          {/* PlanProductVideos */}
          {PlanProductVideos.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="PlanProductVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='PlanProductVideos'
                  id="PlanProductVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hidePlanProductVideosProjectsDialog} />
            </div>

          </div>

        </Dialog>
        <AddGoogleMap
          openMap={openMap}
          hideMapDialog={hideMapDialog}
          isLang={isLang}
        />

      </form>
    </div>
  )
}

export default AddPlanProducts
