import { Drawer, Layout, Menu } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink } from 'react-router-dom';

import img from 'constants/Img';
import { GeneralData } from 'context/General';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import useRoutes from './useRoutes';

const { Sider } = Layout;

function getItem(label, key, icon, children, path, permission) {
  return {
    key,
    icon,
    children,
    label: path ? <NavLink to={path} activeClassName="active-link">{label}</NavLink> : label,
    permission,
  };
}

const SideBar = ({ collapsed }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { setCollapsed, visible, setVisible } = useContext(GeneralData);
  let { i18n } = useTranslation();
  const { routes } = useRoutes();
  const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || '1'); 

  const items = routes?.map(route => {
    return getItem(i18n.language === "en" ? route.nameEn : route.nameAr, route.path, route.icon, null, route.path, route.permission);
  });

  const handleSelect = ({ key }) => {
    setSelectedKey(key); 
    localStorage.setItem('selectedKey', key); 
    setVisible(false);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  useEffect(() => {
    const savedKey = localStorage.getItem('selectedKey');
    if (savedKey) {
      setSelectedKey(savedKey);
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <Drawer
          title="Betterway Egypt"
          placement={i18n.language==="en"?"left":"right"}
          onClose={closeDrawer}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          size='small'
        >
          <Menu
            theme="light"
            defaultSelectedKeys={[selectedKey]} 
            mode="inline"
            items={items}
            onSelect={handleSelect} 
          />
        </Drawer>
      ) : (
        <Sider
          className="sider"
          style={{
            padding: 0,
            background: '#fff',
            position: 'fixed',
            height: '100vh',
            transition: 'width 0.2s ease-in-out',
          }}
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={60}
          onCollapse={(collapse) => setCollapsed(collapse)}
        >
          <div className="demo-logo-vertical">
            <Link to={'/'}>
              <img src={!collapsed ? img.logo : img.logoSm} alt="" width={!collapsed ? 140 : 40} />
            </Link>
          </div>
          <Menu
            theme="light"
            defaultSelectedKeys={[selectedKey]} // استخدام selectedKey
            mode="inline"
            items={items}
            onSelect={handleSelect} // تعيين handleSelect لتحديث حالة التحديد
          />
        </Sider>
      )}
    </>
  );
};

export default SideBar;
