
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const ClientTransfer = () => {
    let { isLang } = useContext(LocalizationContext);
    let { id } = useParams()

    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);



    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">  </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/clients/balance/transfer`;
        let data = await axios.post(url, { IDClient: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.BalanceTransfer);
    }
    useEffect(() => {
        fetchData();
    }, [isLang])

    const [editingRow, setEditingRow] = useState(null);

 


    const statusBodyTemplate = (rowData) => {
         return editingRow === rowData?.TransferStatus ? (
         <>
         
         </>
        ) : (
            <Tag
                value={`${rowData?.TransferStatus === "ACCEPTED" ? t('ACCEPTED') : rowData?.TransferStatus === "PENDING" ? t('pending') : t('BLOCKED')}`}
                severity={getSeverity(rowData.TransferStatus)}
                rounded
            />
        );
    };




    const getSeverity = (status) => {
        switch (status) {
            case 'ACCEPTED':
                return 'success';

            case 'PENDING':
                return 'warning';

            case '':
                return 'info';

        }
    };
    const items = [
        { label: <Link to={`/client`}>{t('client_name')}</Link> },
        { label: <Link to={`/client/transfer/${id}`} className='p-2 border-round'>{t('client_transfer')}</Link> }
      ];
      const home = { icon: 'pi pi-home', url: '/' };
      const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.Date), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
      };
    return (
        <div className='mb-8'>     
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="mb-3"> 
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="card mt-5">
                <DataTable className="custom-datatable"   scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    paginator
                    size='normal'
                    rows={10}
                    header={header}>
                    <Column   field="TransferAmount" header={t('amount')} style={{ textAlign: 'center' }}></Column>
                    <Column   field="MyType" header={t('type_transfer')} style={{ textAlign: 'center' }}></Column>
                    <Column   field="Date" header={isLang === "en"?'Transfer date':'تاريخ التحويل'} body={date} style={{ textAlign: 'center' }}></Column>
                    <Column   field="ClientName" header={t('client_name')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                    <Column   field="TransferStatus" header={t('status')} body={statusBodyTemplate} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                </DataTable> 
            </div>

        </div>
    )
}
export default ClientTransfer
