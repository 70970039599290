import { EnvironmentOutlined, EyeOutlined } from '@ant-design/icons';
import ShowGoogleMap from 'GoogleMap/ShowGoogleMap';
import ShowGoogleMapModal from 'GoogleMap/ShowGoogleMapModal';
import { Button, Input, notification, Pagination, Space, Table, Tag, Tooltip } from 'antd';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Branch = () => {
    const inputRef = useRef(null);
    let { isLang, setLoctionMap } = useContext(LocalizationContext);
    const [openMap, setOpenMap] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const { t, i18n } = useTranslation();
    const location = useLocation();

    const hideImagesProjectsDialog = (branch) => {
        setOpenMap(!openMap);
        const newMarker = { lat: branch?.BranchLatitude, lng: branch?.BranchLongitude };
        setLoctionMap(newMarker);
    };

    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath);
    }, [location]);

    const fetchData = async (searchKey) => {
        const url = `${process.env.REACT_APP_API_URL}/branches`;
        try {
            const response = await axios.post(url, { IDPage: page, SearchKey: searchKey }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('tokenBetter')}`,
                },
            });
            setData(response?.data?.Response?.Branches || []);
            setTotalPages(response?.data?.Response?.Pages || 0);
        } catch (error) {
            notification.error({ message: t('Error'), description: 'Failed to fetch data' });
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enables smooth scrolling
        });
    };
    useEffect(() => {
        scrollToTop()
        fetchData('');
    }, [i18n.language, page]);

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            fetchData(e.target.value);
        }
        if (e.target.value === '') {
            fetchData('');
        }
    };

    const resetData = () => {
        fetchData('');
        inputRef.current.value = '';
    };

    const columns = [
        {
            title: t('addres'),
            dataIndex: 'BranchAddress',
            key: 'BranchAddress',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: t('phone'),
            dataIndex: 'BranchPhone',
            key: 'BranchPhone',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: t('cities_title'),
            dataIndex: 'CityName',
            key: 'CityName',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: t('areas_title'),
            dataIndex: 'AreaName',
            key: 'AreaName',
            align: 'center',
            width: 200,
            ellipsis: true,
        },
        {
            title: t('status'),
            dataIndex: 'BranchStatus',
            key: 'BranchStatus',
            align: 'center',
            width: 200,
            ellipsis: true,
            render: (status) => (
                <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
                    {status === 'ACTIVE' ? t('active') : t('inactive')}
                </Tag>
            ),
        },
        {
            title: '',
            key: 'actions',
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <Space>
                    <Link to={`/branch/edit/${record.IDBranch}`}>
                        <Tooltip title={t('branch_edit')}>
                            <Button icon={<EyeOutlined />} />
                        </Tooltip>
                    </Link>
                    <Tooltip title={t('location')}>
                        <Button icon={<EnvironmentOutlined />} onClick={() => hideImagesProjectsDialog(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <div className='mb-8'>


            <Space className='flex justify-content-between mb-5'>
                <Button onClick={resetData} type="default" icon={<i className="pi pi-history" />}>
                    {t('reset')}
                </Button>
                <Input
                    placeholder={t('search_placeholder')}
                    enterButton
                    allowClear
                    onKeyDown={handleSearch}
                    ref={inputRef}
                />
            </Space>
            <Table
                columns={columns}
                dataSource={data}
                rowKey="IDBranch"
                pagination={false}
                bordered
                scroll={{ x: '100%' }}
            />

            <Pagination
                current={page}
                pageSize={10}
                total={totalPages * 10}
                onChange={(page) => setPage(page)}
                style={{ marginTop: 16, textAlign: 'center' }}

            />

            <ShowGoogleMapModal openMap={openMap} hideImagesProjectsDialog={hideImagesProjectsDialog} isLang={isLang} />
        </div>
    );
};

export default Branch;
