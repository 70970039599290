import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateBrandContacts = () => {
  let { id, idEdit } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/contacts/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);


  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={`/brands/contacts/${id}`}>{t('brand_name')}</Link> },
    { label: <Link to={`/brands/contacts/${id}/edit/${idEdit}`} className='p-2 border-round'>{t('brand_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/contacts/edit/page/${idEdit}`)
    if (data?.Success === true) {
      setData(data?.Response);
    }
  }


  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandContactName: Data?.BrandContactName || '',
      BrandContactPhone: Data?.BrandContactPhone || '',
      BrandContactTitle: Data?.BrandContactTitle || '',
      IDBrandContact: idEdit,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/brands/contacts/${id}`)
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });



  useEffect(() => {
    formik.setValues({
      BrandContactName: Data?.BrandContactName || '',
      BrandContactPhone: Data?.BrandContactPhone || '',
      BrandContactTitle: Data?.BrandContactTitle || '',
      IDBrandContact: idEdit,

    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandContactName" className="font-bold block mb-2">{t('name')}</label>
            <InputText
              name='BrandContactName'
              id="BrandContactName"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandContactName}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandContactPhone" className="font-bold block mb-2">{t('phone')}</label> 

            <InputNumber
              value={formik.values.BrandContactPhone}
              name="BrandContactPhone"
              id="BrandContactPhone"
              className="w-full"
              inputClassName="p-inputtext-sm" 
              onBlur={formik.handleBlur}
              onValueChange={formik.handleChange}
              mode="decimal"
              useGrouping={false}
              dir="ltr"
              pattern="\d*"
              maxLength={12}
            />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandContactTitle" className="font-bold block mb-2">{isLang === "en" ? 'Brand Contact Title' : 'عنوان جهة اتصال العلامة التجارية'}</label>
            <InputText
              name='BrandContactTitle'
              id="BrandContactTitle"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandContactTitle}
            />
          </div>

        </div>




        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          {
            localStorage.getItem('Role') == 1 &&
            <Link to={'/brands'}>
              <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
            </Link>
          }

          {
            localStorage.getItem('Role') == 2 &&
            <Link to={`/brands/contacts/${id}`}>
              <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
            </Link>
          }
        </div>

      </form>
    </div>
  )
}

export default UpdateBrandContacts