

import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateBonanza = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);

  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/bonanza/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);

  const [poaition, setPosition] = useState(false);
  const [selectTotalPoints, setSelectTotalPoints] = useState(false);
  const [selectVisitNum, setSelectVisit] = useState(false);
  const [referralNum, setSelectReferral] = useState(false);
  const [productValue, setProductValue] = useState(false);
  const [Specialvisit, setSpecialVisit] = useState(false);
  const [gifts, setGifts] = useState(1);

  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/bonanza'}>{t('bonanza_name')}</Link> },
    { label: <Link to={`/bonanza/edit/${id}`} className='px-3 py-2 border-round'>{t('update_bonanza')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/bonanza/edit/page/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response);
    }
  }

  useEffect(() => {
    fetchData()
  }, [isLang]);


  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDBonanza: id,
      BonanzaTitleEn: Data?.BonanzaTitleEn || '',
      BonanzaTitleAr: Data?.BonanzaTitleAr || '',
      BonanzaLeftPoints: Data?.BonanzaLeftPoints || 0,
      BonanzaRightPoints: Data?.BonanzaRightPoints || 0,
      BonanzaTotalPoints: Data?.BonanzaTotalPoints || 0,
      BonanzaStartTime: Data?.BonanzaStartTime || '',
      BonanzaEndTime: Data?.BonanzaEndTime || '',
      BonanzaVisitNumber: Data?.BonanzaVisitNumber || 0,
      BonanzaReferralNumber: Data?.BonanzaReferralNumber || 0,
      BonanzaRewardPoints: Data?.BonanzaRewardPoints || 0,
      BonanzaChequeValue: Data?.BonanzaChequeValue || 0,
      BonanzaProductValue: Data?.BonanzaProductValue || 0,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/bonanza')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });



  useEffect(() => {
    formik.setValues({
      IDBonanza: id,
      BonanzaTitleEn: Data?.BonanzaTitleEn || '',
      BonanzaTitleAr: Data?.BonanzaTitleAr || '',
      BonanzaLeftPoints: Data?.BonanzaLeftPoints || 0,
      BonanzaRightPoints: Data?.BonanzaRightPoints || 0,
      BonanzaTotalPoints: Data?.BonanzaTotalPoints || 0,
      BonanzaVisitNumber: Data?.BonanzaVisitNumber || 0,
      BonanzaEndTime: Data?.BonanzaEndTime ? new Date(Data.BonanzaEndTime) : '',
      BonanzaStartTime: Data?.BonanzaStartTime ? new Date(Data.BonanzaStartTime) : '',
      BonanzaReferralNumber: Data?.BonanzaReferralNumber || 0,
      BonanzaRewardPoints: Data?.BonanzaRewardPoints || 0,
      BonanzaChequeValue: Data?.BonanzaChequeValue || 0,
      BonanzaProductValue: Data?.BonanzaProductValue || 0,
    });

    if (Data?.BonanzaLeftPoints > 0 || Data?.BonanzaRightPoints > 0) {
      setPosition(true)
    }
    if (Data?.BonanzaTotalPoints > 0) {
      setSelectTotalPoints(true)
    }
    if (Data?.BonanzaVisitNumber > 0) {
      setSelectVisit(true)
    }
    if (Data?.BonanzaReferralNumber > 0) {
      setSelectReferral(true)
    }
    if (Data?.BonanzaProductValue > 0) {
      setProductValue(true)
    }
    if (Data?.BonanzaRewardPoints > 0) {
      setGifts(1)
    }
    if (Data?.BonanzaChequeValue > 0) {
      setGifts(0)
    }
  }, [Data, formik.setValues]);


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid     ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BonanzaTitleEn" className="  block mb-2">{t('title_en')}</label>
            <InputText
              name='BonanzaTitleEn'
              id="BonanzaTitleEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BonanzaTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BonanzaTitleAr" className="  block mb-2">{t('title_ar')}</label>
            <InputText
              value={formik.values.BonanzaTitleAr}
              name='BonanzaTitleAr'
              id="BonanzaTitleAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid flex  mt-2 align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12   input__Col ">
            <div className="flex align-items-center gap-2">
              <Checkbox onChange={e => setPosition(e.checked)} checked={poaition}></Checkbox>
              <label htmlFor="selectTotalPoints1" className="ml-2 font-bold ">{isLang === "en" ? 'Balance points ' : 'اختر الموقع الذي يظهر فيه بونانزا'}</label>
            </div>
          </div>
          {
            poaition &&
            <>
              <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                <label htmlFor="BonanzaLeftPoints" className="  block  ">{t('ClientLeftPoints')}</label>
                <InputText
                  name='BonanzaLeftPoints'
                  id="BonanzaLeftPoints"
                  type="number"
                  className="w-full"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.BonanzaLeftPoints}
                />
              </div>

              <div className="lg:col-6 md:col-12 sm:col-12   input__Col" >
                <label htmlFor="BonanzaRightPoints" className="  block  ">{t('ClientRightPoints')}</label>
                <InputText
                  value={formik.values.BonanzaRightPoints}
                  name='BonanzaRightPoints'
                  id="BonanzaRightPoints" type="number" className="w-full" onChange={formik.handleChange}
                  onBlur={formik.handleBlur} />
              </div>
            </>
          }

        </div>

        <div className="grid flex   align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
            <div className="flex align-items-center gap-2">
              <Checkbox onChange={e => setSelectTotalPoints(e.checked)} checked={selectTotalPoints}></Checkbox>
              <label htmlFor="selectTotalPoints1" className="ml-2 font-bold ">{t('ClientTotalPoints')}</label>
            </div>
          </div>
          {
            selectTotalPoints &&
            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
              <label htmlFor="BonanzaTotalPoints" className="  block  "></label>
              <InputText
                name='BonanzaTotalPoints'
                id="BonanzaTotalPoints"
                value={formik.values.BonanzaTotalPoints}
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          }
        </div>
        <div className="grid flex   align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
            <div className="flex align-items-center gap-2">
              <Checkbox onChange={e => setSelectVisit(e.checked)} checked={selectVisitNum}></Checkbox>
              <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('totalvisits')}</label>
            </div>
          </div>
          {
            selectVisitNum &&
            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
              <label htmlFor="BonanzaVisitNumber" className="  block  "> </label>
              <InputText
                name='BonanzaVisitNumber'
                id="BonanzaVisitNumber"
                value={formik.values.BonanzaVisitNumber}
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          }
        </div>

        <div className="grid flex   align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
            <div className="flex align-items-center gap-2">
              <Checkbox onChange={e => setSelectReferral(e.checked)} checked={referralNum}></Checkbox>
              <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ReferralNumber')}</label>
            </div>
          </div>
          {
            referralNum &&
            <div className="lg:col-6 md:col-12 sm:col-12    input__Col ">
              <InputText
                name='BonanzaReferralNumber'
                id="BonanzaReferralNumber"
                type='number'
                value={formik.values.BonanzaReferralNumber}
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          }
        </div>


        <div className="grid flex   align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
            <div className="flex align-items-center gap-2">
              <Checkbox onChange={e => setProductValue(e.checked)} checked={productValue}></Checkbox>
              <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{isLang === "en" ? 'Product Value' : 'قيمة منتج  '}</label>
            </div>
          </div>
          {
            productValue &&
            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
              <label htmlFor="BonanzaProductValue" className="  block  "></label>
              <InputText
                name='BonanzaProductValue'
                id="BonanzaProductValue"
                type='number'
                className="w-full  p-inputtext-sm"
                value={formik.values.BonanzaProductValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          }
        </div>
        <div className="grid flex   align-items-center">
          <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
            <div className="flex flex-row gap-5">
              <h4>{isLang === "en" ? 'Choose how to receive gifts?' : 'اختيار طريقة استلام الهدايا'}</h4>
              <div className="flex align-items-center gap-2">
                <RadioButton inputId="gifts1" name="BonanzaLeftPoints" value={1} onChange={(e) => setGifts(e.value)} checked={gifts === 1} />
                <label htmlFor="gifts1" className="ml-2">{t('ClientRewardPoints')}</label>
              </div>

              <div className="flex align-items-center gap-2">
                <RadioButton inputId="gifts2" name="BonanzaRightPoints" value={0} onChange={(e) => setGifts(e.value)} checked={gifts === 0} />
                <label htmlFor="gifts2" className="ml-2">{t('ChequeValue')}</label>
              </div>
            </div>
          </div>
          {
            gifts === 1 &&
            <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
              {/* <label htmlFor="BonanzaRewardPoints" className="  block  ">{t('ClientRewardPoints')}</label> */}
              <InputText
                name='BonanzaRewardPoints'
                id="BonanzaRewardPoints"
                type='number'
                value={formik.values.BonanzaRewardPoints}
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={gifts === 1 && true}
              />
            </div>
          }

          {
            gifts === 0 &&
            <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
              {/* <label htmlFor="BonanzaChequeValue" className="  block  ">{t('ChequeValue')}</label> */}
              <InputText
                name='BonanzaChequeValue'
                id="BonanzaChequeValue"
                type='number'
                className="w-full  p-inputtext-sm"
                value={formik.values.BonanzaChequeValue}

                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={gifts === 0 && true}
              />
            </div>
          }


        </div>


        <div className="grid flex mt-3  align-items-center">
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BonanzaStartTime" className="  block  "> {t('start_date')}</label>
            <Calendar
              id="BonanzaStartTime"
              name="BonanzaStartTime"
              value={formik.values.BonanzaStartTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event start time' : 'وقت بداية الحدث'}

              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
            />
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
          <label htmlFor="BonanzaEndTime" className="  block  "> {t('end_date')}</label>
            <Calendar
              id="BonanzaEndTime"
              name="BonanzaEndTime"
              value={formik.values.BonanzaEndTime}
              onChange={formik.handleChange}
              placeholder={isLang === "en" ? 'Event end time' : 'وقت نهاية الحدث'}

              showIcon
              showTime
              hourFormat="24"
              className="w-full"
              iconPos="right"
              icon="pi pi-clock"
              minDate={
                formik.values.BonanzaStartTime
                  ? new Date(formik.values.BonanzaStartTime.getTime())
                  : new Date()
              }
            />
          </div>

        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/bonanza'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>


      </form>


    </div>
  )
}
export default UpdateBonanza
