
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import BrandRating from './BrandRating/BrandRating';
import BrandSocialMedia from './BrandSocialMedia';
import { TieredMenu } from 'primereact/tieredmenu';
import { FetchApi } from 'context/FetchApi';
import { format } from 'date-fns';

const Brand = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [modalDone, setModalDone] = useState(false);
  const [modalRating, setModalRating] = useState(false);
  const [dataSocialMedia, setdataSocialMedia] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  let { role } = useContext(FetchApi)
  const inputRef = useRef(null);

  const handelSocialMedai = (data) => {
    setdataSocialMedia(data)
    setModalDone(true)
  }
  const handelRating = (data) => {
    setdataSocialMedia(data)
    setModalRating(true)
  }
  const [viewDialogVisible, setViewDialogVisible] = useState(false);
  const [viewData, setViewData] = useState(null);
  const hideViewDialog = (data) => {
    setViewDialogVisible(!viewDialogVisible)
    setViewData(data)
  };
  // Add new brands
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/brands/add">
          <Button label={t('brand_add')} severity="warning" icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

      </div>
    );
  };
  // handel btn action in tabel 
  const menu = useRef(null);
  const [idClients, setIdClients] = useState(null);
  const editClientsPermission = role?.EDIT_BRANDS === 1;

  const items = [
    {
      label: t('brand_view'),
      icon: 'pi pi-eye',
      command: () => {
        hideViewDialog(idClients)
      },
    },

    {
      label: <Link to={`/brands/contract/${idClients?.IDBrand}`}>{t('brand_contract')}</Link>,
      icon: 'pi pi-file',
    },
    {
      label: <Link to={`/brands/gallery/${idClients?.IDBrand}`}>{t('brand_gallery')}</Link>,
      icon: 'pi pi-images',
    },
    {
      label: <Link to={`/brands/contacts/${idClients?.IDBrand}`}>{t('brand_contacts')}</Link>,
      icon: 'pi pi-phone',
    },
    {
      label: t('brand_SocialMedia'),
      icon: 'pi pi-megaphone',
      command: () => {
        handelSocialMedai(idClients)
      },
    },
    ...(role?.VIEW_BRAND_REVIEWS == 1 ? [
      {
        label: t('brand_reviews'),
        icon: 'pi pi-star',
        command: () => {
          handelRating(idClients)
        },
      },
    ]
      : []),



    ...(editClientsPermission ?
      [
        ...(localStorage.getItem('Role') == 1 ? [
          {
            label: <Link to={`/log/${idClients?.IDBrand}/BRAND`}>{t('log')}</Link>,
            icon: 'pi pi-bookmark',
          },
          {
            label: <Link to={`/brands/edit/${idClients?.IDBrand}`}>{t('brand_edit')}</Link>,
            icon: 'pi pi-pencil',
          },
        ]
          : []),
      ]
      : []),
  ];
  const actionBodyTemplate = (rowData) => {

    return (
      <div className="flex   justify-content-center align-content-center">
        <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
        <Button
          size='small' text raised severity='warning' icon='pi pi-ellipsis-h'
          onClick={(e) => {
            menu.current.toggle(e)
            setIdClients(rowData);
          }} />
      </div>
    );
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">  </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/brands`;
    let data = await axios.post(url, { IDPage: page + 1 ,SearchKey},
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Brands);
    setPagesNum(data?.data?.Response?.Pages)

  }

  useEffect(() => {
    fetchData();
  }, [isLang, page])

  // location path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])

  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
    { label: t('pending'), value: 'PENDING' },
  ];   
   const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
}

  const onStatusChange = async (rowData, e) => {

    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/brands/status`, {
      IDBrand: rowData?.IDBrand,
      BrandStatus: e.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success === true) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    return editingRow === rowData?.IDBrand ? (
      <Dropdown
        value={rowData?.BrandStatus}
        options={statusOptions}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.BrandStatus === "ACTIVE" ? t('active') : rowData?.BrandStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.BrandStatus)}
        onClick={() => {
          if (editClientsPermission) {
            setEditingRow(rowData?.IDBrand)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>{`${rowData?.BrandStatus === "ACTIVE" ? t('active') : rowData?.BrandStatus === "INACTIVE" ? t('inactive') : t('pending')}`}    </span>
          {
            editClientsPermission &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center image_prime p-4' >
      <Image
        preview
        src={rowData.BrandLogo}
        alt={rowData.BrandLogo}
        width='100px'
        height='100px'
        className='   overflow-hidden  '
        style={{ objectFit: 'cover', borderRadius: '50%' }}
      />
    </div>
  };


  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';

      case 'ACTIVE':
        return 'success';

      case 'PENDING':
        return 'warning';

      case '':
        return 'info';
    }
  };
  const CreateDate = (rowData) => {
    const formattedDate = format(new Date(rowData?.CreateDate), 'dd-MM-yyyy');
    return <span>{formattedDate}</span>;
  };
  return (
    <>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          role?.ADD_BRANDS == 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }
        <DataTable className="custom-datatable"
          scrollable
          scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          size='small'
          globalFilter={globalFilter}
          header={header}>
          <Column field="BrandLogo" header={t('logo')} body={imageBodyTemplate}></Column>
          <Column field="BrandName" header={t('tabel_brand_header_1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="BrandNumber" header={t('tabel_brand_header_3')} style={{ textAlign: 'center' }}></Column>
          <Column field="SalesName" header={t('tabel_brand_header_4')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column field="SalesPhone" header={t('tabel_brand_header_5')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column field="BrandActive" header={t('status')} body={statusBodyTemplate} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column field="CreateDate" header={t('CreateDate')} body={CreateDate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
        
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }} header={t('action')}></Column>
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />


        <Dialog
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          visible={viewDialogVisible}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? 'Brand Information' : 'معلومات العلامة التجارية'}
          modal
          onHide={hideViewDialog}
        >
          <div className='flex flex-column gap-3'>
            <div className='flex flex-row gap-3'>
              <strong>{t('name')} : </strong>
              <span>{viewData?.BrandName}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('desc')} : </strong>
              <span>{viewData?.BrandDesc}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('tabel_brand_header_7')} : </strong>
              <span>{viewData?.BrandPolicy}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('email')} : </strong>
              <span>{viewData?.BrandEmail}</span>
            </div>
            <hr />

            <div className='flex flex-row gap-3'>
              <strong>{t('tabel_brand_header_6')} : </strong>
              <span>{viewData?.BrandRating}</span>
            </div>




          </div>
        </Dialog>
        <BrandSocialMedia
          modalDone={modalDone}
          setModalDone={setModalDone}
          dataSocialMedia={dataSocialMedia}
        />

        <BrandRating
          modalDone={modalRating}
          setModalDone={setModalRating}
          dataSocialMedia={dataSocialMedia}
          role={role?.EDIT_BRAND_REVIEWS}
        />

      </div>

    </>
  )
}
export default Brand