
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
const Tools = () => {
    let { role } = useContext(FetchApi)
    const inputRef = useRef(null);

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);

    // Add new Tools
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/tools/add">
                    <Button severity="warning" label={t('add_tools')} icon="pi pi-plus" size='small' />
                </Link>
                <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

            </div>
        );
    };

    // delete users
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [IdDeleteusers, setIdDeleteusers] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const confirmDeleteusers = async (users) => {
        setDeleteusersDialog(true);
        setIdDeleteusers(users?.IDTool)
    };
    const deleteusers = async (rowData) => {
        setdeleteLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/tools/status`, {
            IDTool: IdDeleteusers,
            ToolStatus: 'DELETED'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                setTimeout(() => {
                    setDeleteusersDialog(false);
                    fetchData()
                    setdeleteLoadind(false)
                }, 1000);
            } else {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: data?.ApiMsg, life: 3000 });
                setdeleteLoadind(false)

            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
        </div>
    );
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />

                <Link to={`/tools/details/${rowData.IDTool}`}>
                    <Button className="disabled-button" icon="pi pi-eye " rounded raised text severity='warning' tooltip={t('details_tools')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                {
                    role?.EDIT_TOOLS == 1 &&
                    <>
                        <Link to={`/tools/edit/${rowData.IDTool}`}>
                            <Button icon="pi pi-pencil" rounded outlined raised text className="mr-2" tooltip={t('update_tools')} tooltipOptions={{ position: t('tooltip') }} />
                        </Link>
                        <Button disabled={rowData?.EventStatus === "ONGOING"} icon="pi pi-trash" text raised rounded outlined severity="danger" onClick={() => confirmDeleteusers(rowData)} />
                    </>

                }


                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDTool}/TOOL`}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }

            </div>
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    className="w-full p-inputtext-sm"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            fetchData(e.target.value);
                        }
                        if (e.target.value == '') {
                            fetchData(e.target.value);
                        }
                    }}
                    placeholder={t('search_placeholder')}
                    ref={inputRef}
                />            </span>
        </div>
    );
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    const fetchData = async (SearchKey) => {

        const url = `${process.env.REACT_APP_API_URL}/tools`;
        let data = await axios.post(url, { IDPage: page + 1, SearchKey },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Tools);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])
    const resetData = () => {
        fetchData();
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال
    }
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
        { label: t('pending'), value: 'PENDING' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/tools/status`, {
            IDTool: rowData?.IDTool,
            ToolStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.ToolStatus === "ACTIVE" ? t('active') : rowData?.ToolStatus === "INACTIVE" ? t('inactive') : rowData?.ToolStatus === "ONGOING" ? t('ONGOING') : rowData?.ToolStatus === "CANCELLED" ? t('CANCELLED') : t('pending');


        return editingRow === rowData?.IDTool ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.ToolStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.ToolStatus === "ACTIVE" ? t('active') : rowData?.ToolStatus === "INACTIVE" ? t('inactive') : rowData?.ToolStatus === "ONGOING" ? t('ONGOING') : rowData?.ToolStatus === "CANCELLED" ? t('CANCELLED') : t('pending')}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.ToolStatus)}
                onClick={() => {
                    if (role?.EDIT_TOOLS == 1) {
                        setEditingRow(rowData?.IDTool)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>
                        {`${rowData?.ToolStatus === "ACTIVE" ? t('active') : rowData?.ToolStatus === "INACTIVE" ? t('inactive') : rowData?.ToolStatus === "ONGOING" ? t('ONGOING') : rowData?.ToolStatus === "CANCELLED" ? t('CANCELLED') : rowData?.ToolStatus === "CANCELLED" ? t('CANCELLED') : t('pending')}`}
                    </span>
                    {
                        (role?.EDIT_TOOLS == 1) &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    //  ,ONGOING,,CANCELLED, 


    const getSeverity = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'success';

            case 'INACTIVE':
                return 'danger';

            case 'PENDING':
                return 'warning';

            case 'CANCELLED':
                return 'danger';

            case 'ONGOING':
                return 'help';

            case '':
                return 'info';
        }
    };

    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_TOOLS == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rows={10}
                    header={header}>
                    <Column field="ToolTitle" header={t('title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ToolDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ToolType" header={t('Type')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ToolPrice" header={t('price')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ToolPoints" header={t('ClientRewardPoints')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ToolStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                    </span>
                </div>
            </Dialog>
        </div>
    )
}
export default Tools
