import AddGallery from 'Page/Upload/AddGallery';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const AddProducts = () => {
  let { subCategoriesAjex, fetchSubCategoriesAjex, fetchBrandsAjex, brandsAjex } = useContext(FetchApi)
  let { multiImage, isLang, setMultiImage } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/products/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const [value, setValue] = useState(0);

  const DiscountType = [
    { name: 'Value', value: 'VALUE' },
    { name: 'Precent', value: 'PERCENT' },
  ];
  const items = [
    { label: <Link to={`/products`}>{t('products')}   </Link> },
    { label: <Link to={`/products/add`} className='p-2 border-round'>  {t('products_add')}  </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      IDBrand: null,
      IDSubCategory: null,
      BrandProductTitleEn: '',
      BrandProductTitleAr: '',
      BrandProductDescEn: '',
      BrandProductDescAr: '',
      BrandProductStartDate: '',
      BrandProductEndDate: '',
      BrandProductDiscount: '',
      BrandProductPoints: '',
      BrandProductPrice: '',
      /*   BrandProductReferralPoints: '',
           BrandProductUplinePoints: '', */
      BrandProductDiscountType: '',
      BrandProductGallery: multiImage,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          setMultiImage([])

          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/products`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    fetchSubCategoriesAjex()
    fetchBrandsAjex()
    return () => {
      fetchSubCategoriesAjex()
      fetchBrandsAjex()
    }
  }, [isLang])

  const fetchDataDateContracts = async (id) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/edit/page/${id}`);
      if (data?.Success === true) {
        const brandContractStartDate = data?.Response?.BrandContract?.BrandContractStartDate;
        const formattedDate = brandContractStartDate ? format(new Date(brandContractStartDate), 'yyyy-MM-dd') : null;
        const brandContractEndDate = data?.Response?.BrandContract?.BrandContractEndDate;
        const formattedDateEnd = brandContractEndDate ? format(new Date(brandContractEndDate), 'yyyy-MM-dd') : null;

        // Update formik values with formatted date if it exists
        if (formattedDate !== null) {
          formik.setFieldValue("BrandProductStartDate", formattedDate);
        }

        if (formattedDateEnd !== null) {
          formik.setFieldValue("BrandProductEndDate", formattedDateEnd);
        }

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    formik.setFieldValue('BrandProductGallery', multiImage);
  }, [multiImage]); // يتم استدعاء useEffect عندما تتغير multiImage

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mb-4">
          <label htmlFor="BrandNameEn" className="font-bold block mb-2"> {t('BrandProductGallery')} </label>
          <AddGallery />
        </div>
        <div className="grid">
          <div className="lg:col-6 md:col-12 sm:col-12      ">
            <label htmlFor="IDSubCategory" className="font-bold block mb-2"> {t('categories_title')}</label>
            <Dropdown filter
              options={subCategoriesAjex?.map(item => ({
                name: item.SubCategoryName,
                value: item.IDSubCategory,
              }))}
              id="IDSubCategory"
              name="IDSubCategory"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDSubCategory} // Add this line 
              onChange={(e) => formik.setFieldValue("IDSubCategory", e.value)}
              onBlur={formik.handleBlur}
              placeholder={t('SubCategory_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12      ">
            <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('brand_name')}</label>
            <Dropdown filter
              options={brandsAjex?.map(item => ({
                name: item.BrandName,
                value: item.IDBrand,
              }))}
              id="IDBrand"
              name="IDBrand"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDBrand} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDBrand", e.value)
                fetchDataDateContracts(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('brand_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-3 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductStartDate" className="font-bold block mb-2">{t('start_date')}</label>
            <Calendar
              id="BrandProductStartDate"
              name="BrandProductStartDate"
              value={formik.values.BrandProductStartDate ? new Date(formik.values.BrandProductStartDate) : null}

              placeholder={isLang === "en" ? 'Start date' : 'تاريخ بدأ'}
              onChange={(e) => {
                const formattedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                if (formattedDate !== null) {
                  formik.setFieldValue("BrandProductStartDate", formattedDate);
                }
              }}
              showIcon
              className='w-full'
              dateFormat="yy-mm-dd"  // Specify your desired date format here
            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductEndDate" className="font-bold block mb-2">{t('end_date')}</label>
            <Calendar
              id="BrandProductEndDate"
              name="BrandProductEndDate"
              value={formik.values.BrandProductEndDate ? new Date(formik.values.BrandProductEndDate) : null}

              placeholder={isLang === "en" ? 'End date' : 'تاريخ بدأ'}
              onChange={(e) => {
                const formattedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : null;
                if (formattedDate !== null) {
                  formik.setFieldValue("BrandProductEndDate", formattedDate);
                }
              }}
              showIcon
              className='w-full'
              dateFormat="yy-mm-dd"  // Specify your desired date format here
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2">{t('label_product_name_en')}</label>
            <InputText
              name='BrandProductTitleEn'
              id="BrandProductTitleEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">{t('label_product_name_ar')}</label>
            <InputText
              name='BrandProductTitleAr'
              id="BrandProductTitleAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductDescEn" className="font-bold block mb-2">{t('label_product_Desc_en')}</label>
            <InputText
              name='BrandProductDescEn'
              id="BrandProductDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">{t('label_product_Desc_ar')}</label>
            <InputText
              name='BrandProductDescAr'
              id="BrandProductDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='BrandProductPrice'
              id="BrandProductPrice"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="BrandProductPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='BrandProductPoints'
              id="BrandProductPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          {/* <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-3 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductStartDate" className="font-bold block mb-2">{t('start_date')}</label>
            <Calendar
              id="BrandProductStartDate"
              name="BrandProductStartDate"
              value={formik.values.BrandProductStartDate}
              placeholder={isLang === "en" ? ' start date' : '  تاريخ بدأ   '}
              onChange={(e) => {
                formik.setFieldValue("BrandProductStartDate", e.value)
              }}
              showIcon
              className='w-full'
            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandProductEndDate" className="font-bold block mb-2">{t('end_date')}</label>
            <Calendar
              id="BrandProductEndDate"
              name="BrandProductEndDate"
              value={formik.values.BrandProductEndDate}
              placeholder={isLang === "en" ? ' end date' : '  تاريخ نهاية   '}
              onChange={(e) => {
                formik.setFieldValue("BrandProductEndDate", e.value)
              }}
              showIcon
              className='w-full'

            />
          </div> */}

          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductReferralPoints" className="font-bold block mb-2">{t('ReferralPoints')}</label>
            <InputText
              name='BrandProductReferralPoints'
              id="BrandProductReferralPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div> */}

          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductUplinePoints" className="font-bold block mb-2">  {t('UplinePoints')}  </label>
            <InputText
              name='BrandProductUplinePoints'
              id="BrandProductUplinePoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div> */}

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductDiscountType" className="font-bold block mb-2"> {t('Discounttype')} </label>
            <Dropdown filter
              id="BrandProductDiscountType"
              name="BrandProductDiscountType"
              value={formik.values.BrandProductDiscountType}
              optionLabel="name"
              onChange={(e) => formik.setFieldValue("BrandProductDiscountType", e.value)}
              onBlur={formik.handleBlur}
              options={DiscountType}
              placeholder={t('Discounttype')}
              className="w-full  "

            />

          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandProductDiscount" className="font-bold block mb-2">{t('Discount')}</label>
            {
              formik.values.BrandProductDiscountType === "VALUE" ?
                <InputNumber
                  value={value}
                  name='BrandProductDiscount'
                  onChange={(e) => {
                    formik.setFieldValue('BrandProductDiscount', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="BrandProductDiscount"
                  className="w-full  p-inputtext-sm"
                />
                :
                <InputNumber
                  value={value}
                  name='BrandProductDiscount'
                  onChange={(e) => {
                    formik.setFieldValue('BrandProductDiscount', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="BrandProductDiscount"
                  className="w-full  p-inputtext-sm"
                  prefix="%" />
            }


          </div>



        </div>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/products`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default AddProducts