import Component from 'constants/Component';
import FetchApiContext from 'context/FetchApi';
import LangChange, { LocalizationContext } from 'context/LangChange';
import { Tooltip } from 'primereact/tooltip';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import './style/App.scss';
import { useContext, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import General from 'context/General';

function App() {

  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('tokenBetter')
    if (!token) {
      return <Navigate to="/login" replace={true} />
    } else {
      return children;
    }
  }
  const root = createBrowserRouter([
    {
      path: '', element: <Component.BetterWay />, children: [
        { index: true, element: <ProtectedRoutes><Component.Dashboard /></ProtectedRoutes> }, 

        {
          path: '/reviews/:id', children: [
            { index: true, element: <ProtectedRoutes><Component.BrandReviews /></ProtectedRoutes> },
          ]
        }, 
        
        {
          path: '/brands/profile/:id', children: [
            { index: true, element: <ProtectedRoutes><Component.ProfileBrand /></ProtectedRoutes> },
          ]
        },
        {
          path: '/brands/contract/:id', children: [
            { index: true, element: <ProtectedRoutes><Component.BrandContract /></ProtectedRoutes> },
          ]
        },
        {
          path: '/brands/gallery/:id', children: [
            { index: true, element: <ProtectedRoutes><Component.BrandGallery /></ProtectedRoutes> },
          ]
        },
        {
          path: '/brands/contacts/:id', children: [
            { index: true, element: <ProtectedRoutes><Component.BrandContacts /></ProtectedRoutes> },
          ]
        },

        {
          path: '/products', children: [
            { index: true, element: <ProtectedRoutes><Component.Products /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateProducts /></ProtectedRoutes> }
          ]
        },
        {
          path: '/branch', children: [
            { index: true, element: <ProtectedRoutes><Component.Branch /></ProtectedRoutes> },
            { path: 'edit/:id', element: <ProtectedRoutes><Component.UpdateBranch /></ProtectedRoutes> }
          ]
        },

        {
          path: '*', element: <Component.Error />
        }
      ],
    },
    {
      path: '/login', element: <Component.Auth />, children: [
        { index: true, element: <Component.Login /> },
      ]
    },
  ])
  const tooltipOptions = {
    position: 'top',
    style: { backgroundColor: 'blue' },
  };

  async function LogOut() {
    localStorage.removeItem("tokenBetter");
    localStorage.removeItem("IDBrand");
    localStorage.removeItem("IDUser");
    localStorage.removeItem("UserEmail");
    localStorage.removeItem("tokenBetter");
    localStorage.removeItem("Role");
    return <Navigate to="/login" replace={true} />
  }
  useEffect(() => {
    let session = localStorage.getItem('AdminSessionTimeout')
    let Role = localStorage.getItem('Role')

    if (Role == 1) {
      setTimeout(() => {
        LogOut();
      }, 5 * 60 * 1000);
    }
  }, [])


  return (
    <div className="App">
      <Tooltip target=".disabled-button" autoHide={false} options={tooltipOptions} />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#F1592A', 
            borderRadius: '6px',
            fontFamily: 'cairo, sans-serif',
          },

          components: {
            Button: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Input: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Tabs: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            },
            Checkbox: {
              colorPrimary: '#27BDB0',
              algorithm: true, // Enable algorithm

            },
            AutoComplete: {
              colorPrimary: '#F1592A',
              algorithm: true, // Enable algorithm
            }
          },
        }}
      >
        <FetchApiContext>
          <General>
            <LangChange>
              <RouterProvider router={root} />
            </LangChange>
          </General>
        </FetchApiContext>
      </ConfigProvider>
    </div>
  );
}

export default App;
