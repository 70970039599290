import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Nationalities = () => {
    let { role } = useContext(FetchApi)
    const dt = useRef(null);
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [Data, setData] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{t('search-areas')} </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className='p-inputtext-sm' onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );
    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/nationalities`;
        let data = await axios.post(url, {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        console.log(data?.data);
        setData(data?.data?.Response);
    }
    useEffect(() => {
        fetchData();
    }, [isLang])

    // Add new nationalities
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to={`/nationalities/add`}>
                    <Button label={t('nationalities')} severity="warning" icon="pi pi-plus" size='small' />
                </Link>
            </div>
        );
    };
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                {
                    role?.EDIT_NATIONALITIES == 1 &&
                    <Link to={`/nationalities/edit/${rowData.IDNationality}`}>
                        <Button size='small' text raised icon="pi pi-pencil" rounded outlined className=" disabled-button" tooltip={t('update_nationalities')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDNationality}/NATIONALITY`}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
            </div>
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])

    return (
        <>
            {
                role?.ADD_NATIONALITIES === 1 &&
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
            }
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <DataTable
                className="custom-datatable"
                scrollable scrollHeight="550px"
                ref={dt}
                value={Data}
                header={header}
                dataKey="id"
                showGridlines
                rowHover
    
          globalFilter={globalFilter} 
            >
                <Column field="NationalityNameEn" header={t('nationalities_en')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                <Column field="NationalityNameAr" header={t('nationalities_ar')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                {
                    (role?.EDIT_NATIONALITIES == 1 || localStorage.getItem('Role') == 1) &&
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                }
            </DataTable>

        </>
    )
}


export default Nationalities
