
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const ClientTools = () => {
  let { isLang } = useContext(LocalizationContext);
  let { id } = useParams() 
  let { t } = useTranslation()
  const dt = useRef(null); 
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);



  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">{t('tools')}</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
      </span>
    </div>
  );

  const fetchData = async () => {
    
    const url = `${process.env.REACT_APP_API_URL}/clients/tools`;
    let data = await axios.post(url, { IDClient: id },
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Tools);
  }
  useEffect(() => {
    fetchData();
  }, [isLang])

  const [editingRow, setEditingRow] = useState(null);




  const statusBodyTemplate = (rowData) => {
    return editingRow === rowData?.EventAttendeeStatus ? (
      <>
      </>
    ) : (
      <Tag
        value={`${rowData?.EventAttendeeStatus === "PAID" ? t('PAID') : rowData?.EventAttendeeStatus === "PENDING" ? t('pending') : t('CANCELLED')}`}
        severity={getSeverity(rowData.EventAttendeeStatus)}
        rounded
      />
    );
  }; 
  const getSeverity = (status) => {
    switch (status) {
      case 'PAID':
        return 'success'; 
      case 'PENDING':
        return 'warning'; 
      case 'CANCELLED':
        return 'danger'; 
      case '':
        return 'info'; 
    }
  };
 
  return (
    <div> 
      <div className="card mt-5">
        <DataTable className="custom-datatable"   scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          paginator   
          rows={10}
          header={header}>
          <Column   field="ClientToolPrice" header={t('ClientToolPrice')} style={{ textAlign: 'center' }}></Column>
          <Column   field="ToolPrice" header={t('price')} style={{ textAlign: 'center' }}></Column>
          <Column   field="ToolType" header={t('Type')} style={{ textAlign: 'center' }}></Column>
          <Column   field="ToolTitle" header={t('title')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
          <Column   field="ClientToolDownloaded" header={t('ClientToolDownloaded')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
         </DataTable>
      </div>

    </div>
  )
}


export default ClientTools
