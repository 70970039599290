import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const RoleDate = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [RolesDialog, setRolesDialog] = useState(false);
    const [updateRolesDialog, setUpdateRolesDialog] = useState(false);
    const [IdRoles, setIdRoles] = useState(null);
    const [Data, setData] = useState(null);
    const [loading, setLoadind] = useState(false);
    const [loading2, setLoadind2] = useState(false);
    const inputRef = useRef(null);
    const inputRefUpdates = useRef(null);
    const hideRolesDialog = () => setRolesDialog(false);
    const hideUpdateRolesDialog = () => setUpdateRolesDialog(false);
    const [checked, setChecked] = useState(null);

    // Add new Roles
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Button onClick={() => setRolesDialog(true)} severity="warning" label={t('add_roles')} icon="pi pi-plus" size='small' />
            </div>
        );
    };
    //  Roles
    const confirmRoles = async (Roles) => {
        setUpdateRolesDialog(true);
        setIdRoles(Roles)
        setChecked(Roles?.RoleActive === 1 ? true : false)

    };
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button icon="pi pi-pencil" disabled={rowData?.RoleChange === 0} onClick={() => confirmRoles(rowData)} text raised rounded outlined severity="" tooltip={t('update_roles')} tooltipOptions={{ position: t('tooltip') }} />
                <Link to={`/roles/sections/${rowData?.IDRole}`}>
                    <Button icon="pi pi-shield" disabled={rowData?.RoleChange === 0} onClick={() => confirmRoles(rowData)} text raised rounded outlined severity="" tooltip={t('permission')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
            </div>
        );
    };
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/roles`;
        let data = await axios.get(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }
    useEffect(() => {
        fetchData();
    }, [isLang])

    const updateRoles = async () => {
        setLoadind2(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/roles/edit`, {
            IDRole: IdRoles?.IDRole,
            RoleActive: checked === true ? 1 : 0,
            RoleName: inputRefUpdates.current.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {
            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                setTimeout(() => {
                    setUpdateRolesDialog(false);
                    fetchData()
                    setLoadind2(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setLoadind2(false)
        })

    };


    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.RoleActive === 1 ? t('active') : t('inactive');
        return <Tag value={editingStatus} rounded severity={getSeverity(rowData.RoleActive)} />
    };

    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.created_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };

    const addNewRole = async () => {
        setLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/roles/add`, {
            RoleName: inputRef.current.value,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {


            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
            setLoadind(false)
            setRolesDialog(false)

        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setLoadind(false)

        })
    }
    return (
        <div className='mb-8 '>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                <DataTable
                    className="custom-datatable"
                    scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    showGridlines
                    rowHover
                    globalFilter={globalFilter}
                    header={header}>
                    <Column  field="RoleName" header={t('role')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="RoleActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="created_at" header={t('created_at')} body={date} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column   field="UserRank" header={isLang === "en" ? "User Rank" : 'العنوان بالعربي '} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

            </div>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={RolesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Add Role' : 'إضافة أدوار جديدة '} modal onHide={hideRolesDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="RoleName" className="font-bold block mb-2">{isLang === "en" ? 'Role name' : 'إضافة أدوار جديدة '}</label>
                        <InputText
                            name='RoleName'
                            id="RoleName"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            ref={inputRef}
                        />
                        <div className="flex justify-content-center align-items-center mt-4 gap-3">
                            <Button loading={loading} label={t('submit')} size='small' raised icon="pi pi-check" severity="warning" onClick={() => addNewRole()} />
                            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' onClick={() => setRolesDialog(false)} />
                        </div>
                    </div>

                </div>
            </Dialog>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={updateRolesDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Update Role' : 'إضافة أدوار جديدة '} modal onHide={hideUpdateRolesDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12   mt-2 input__Col" >
                        <label htmlFor="RoleName" className="font-bold block mb-2">{isLang === "en" ? 'Role name' : 'إضافة أدوار جديدة '}</label>
                        <InputText
                            name='RoleName'
                            id="RoleName"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            ref={inputRefUpdates}
                            defaultValue={IdRoles?.RoleName || ''}
                        />
                        <div className=" mt-4">
                            <label htmlFor="RoleName" className="font-bold block mb-2">{t('status')}</label>
                            <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                        </div>
                        <div className="flex justify-content-center align-items-center mt-4 gap-3">
                            <Button loading={loading2} label={t('submit')} size='small' raised icon="pi pi-check" severity="warning" onClick={() => updateRoles()} />
                            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' onClick={hideUpdateRolesDialog} />
                        </div>
                    </div>

                </div>
            </Dialog>
        </div>
    );
};


export default RoleDate
