import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateUpgradeData = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/plans/products/upgrades/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/upgrades'}>{t('Upgrade')}</Link> },
    { label: <Link to={`/upgrades/edit/${id}`} className='p-2 border-round'>{t('edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/plans/products/upgrades/edit/page/${id}`)
    if (data?.Success === true) {
      setData(data?.Response);
      console.log(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      UpgradeNameEn: Data?.UpgradeNameEn || '',
      UpgradeNameAr: Data?.UpgradeNameAr || '',
      UpgradeAgencyNumber: Data?.UpgradeAgencyNumber || '',
      UpgradePrice: Data?.UpgradePrice || '',
      IDPlanProductUpgrade: id || '',

    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/upgrades')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    formik.setValues({
      UpgradeNameEn: Data?.UpgradeNameEn || '',
      UpgradeNameAr: Data?.UpgradeNameAr || '',
      IDPlanProductUpgrade: Data?.IDPlanProductUpgrade || '',
      UpgradeAgencyNumber: Data?.UpgradeAgencyNumber || '',
      UpgradePrice: Data?.UpgradePrice || '',

    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="UpgradeNameEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
            <InputText
              name='UpgradeNameEn'
              id="UpgradeNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.UpgradeNameEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="UpgradeAgencyNumber" className="font-bold block mb-2">{t('UpgradeAgencyNumber')}</label>
            <InputText
              value={formik.values.UpgradeNameAr}
              name='UpgradeNameAr'
              id="UpgradeNameAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="UpgradeAgencyNumber" className="font-bold block mb-2">  {t('UpgradeAgencyNumber')}</label>
            <InputText
              value={formik.values.UpgradeAgencyNumber}
              name='UpgradeAgencyNumber'
              id="UpgradeAgencyNumber" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="UpgradePrice" className="font-bold block mb-2">  {t('price')}</label>
            <InputText
              value={formik.values.UpgradePrice}
              name='UpgradePrice'
              id="UpgradePrice" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

        </div>



        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/upgrades'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateUpgradeData