import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const RoleSections = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);

    const items = [
        { label: <Link to={'/roles'}>{t('roles')}</Link> },
        { label: <Link to={`/roles/sections/${id}`} className='px-3 py-2 border-round'>{t('permission')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h1></h1>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>

        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/roles/sections`;
        let data = await axios.post(url, { IDRole: id, },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }

    useEffect(() => {
        fetchData();
    }, [isLang])





    const actionBodyTemplate = (rowData) => {
        const isChecked = rowData?.RoleSectionStatus === 1 ? true : false;
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <InputSwitch checked={isChecked} onChange={(e) => {
                    changeChecked(rowData?.IDRoleSection)
                }} />
            </div>
        );
    };
    const actionBodyBody = (rowData) => {
         return (
            <div className="flex gap-2 justify-content-center align-content-center">
              <span>{rowData?.Section?.replace(/_/g, ' ').charAt(0).toUpperCase() + rowData?.Section?.replace(/_/g, ' ').slice(1)?.toLowerCase()}</span>
            </div>
        );
    };


    const changeChecked = async (id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/roles/sections/status/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
        })

    };



    return (
        <div >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <BreadCrumb model={items} home={home} />

            <div className="card mt-4">
                <DataTable className="custom-datatable"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    size='small'
                    showGridlines
                    header={header}
                >
                    <Column body={actionBodyTemplate} header={isLang === "en" ? 'Change permission' : 'تغيير الإذن'} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    <Column  field="SectionNameEn" header={t('tabel_brand_header_1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  body={actionBodyBody} field="Section" header={t('Section')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>

                </DataTable>


            </div>

        </div>
    )
}



export default RoleSections