
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import { FetchApi } from 'context/FetchApi';

const PlanData = () => {

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  let { role } = useContext(FetchApi)

  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [deleteplansDialog, setDeleteplansDialog] = useState(false);
  const [IdDeleteplans, setIdDeleteplans] = useState(null);
  const [Data, setData] = useState(null);
  const [deleteLoadind, setdeleteLoadind] = useState(false);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const inputRef = useRef(null);

  // Add new plans
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/plans/add">
          <Button severity="warning" label={t('add_plans')} icon="pi pi-plus" size='small' />

        </Link>
          <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />
      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        <Tooltip target=".disabled-button" autoHide={false} />
        {
          role?.EDIT_PLANS == 1 &&
          <>
            <Link to={`/plans/edit/${rowData.IDPlan}`}>
              <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
            </Link>
            <Button icon="pi pi-trash" rounded outlined text raised severity="danger" onClick={() => confirmDeleteplans(rowData)} />
          </>
        }
        {
          localStorage.getItem('Role') == 1 &&
          <Link to={`/log/${rowData.IDPlan}/PLAN`}>
            <Button icon="pi pi-file" rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} text raised />
          </Link>
        }
      </div>
    );
  };
  // location path 
  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('logPath', currentPath)
  }, [])

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
      {/* <h4 className="m-0">{isLang === "en" ? "Search by name or mobile number or email " : 'بحث بالاسم او رقم جوال او بريد الالكتروني'} </h4> */}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />      </span>
    </div>
  );

  const fetchData = async (SearchKey) => {

    const url = `${process.env.REACT_APP_API_URL}/plans`;
    let data = await axios.post(url, {IDPage: page + 1, SearchKey},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.Plans);
    setPagesNum(data?.data?.Response?.Pages)

  }
  useEffect(() => {
    fetchData();
  }, [isLang, page])


  // delete plans
  const confirmDeleteplans = async (plans) => {
    setDeleteplansDialog(true);
    setIdDeleteplans(plans?.IDPlan)
  };
  const deleteplans = async (rowData) => {
    setdeleteLoadind(true)
    await axios.post(`${process.env.REACT_APP_API_URL}/plans/status`, {
      IDPlan: IdDeleteplans,
      PlanStatus: 'DELETED'
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

        setTimeout(() => {
          setDeleteplansDialog(false);
          fetchData()
          setdeleteLoadind(false)
        }, 1000);
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
      setdeleteLoadind(false)
    })

  };
  const hideDeleteplansDialog = () => setDeleteplansDialog(false);
  const deleteplansDialogFooter = (
    <div className='btn_gapAr'>
      <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteplansDialog} />
      <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteplans} />
    </div>
  );
  const [editingRow, setEditingRow] = useState(null);

  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Deactivated', value: 'DEACTIVATED' },
    { label: 'Pending', value: 'PENDING' },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/plans/status`, {
      IDPlan: rowData?.IDPlan,
      PlanStatus: e.value
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.PlanStatus === "ACTIVE" ? t('active') : rowData?.PlanStatus === "DEACTIVATED" ? t('deactivated') : t('pending');

    return editingRow === rowData?.IDPlan ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.PlanStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.PlanStatus === "ACTIVE" ? t('active') : rowData?.PlanStatus === "DEACTIVATED" ? t('deactivated') : t('pending')}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag
        severity={getSeverity(rowData.PlanStatus)}
        onClick={() => {
          if (role?.EDIT_PLANS == 1) {
            setEditingRow(rowData?.IDPlan)
          }
        }}
        rounded
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span>{`${rowData?.PlanStatus === "ACTIVE" ? t('active') : rowData?.PlanStatus === "DEACTIVATED" ? t('deactivated') : t('pending')}`}   </span>
          {
            (role?.EDIT_PLANS == 1) &&
            <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };


  const getSeverity = (status) => {
    switch (status) {
      case 'DEACTIVATED':
        return 'danger';

      case 'ACTIVE':
        return 'success';

      case 'PENDING':
        return 'warning';

      case '':
        return 'info';
    }
  };
  const resetData = () => {
    fetchData();
    inputRef.current.value = ''; // مسح محتوى حقل الإدخال
}
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">
        {
          role?.ADD_PLANS == 1 &&
          <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        }

        <DataTable className="custom-datatable" scrollable scrollHeight="550px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="PlanName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {/* <Column  field="PlanDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          {/* <Column   field="PlanPrice" header={t('price')} style={{ minWidth:'12rem',   textAlign: 'center' }}></Column> */}
          {/* <Column   field="AgencyNumber" header={t('AgencyNumber')}   style={{ minWidth:'12rem',   textAlign: 'center' }}></Column>  */}
          {/* <Column   field="PlanPoints" header={t('points')}   style={{ minWidth:'12rem',   textAlign: 'center' }}></Column>  */}
          {/* <Column   field="ReferralPoints" header={t('ReferralPoints')}   style={{  minWidth:'12rem',  textAlign: 'center' }}></Column>  */}
          {/* <Column   field="UplinePoints" header={t('UplinePoints')}   style={{  minWidth:'12rem',  textAlign: 'center' }}></Column>  */}
          {/* <Column   field="CardNumber" header={t('card')}   style={{ minWidth:'12rem',   textAlign: 'center' }}></Column>  */}
          <Column field="LeftBalanceNumber" header={t('LeftBalancePoint')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="RightBalanceNumber" header={t('RightBalancePoint')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="ChequeValue" header={t('ChequeValue')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="LeftMaxOutNumber" header={t('LeftMaxOutPoint')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="RightMaxOutNumber" header={t('RightMaxOutPoint')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="ChequeMaxOut" header={t('ChequeMaxOut')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {/* <Column  field="ChequeEarnTime" header={t('ChequeEarnTime')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          {/* <Column  field="ChequeDate" header={t('ChequeDate')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column field="PlanStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          {
            (role?.EDIT_PLANS == 1 || localStorage.getItem('Role') == 1) &&
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
          }
        </DataTable>
        <Paginator dir='ltr'
          first={page * 10} // Calculate the first record index based on the page
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>

      <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteplansDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteplansDialogFooter} onHide={hideDeleteplansDialog}>
        <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          <span>
            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
          </span>
        </div>
      </Dialog>

    </div>
  );
};


export default PlanData
