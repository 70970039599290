import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AddPosition = () => {
    const [referralNum, setSelectReferral] = useState(false);
    const [Specialvisit, setSpecialVisit] = useState(false);
    const [visitNum, setSelectVisit] = useState(false);
    const [chequeValueNum, setSelectChequeValue] = useState(false);
    const [giftsPoint, setGiftsPoint] = useState(null);
    const [gifts, setGifts] = useState(null);


    let { fetchSubCategoriesAjex, fetchBrandsAjex } = useContext(FetchApi)
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const url = `${process.env.REACT_APP_API_URL}/positions/add`;
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()


    const items = [
        { label: <Link to={`/positions`}>{t('positions')}   </Link> },
        { label: <Link to={`/positions/add`} className='p-2 border-round'>  {t('add_positions')}  </Link> }
    ];

    const home = { icon: 'pi pi-home', url: '/' };

    const formik = useFormik({
        initialValues: {
            PositionTitleEn: '',
            PositionTitleAr: '',
            PositionReferralNumber: '',
            PositionReferralInterval: '',
            PositionLeftNumber: '',
            PositionRightNumber: '',
            PositionAllNumber: '',
            PositionNumberInterval: '',
            PositionLeftPoints: '',
            PositionRightPoints: '',
            PositionAllPoints: '',
            PositionPointInterval: '',
            PositionVisits: '',
            PositionVisitInterval: '',
            PositionChequeValue: '',
            PositionChequeInterval: '',

        },
        onSubmit: async (values, { resetForm }) => {
            const minutesInADay = 24 * 60;

            try {
                setLoading(true);
                let { data } = await axios.post(url, {
                    PositionTitleEn: values?.PositionTitleEn,
                    PositionTitleAr: values?.PositionTitleAr,
                    PositionReferralNumber: values?.PositionReferralNumber,
                    PositionReferralInterval: values?.PositionReferralInterval ? values?.PositionReferralInterval * minutesInADay : '',
                    PositionLeftNumber: values?.PositionLeftNumber,
                    PositionRightNumber: values?.PositionRightNumber,
                    PositionAllNumber: values?.PositionAllNumber,
                    PositionNumberInterval: values?.PositionNumberInterval ? values?.PositionNumberInterval * minutesInADay : 0,
                    PositionLeftPoints: values?.PositionLeftPoints,
                    PositionRightPoints: values?.PositionRightPoints,
                    PositionAllPoints: values?.PositionAllPoints,
                    PositionPointInterval: values?.PositionPointInterval ? values?.PositionPointInterval * minutesInADay : 0,
                    PositionVisits: values?.PositionVisits,
                    PositionVisitInterval: values?.PositionVisitInterval ? values?.PositionVisitInterval * minutesInADay : 0,
                    PositionChequeValue: values?.PositionChequeValue,
                    PositionChequeInterval: values?.PositionChequeInterval ? values?.PositionChequeInterval * minutesInADay : 0,
                },
                    {
                        headers: {
                            // 'Content-Type': 'application/json',
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                        },
                    })

                if (data?.Success) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoading(false);

                        if (Specialvisit === true) {
                            navigate(`/positions/add/brands/${data?.Response}`)
                        } else {
                            navigate(`/positions`)
                        }
                        resetForm();
                    }, 1000);
                } else if (!data?.Success) {
                    toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                    setLoading(false);
                } else if (data?.status === 400) {
                    toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

                    setLoading(false);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
                }

            } catch ({ response }) {
                console.error(response.data.message);
                toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
                setLoading(false);
            }
        }
    });

    useEffect(() => {

        fetchSubCategoriesAjex()
        fetchBrandsAjex()

        return () => {
            fetchSubCategoriesAjex()
            fetchBrandsAjex()

        }
    }, [isLang])

 


    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <BreadCrumb model={items} home={home} />
            <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
                {/* //TODO title ar - en   */}
                <div className="grid ">
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
                        <label htmlFor="PositionTitleEn" className="  block mb-2">{isLang === "en" ? 'Name in English' : 'العنوان باللغة الإنجليزية '}</label>
                        <InputText
                            name='PositionTitleEn'
                            id="PositionTitleEn"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
                        <label htmlFor="PositionTitleAr" className="  block mb-2">{isLang === "en" ? ' Name in arabic' : 'العنوان باللغة العربية '}</label>
                        <InputText
                            name='PositionTitleAr'
                            id="PositionTitleAr"
                            type='text'
                            className="w-full  p-inputtext-sm"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            dir='ltr'
                        />
                    </div>
                </div>
                {/* //TODO Referral Number   */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => {
                                setSelectReferral(e.checked)

                            }} checked={referralNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ReferralNumber')}</label>
                        </div>
                    </div>

                    {
                        referralNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionReferralNumber'
                                    id="PositionReferralNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('ReferralNumber')}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionReferralInterval'
                                    id="PositionReferralInterval"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                />
                            </div>
                        </>
                    }

                </div>

                {/* //TODO Position Visits     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => {
                                setSelectVisit(e.checked)

                            }} checked={visitNum}></Checkbox>
                            <label htmlFor="PositionVisits" className="ml-2 font-bold ">{t('visits')}</label>
                        </div>
                    </div>

                    {
                        visitNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionVisits'
                                    id="PositionVisits"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('visits')}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionVisitInterval'
                                    id="PositionVisitInterval"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}

                                />
                            </div>
                        </>

                    }

                </div>
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => setSpecialVisit(e.checked)} checked={Specialvisit}></Checkbox>
                            <label htmlFor="Specialvisit" className="ml-2 font-bold ">{isLang === "en" ? 'Unique visits  ' : 'زيارات فريدة'}</label>
                        </div>
                    </div>
                </div>
                {/* //TODO cheque Value     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex align-items-center gap-2">
                            <Checkbox onChange={e => {
                                setSelectChequeValue(e.checked)
                            }} checked={chequeValueNum}></Checkbox>
                            <label htmlFor="selectVisitNum1" className="ml-2 font-bold ">{t('ChequeValue')}</label>
                        </div>
                    </div>

                    {
                        chequeValueNum &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionChequeValue'
                                    id="PositionChequeValue"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('ChequeValue')}

                                />
                            </div>
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                <InputText
                                    name='PositionChequeInterval'
                                    id="PositionChequeInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}

                                />
                            </div>
                        </>
                    }

                </div>
                {/* //TODO Balance Number     */}
                <div className="grid flex align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex flex-row gap-5">
                            <h4>{isLang === "en" ? 'Balance number : ' : 'Balance number : '}</h4>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts1" name="BonanzaLeftPoints" value={1} onChange={(e) => {setGifts(e.value)}} checked={gifts === 1} />
                                <label htmlFor="gifts1" className="ml-2">{t('balancePerson')}</label>
                            </div>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="gifts2" name="BonanzaRightPoints" value={0}
                                    onChange={(e) => { setGifts(e.value) }} checked={gifts === 0} />
                                <label htmlFor="gifts2" className="ml-2">{t('TotalNumber')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        gifts === 1 &&
                        <>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                {/* <label htmlFor="PositionLeftNumber" className="  block  ">{t('ClientLeftNumber')}</label> */}
                                <InputText
                                    name='PositionLeftNumber'
                                    id="PositionLeftNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientLeftNumber')}
                                />
                            </div>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                {/* <label htmlFor="PositionRightNumber" className="  block  ">{t('ClientRightNumber')}</label> */}
                                <InputText
                                    placeholder={t('ClientRightNumber')}
                                    name='PositionRightNumber'
                                    id="PositionRightNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-4 md:col-12 sm:col-12  mt-2 input__Col ">
                                <InputText
                                    name='PositionNumberInterval'
                                    id="PositionNumberInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}

                                />
                            </div>
                        </>
                    }
                    {gifts === 0 &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12  input__Col ">
                                {/* {<label htmlFor="BonanzaChequeValue" className="  block mb-2 ">{t('TotalNumber')}</label>} */}
                                <InputText
                                    name='PositionAllNumber'
                                    id="PositionAllNumber"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 0 && true}
                                    placeholder={t('TotalNumber')}
                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                {/* {<label htmlFor="BonanzaChequeValue" className="  block mb-2 ">{t('days')}</label>} */}
                                <InputText
                                    name='PositionNumberInterval'
                                    id="PositionNumberInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}
                                />
                            </div>
                        </>
                    }
                </div>
                {/* //TODO Balance Points   */}
                <div className="grid flex   align-items-center">
                    <div className="lg:col-12 md:col-12 sm:col-12 mt-4 input__Col ">
                        <div className="flex flex-row gap-5">
                            <h4>{isLang === "en" ? 'Balance points : ' : 'نقاط الرصيد:'}</h4>
                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="giftsPoint1" name="BonanzaLeftPoints" value={1} onChange={(e) => {
                                    setGiftsPoint(e.value)
                                }} checked={giftsPoint === 1} />
                                <label htmlFor="giftsPoint1" className="ml-2">{t('balancePoints')}</label>
                            </div>


                            <div className="flex align-items-center gap-2">
                                <RadioButton inputId="giftsPoint2" name="BonanzaRightPoints" value={0}
                                    onChange={(e) => {
                                        setGiftsPoint(e.value)
                                    }} checked={giftsPoint === 0} />
                                <label htmlFor="giftsPoint2" className="ml-2">{t('ClientTotalPoints')}</label>
                            </div>
                        </div>
                    </div>
                    {
                        giftsPoint === 1 &&
                        <>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                <InputText
                                    name='PositionLeftPoints'
                                    id="PositionLeftPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientLeftPoints')}
                                />
                            </div>
                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col ">
                                <InputText
                                    name='PositionRightPoints'
                                    id="PositionRightPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 1 && true}
                                    placeholder={t('ClientRightPoints')}

                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}

                            <div className="lg:col-4 md:col-12 sm:col-12 mt-2  input__Col ">
                                <InputText
                                    name='PositionPointInterval'
                                    id="PositionPointInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}

                                />
                            </div>
                        </>
                    }


                    {giftsPoint === 0 &&
                        <>
                            <div className="lg:col-6 md:col-12 sm:col-12  input__Col ">
                                {/* {<label htmlFor="PositionAllPoints" className="  block mb-2 ">{t('ClientTotalPoints')}</label>} */}
                                <InputText
                                    name='PositionAllPoints'
                                    id="PositionAllPoints"
                                    type='number'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    required={gifts === 0 && true}
                                    placeholder={t('ClientTotalPoints')}
                                />
                            </div>
                            {/* //TODO numberInterval Position Left Number */}
                            <div className="lg:col-6 md:col-12 sm:col-12   input__Col ">
                                {/* {<label htmlFor="PositionPointInterval" className="  block mb-2 ">{t('days')}</label>} */}
                                <InputText
                                    name='PositionPointInterval'
                                    id="PositionPointInterval"
                                    type='text'
                                    className="w-full  p-inputtext-sm"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder={t('days')}

                                />
                            </div>
                        </>

                    }




                </div>
                <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
                    <div className="btn_submit">
                        <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
                    </div>
                    <Link to={`/positions`}>
                        <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
                    </Link>
                </div>
            </form>
        </div >
    )
}

export default AddPosition
