
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';


const UpgradeData = () => {
    let { role } = useContext(FetchApi)
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    // Add new upgrades
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/upgrades/add">
                    <Button label={t('add')} severity="warning" icon="pi pi-plus" size='small' />
                </Link>

            </div>
        );
    };
    // logcation path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                {
                    role?.EDIT_UPGRADES == 1 &&
                    <Link to={`/upgrades/edit/${rowData.IDPlanProductUpgrade}`}>
                        <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
                    </Link>
                }
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDPlanProductUpgrade}/UPGRADE `}>
                        <Button icon="pi pi-file" rounded outlined text raised severity="secondary" />
                    </Link>
                }

            </div>
        );
    };
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">  </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/plans/products/upgrades`;

        let data = await axios.post(url, {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);

    }
    useEffect(() => {
        fetchData();
    }, [isLang])


    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0 },
    ];
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/plans/products/upgrades/status/${rowData?.IDPlanProductUpgrade}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.UpgradeActive === 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.UpgradeActive !== 1 ? t('inactive') : t('active');

        return editingRow === rowData?.IDPlanProductUpgrade ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.UpgradeActive)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.UpgradeActive)} 
                onClick={() => {
                    if (role?.EDIT_UPGRADES == 1) {
                      setEditingRow(rowData?.IDPlanProductUpgrade)
                    }
                  }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    {
                        (role?.EDIT_UPGRADES == 1) &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };



    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case '':
                return 'info';
        }
    };

    return (
        <div  >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_BANNERS == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }


                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="UpgradeName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="UpgradePrice" header={t('price')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="UpgradeAgencyNumber" header={t('UpgradeAgencyNumber')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="UpgradeActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {
                        (role?.EDIT_UPGRADES == 1 || localStorage.getItem('Role') == 1) &&
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    }
                </DataTable>

            </div>

        </div>
    )
}

export default UpgradeData