import { useTranslation } from "react-i18next";
import { Row, Col, Card, Statistic } from 'antd';
import { UserOutlined, PhoneOutlined } from '@ant-design/icons';

const SummaryClient = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]} className="p-2">
      {data?.SalesName && (
        <Col lg={6} md={12} sm={24} xs={24} className="mt-2">
          <Card
            className="summary-card"
            bordered={false}
            style={{
              borderRadius: '10px',
              backgroundColor: 'rgba(24, 144, 255, 0.1)', // لون شفاف للأزرق
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '0px',
            }}
          >
            <div className="flex align-items-center gap-4">
              <UserOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
              <Statistic
                title={t('tabel_brand_header_4')}
                value={data?.SalesName}
                valueStyle={{ fontSize: '18px', fontWeight: 'bold' }}
              />
            </div>
          </Card>
        </Col>
      )}

      {data?.SalesPhone && (
        <Col lg={6} md={12} sm={24} xs={24} className="mt-2  ">
          <Card
            className="summary-card"
            bordered={false}
            classNames={'w-full'}
            style={{
              borderRadius: '10px',
              backgroundColor: 'rgba(82, 196, 26, 0.1)', // لون شفاف للأخضر
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '0px',
            }}
          >
            <div className="flex align-items-center gap-4">
              <PhoneOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
              <Statistic
                title={t('tabel_brand_header_5')}
                value={data?.SalesPhone}
                valueStyle={{ fontSize: '18px', fontWeight: 'bold' }}
              />
            </div>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default SummaryClient;
