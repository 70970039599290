import AddGallery from 'Page/Upload/AddGallery';
import AddSingleImage from 'Page/Upload/AddSingleImage';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const AddBrand = () => {
  let { multiImage,setMultiImage, singleImage,setSingleImage, isLang } = useContext(LocalizationContext);
  let { SalesAjex, fetchSalesAjex } = useContext(FetchApi)

  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/brands'}>{t('brand_name')}   </Link> },
    { label: <Link to={'/brands/add'} className='p-2 border-round'>  {t('brand_add')}  </Link> }
  ];


  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      BrandNameEn: '',
      BrandNameAr: '',
      BrandNumber: '',
      BrandEmail: '',
      IDUser: '',
      BrandPolicyEn: '',
      BrandPolicyAr: '',
      BrandDescEn: '',
      BrandDescAr: '',
      BrandLogo: singleImage,
      BrandDocuments: multiImage,

    },
    onSubmit: async (values, { resetForm }) => {



      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          setMultiImage([])
          setSingleImage(null)
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/brands')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
  // Handle multi-image select
  useEffect(() => {
    fetchSalesAjex()
  }, []);

  useEffect(() => {
    formik.setFieldValue('BrandDocuments', multiImage);
  }, [multiImage]); // يتم استدعاء useEffect عندما تتغير multiImage


  useEffect(() => {
    formik.setFieldValue('BrandLogo', singleImage);
  }, [singleImage]); // يتم استدعاء useEffect عندما تتغير multiImage

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <label htmlFor="BrandNameEn" className="font-bold block mb-2"> Brand Logo </label>
        <AddSingleImage />
        <label htmlFor="BrandNameEn" className="font-bold block mb-2"> Brand Document  </label>
        <AddGallery />

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNameEn" className="font-bold block mb-2"> {t('label_brand_name_en')} </label>
            <InputText
              name='BrandNameEn'
              id="BrandNameEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}


            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandNameAr" className="font-bold block mb-2"> {t('label_brand_name_ar')}</label>
            <InputText
              name='BrandNameAr'
              id="BrandNameAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
          </div>
        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNumber" className="font-bold block mb-2"> {t('label_brand_num')}  </label>
            <InputText
              name='BrandNumber'
              id="BrandNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandEmail" className="font-bold block mb-2"> {t('email')}   </label>
            <InputText
              name='BrandEmail'
              id="BrandEmail"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandPolicyEn" className="font-bold block mb-2">{t('Policy_en')}</label>
            <InputText
              name='BrandPolicyEn'
              id="BrandPolicyEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandPolicyAr" className="font-bold block mb-2">{t('Policy_ar')}</label>
            <InputText
              name='BrandPolicyAr'
              id="BrandPolicyAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='BrandDescEn'
              id="BrandDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              name='BrandDescAr'
              id="BrandDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="grid  mt-5  ">

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="IDUser" className="font-bold block mb-2">{t('tabel_brand_header_4')}  </label>
            <Dropdown filter
              options={SalesAjex?.map(item => ({
                name: item.UserName,
                value: item.IDUser,
              }))}
              id="IDUser"
              name="IDUser"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDUser} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDUser", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('user_placeholder')}
              className="w-full p-inputtext-sm"

            />
          </div>
        </div>

        <div className="btn_gapAr  group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="warning" loading={loading} label={t('submit')} icon='pi pi-check' type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/brands'}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default AddBrand