import loginBg from "assets/Images/logo.png";
import logo from "assets/Images/logo.png";
import logoSm from "assets/Images/Logo copy.png";
import DefaultImage from "assets/Images/default-image.jpg";

const img = {
    loginBg,
    logo, 
    DefaultImage,
    logoSm
}

export default img;
