import { Footer } from 'antd/es/layout/layout'
import React from 'react'
import '../style.scss'


const Footers = () => {
    return (
        <Footer
            style={{
                textAlign: 'center',
                background: '#fff',  // Light background for footer
                color: 'black',  // Text color for visibility
            }}
        >
            BetterWay  ©{new Date().getFullYear()} Created by <a style={{ color: '#F1592A' 
                
            }} href="https://vigtas.com/" target="_blank" rel="noopener noreferrer">Vigtas</a>
        </Footer>
    )
}

export default Footers
