
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Bonanza = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    let { role } = useContext(FetchApi)
    const inputRef = useRef(null);

    // Add new bonanza
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/bonanza/add">
                    <Button severity="warning" label={t('add_bonanza')} icon="pi pi-plus" size='small' />
                </Link>
            <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

            </div>
        );
    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />

                <Link to={`/bonanza/brands/${rowData.IDBonanza}`}>
                    <Button className="disabled-button" icon="pi pi-eye " rounded outlined text raised severity='warning' tooltip={t('brand_bonanza')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                {
                    role?.EDIT_BONANZA == 1 &&
                    <Link Link to={`/bonanza/edit/${rowData.IDBonanza}`}>
                        <Button icon="pi pi-pencil" rounded text raised className="mr-2" tooltip={t('update_bonanza')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }

                <Link to={`/bonanza/clients/${rowData.IDBonanza}`}>
                    <Button icon="pi pi-users" rounded text raised className="mr-2" tooltip={t('Clients_bonanza')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDBonanza}/BONANZA`}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }
            </div >
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />            </span>
        </div>
    );
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);

    const fetchData = async (SearchKey) => {

        const url = `${process.env.REACT_APP_API_URL}/bonanza`;
        let data = await axios.post(url, { IDPage: page + 1 ,SearchKey},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        console.log(data?.data?.Response?.Bonanzas);
        setData(data?.data?.Response?.Bonanzas);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])
    const resetData = () => {
        fetchData();
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال
    }
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/bonanza/status`, {
            IDBonanza: rowData?.IDBonanza,
            BonanzaStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BonanzaStatus === "ACTIVE" ? t('active') : rowData?.BonanzaStatus === "INACTIVE" ? t('inactive') : rowData?.BonanzaStatus === "ONGOING" ? t('ONGOING') : rowData?.BonanzaStatus === "CANCELLED" ? t('CANCELLED') : t('pending');


        return editingRow === rowData?.IDBonanza ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BonanzaStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.BonanzaStatus === "ACTIVE" ? t('active') : rowData?.BonanzaStatus === "INACTIVE" ? t('inactive') : rowData?.BonanzaStatus === "ONGOING" ? t('ONGOING') : rowData?.BonanzaStatus === "CANCELLED" ? t('CANCELLED') : t('pending')}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.BonanzaStatus)}
                onClick={() => {
                    if (role?.EDIT_BONANZA == 1) {
                        setEditingRow(rowData?.IDBonanza)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>
                        {`${rowData?.BonanzaStatus === "ACTIVE" ? t('active') : rowData?.BonanzaStatus === "INACTIVE" ? t('inactive') : rowData?.BonanzaStatus === "EXPIRED" ? t('expired') : rowData?.BonanzaStatus === "ONGOING" ? t('ONGOING') : rowData?.BonanzaStatus === "CANCELLED" ? t('CANCELLED') : rowData?.BonanzaStatus === "PENDING" ? t('pending') : ''}`}
                    </span>
                    {
                        role?.EDIT_BONANZA == 1 &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };
    //  ,ONGOING,,CANCELLED, 


    const getSeverity = (status) => {
        switch (status) {
            case 'ACTIVE':
                return 'success';

            case 'INACTIVE':
                return 'danger';

            case 'EXPIRED':
                return 'danger';

            case 'PENDING':
                return 'warning';
        }
    };
    const startDate = (rowData) => {
        const formattedDate = format(new Date(rowData?.BonanzaStartTime), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };

    const enddate = (rowData) => {
        const formattedDate = format(new Date(rowData?.BonanzaEndTime), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const CreateDate = (rowData) => {
        const formattedDate = format(new Date(rowData?.CreateDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_BONANZA == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rows={10}
                    header={header}>
                    <Column field="BonanzaTitle" header={t('title')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaLeftPoints" header={t('ClientLeftPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaRightPoints" header={t('ClientRightPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaTotalPoints" header={t('ClientTotalPoints')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaVisitNumber" header={t('visits')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaReferralNumber" header={t('ReferralNumber')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaRewardPoints" header={t('ClientRewardPoints')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaChequeValue" header={t('ChequeValue')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaProductValue" header={isLang === "en" ? 'Product Value' : 'قيمة منتج  '} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaStartTime" header={t('tabel_header_contract_1')} body={startDate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaEndTime" header={t('tabel_header_contract_2')} body={enddate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="CreateDate" header={t('CreateDate')} body={CreateDate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BonanzaStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>


                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

        </div>
    )
}

export default Bonanza