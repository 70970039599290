import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const AddTools = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/tools/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const [openImanes, setOpenImanes] = useState(false);

  const [selectMultiImageFile, setSelectMultiImageFile] = useState([]);
  const [openImanesFile, setOpenImanesFile] = useState(false);
  const toolsTyps = [
    { name: 'Pdf', value: 'PDF' },
    { name: 'Images', value: 'IMAGE' },
    { name: 'Video', value: 'VIDEO' },
  ];

  //!hide images modal 
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  const hideImagesProjectsDialogFile = (e) => setOpenImanesFile(false)

  const items = [
    { label: <Link to={`/tools`}>{t('tools_name')}   </Link> },
    { label: <Link to={`/tools/add`} className='p-2 border-round'>  {t('add_tools')}  </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  //!hide vido= 
  const [openToolVideos, setOpenToolVideos] = useState(false);
  const [ToolVideos, setToolVideos] = useState([]);

  //!hide ToolVideos modal 
  const hideToolVideosProjectsDialog = (e) => setOpenToolVideos(false)

  // Handle scope change
  const handleScopeChange = (index, value) => {
    const updatedToolVideos = [...formik.values.ToolVideos];
    updatedToolVideos[index] = value;
    formik.setFieldValue('ToolVideos', updatedToolVideos);
  };

  // Handle adding a new scope
  const handleAddScope = () => {
    const updatedToolVideos = [...formik.values.ToolVideos, ''];
    console.log(updatedToolVideos);
    formik.setFieldValue('ToolVideos', updatedToolVideos);
    setToolVideos(updatedToolVideos);
  };

  const formik = useFormik({
    initialValues: {
      ToolTitleEn: '',
      ToolTitleAr: '',
      ToolDescEn: '',
      ToolDescAr: '',
      ToolPoints: '',
      ToolPrice: '',
      ToolType: '',
      ToolGallery: selectMultiImage,
      ToolVideos: ToolVideos,
      ToolFiles: selectMultiImageFile
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/tools`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }
      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  // Handle multi-image select
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const handleMultiImageSelectFiles = (event) => setSelectMultiImageFile([...selectMultiImageFile, event.target.files[0]])

  const handleDeleteImageFile = (index) => {
    const updatedImages = [...selectMultiImageFile];
    updatedImages.splice(index, 1);
    setSelectMultiImageFile(updatedImages);
  };
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolTitleEn" className="font-bold block mb-2">{t('title_en')}</label>
            <InputText
              name='ToolTitleEn'
              id="ToolTitleEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="ToolTitleAr" className="font-bold block mb-2">{t('title_ar')}</label>
            <InputText
              name='ToolTitleAr'
              id="ToolTitleAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='ToolDescEn'
              id="ToolDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col" >
            <label htmlFor="ToolDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              name='ToolDescAr'
              id="ToolDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              name='ToolPrice'
              id="ToolPrice"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolPoints" className="font-bold block mb-2">{t('ClientRewardPoints')}</label>
            <InputText
              name='ToolPoints'
              id="ToolPoints"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 mt-2 sm:col-12 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">{t('gallery')} </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
              <Button label={isLang === "en" ? "Tool Videos  " : 'فيديوهات الأداة'} size='small' type='button' severity="" text raised icon="pi pi-exclamation-circle" onClick={() => setOpenToolVideos(!openImanes)} />
            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ToolType" className="font-bold block mb-2">Tool Type</label>

            <div className="flex  justify-content-  align-items-center gap-2">
              <InputText
                name='ToolType'
                id="ToolType"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Button className="w-5 " label={isLang === "en" ? " Upload File" : 'رفع ملف'} size='small' type='button' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanesFile(!openImanesFile)} />
            </div>
          </div>
        </div>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/tools`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>


        <Dialog
          visible={openImanes}
          style={{ width: '50rem' }}
          modal
          onHide={hideImagesProjectsDialog}
          header={isLang === "en" ? " Event Gallery" : "معرض الأحداث"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
        >

          {/*Add DataTable to display data} */}
          <div className="mt-3 mb-3 flex justify-content-center">
            <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
              <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
              <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
            </label>
          </div>

          <input
            type='file'
            id='fileInput'
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(event) => {
              formik.setFieldValue('ToolGallery', [...selectMultiImage, event.target.files[0]]);
              handleMultiImageSelect(event);
            }}
          />

          {/*  Display selected images */}
          <div className="grid  mt-2   ">
            {selectMultiImage.map((image, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col relative  ">
                <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(image)}
                    alt={'brnad iamge'}
                    width='300px'
                    height='100%'
                    className=' mx-auto overflow-hidden '
                    style={{ objectFit: 'cover' }}
                  />
                  {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-14rem w-100  border-round-xl' /> */}
                  <Button
                    size="small"
                    type="button"
                    severity='danger'
                    raised
                    className='btn__porfile bg_delete  absolute '
                    rounded
                    icon="pi pi-trash"
                    style={{ right: '15px' }}
                    onClick={() => handleDeleteImage(index)}
                  />
                </div>
              </div>
            ))}
          </div>



          <button type='button' style={{ display: 'none' }} onClick={() => document.getElementById('fileInput').click()}>
            Choose Images
          </button>
        </Dialog>

        {/*****Model Add Multi ToolVideos */}
        <Dialog visible={openToolVideos}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand Videos" : 'فيديوهات العلامة التجارية   '}
          modal
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          onHide={hideToolVideosProjectsDialog}>

          {/* ToolVideos */}
          {ToolVideos.map((scope, index) => (
            <div className="grid" key={index}>
              <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col ">
                <label htmlFor="ToolVideos" className="font-bold block mb-2">  {isLang === "en" ? "  Youtube Link" : 'رابط يوتيوب'}    </label>
                <InputText
                  name='ToolVideos'
                  id="ToolVideos"
                  type="text"
                  className="w-full"
                  defaultValue={scope.ar}
                  onChange={(event) => handleScopeChange(index, event.target.value)}
                />

              </div>
            </div>


          ))}
          <div className="flex flex-row gap-2 justify-content-center align-items-center">
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Add youtube" : 'أضف يوتيوب'} size='small' icon='pi pi-plus-circle' type='button' onClick={handleAddScope} />
            </div>
            <div className="flex btn_gapAr justify-content-center align-items-center mt-3">
              <Button label={isLang === "en" ? "Save" : '   حفظ   '} outlined size='small' icon='pi pi-check' type='button' onClick={hideToolVideosProjectsDialog} />
            </div>

          </div>

        </Dialog>


        <Dialog
          visible={openImanesFile}
          style={{ width: '50rem' }}
          modal
          onHide={hideImagesProjectsDialogFile}
          header={isLang === "en" ? "Tool Files" : "معرض الأحداث"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
        >

          {/*Add DataTable to display data} */}
          <div className="mt-3 mb-3 flex justify-content-center">
            <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
              <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInputFile').click()} />
              <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanesFile(false)} />
            </label>
          </div>

          <input
            type='file'
            id='fileInputFile'
            accept=".pdf, image/*"
            style={{ display: 'none' }}
            onChange={(event) => {
              formik.setFieldValue('ToolFiles', [...selectMultiImageFile, event.target.files[0]]);
              handleMultiImageSelectFiles(event);
            }}
          />




          <div className="grid  mt-2   ">
            {selectMultiImageFile?.map((file, index) => (
              <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col relative  ">
                <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                  {file.type.startsWith('image/') ? (
                    <>
                      <Image
                        preview
                        loading="lazy"
                        src={URL.createObjectURL(file)}
                        alt={'brnad iamge'}
                        width='300px'
                        height='100%'
                        className=' mx-auto overflow-hidden '
                        style={{ objectFit: 'cover' }}
                      />
                       <Button
                        size="small"
                        type="button"
                        severity='danger'
                        raised
                        className='btn__porfile bg_delete  absolute '
                        rounded
                        icon="pi pi-trash"
                        style={{ right: '15px' }}
                        onClick={() => handleDeleteImageFile(index)}
                      />
                    </>

                  ) : (
                    // Render PDF using an iframe if it's a PDF file
                    <>
                      <iframe
                        title={`PDF ${index}`}
                        src={URL.createObjectURL(file)}
                        style={{ objectFit: 'contain' }} className='w-full h-15rem border-round-xl'
                      />
                      <Button
                        size="small"
                        type="button"
                        severity='danger'
                        raised
                        className='btn__porfile bg_delete  absolute '
                        rounded
                        icon="pi pi-trash"
                        style={{ right: '15px' }}
                        onClick={() => handleDeleteImageFile(index)}
                      />
                    </>


                  )}
                </div>
              </div>
            ))}
          </div>


          <button type='button' style={{ display: 'none' }} onClick={() => document.getElementById('fileInputFile').click()}>
            Choose Images
          </button>
        </Dialog>


      </form>
    </div>
  )
}



export default AddTools
