import AddGallery from 'Page/Upload/AddGallery';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';


const AddBrandContract = () => {
  const { id } = useParams()
  let { multiImage, setMultiImage, singleImage, setSingleImage, isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/contracts/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={`/brands`}>{t('brand_name')}   </Link> },
    { label: <Link to={`/brands/contract/${id}`}>{t('brand_contract')}   </Link> },
    { label: <Link to={`/brands/contract/${id}/add`} className='p-2 border-round'>  {t('add_brand_contract')}  </Link> }
  ]; 
  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      IDBrand: id,
      BrandContractAmount: '',
      BrandContractDocuments: multiImage,
      BrandContractMonths: ''
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setMultiImage([])
          setTimeout(() => {
            setLoading(false);
            navigate(`/brands/contract/${id}`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });
 
  useEffect(() => {
    formik.setFieldValue('BrandContractDocuments', multiImage);
  }, [multiImage]); // يتم استدعاء useEffect عندما تتغير multiImage

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
          <label htmlFor="link" className="font-bold block mb-2">      {isLang === "en" ? 'Contract Documents' : 'وثائق العقد'} </label>
          <AddGallery />
        </div>
        <div className="grid  mt-5  ">
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandContractStartDate" className="font-bold block mb-2">{isLang === "en" ? '  Start Date' : 'تاريخ بدء عقد    '}</label>
            <Calendar
              id="BrandContractStartDate"
              name="BrandContractStartDate"
              value={formik.values.BrandContractStartDate}
              placeholder={isLang === "en" ? ' start date' : '  تاريخ بدا   '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandContractStartDate  ", selectedDate);
              }}
              showIcon
              className='w-full'

            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandContractEndDate" className="font-bold block mb-2">{isLang === "en" ? '  End Date' : '    تاريخ نهاية  عقد    '}</label>
            <Calendar
              id="BrandContractEndDate"
              name="BrandContractEndDate"
              value={formik.values.BrandContractEndDate}
              placeholder={isLang === "en" ? ' end date' : '  تاريخ نهاية   '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandContractEndDate  ", selectedDate);
              }}
              showIcon
              className='w-full'

            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandContractAmount" className="font-bold block mb-2">{isLang === "en" ? ' Amount' : 'قيمة العقد    '}</label>
            <InputText
              name='BrandContractAmount'
              id="BrandContractAmount"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="BrandContractMonths" className="font-bold block mb-2">{isLang === "en" ? '  Number of months contract' : 'أشهر عقد'}</label>
            <InputText
              name='BrandContractMonths'
              id="BrandContractMonths"
              type='number'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>


        </div>



        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/brands/contract/${id}`}>
            <Button label={t('cancel')} type="reset" severity="warning" outlined size='small' className='mt-3' />
          </Link>
        </div>


      </form>
    </div>
  )
}


export default AddBrandContract
