import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { parseISO } from 'date-fns/esm';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const UpdateBrandContract = () => {
  const { id, idEdit } = useParams()

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/contracts/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={`/brands`}>{t('brand_name')}   </Link> },
    { label: <Link to={`/brands/contract/${id}`}>{t('brand_contract')}</Link> },
    { label: <Link to={`/brands/contract/${id}/edit/${idEdit}`} className='p-2 border-round'>{t('edit_brand_contract')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/contracts/edit/page/${idEdit}`)
    if (data?.Success === true) {
      setData(data?.Response);
      fetchDocuments()
    }
  }

  const fetchDocuments = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/contracts/documents/${idEdit}`)
    if (data?.Success === true) {
      setDocuments(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandContractStartDate: Data?.BrandContractStartDate || '',
      BrandContractEndDate: Data?.BrandContractEndDate || '',
      BrandContractAmount: Data?.BrandContractAmount || '',
      BrandContractMonths: Data?.BrandContractMonths || '',
      IDBrandContract: idEdit,
      BrandContractDocuments: selectMultiImage,


    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/brands/contract/${id}`)
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/contracts/document/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {
        
        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchDocuments()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  }; 


  useEffect(() => {
    formik.setValues({
      BrandContractStartDate: Data?.BrandContractStartDate || '',
      BrandContractEndDate: Data?.BrandContractEndDate || '',
      BrandContractAmount: Data?.BrandContractAmount || '',
      BrandContractMonths: Data?.BrandContractMonths || '',
      IDBrandContract: idEdit,
    });

  }, [Data, formik.setValues]);

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="mb-3">
        <BreadCrumb model={items} home={home} />

      </div>
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-2 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandContractStartDate" className="font-bold block mb-2">{isLang === "en" ? 'Brand contract start Date' : 'تاريخ بدء عقد العلامة التجارية'}</label>
            <Calendar
              id="BrandContractStartDate"
              name="BrandContractStartDate"
              value={formik?.values?.BrandContractStartDate ? parseISO(formik?.values?.BrandContractStartDate) : null}
              placeholder={isLang === "en" ? ' start date' : ' تاريخ بدأ '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandContractStartDate", selectedDate);
                console.log(format(new Date(e.value), 'dd-MM-yyyy'));
              }}
              showIcon
              className='w-full'
              dateFormat="dd-mm-yy"
            />
          </div>

          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="BrandContractEndDate" className="font-bold block mb-2">{isLang === "en" ? 'Brand contract end Date' : 'تاريخ نهاية عقد العلامة التجارية'}</label>
            <Calendar
              id="BrandContractEndDate"
              name="BrandContractEndDate"
              value={formik?.values?.BrandContractEndDate ? parseISO(formik?.values?.BrandContractEndDate) : null}
              placeholder={isLang === "en" ? ' start date' : ' تاريخ بدأ '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("BrandContractEndDate", selectedDate);
                console.log(format(new Date(e.value), 'dd-MM-yyyy'));
              }}
              showIcon
              className='w-full'

              dateFormat="dd-mm-yy"
            />

          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandContractAmount" className="font-bold block mb-2">{isLang === "en" ? 'Brand contract amount' : 'قيمة عقد العلامة التجارية'}</label>
            <InputText
              name='BrandContractAmount'
              id="BrandContractAmount"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandContractAmount}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandContractMonths" className="font-bold block mb-2">{isLang === "en" ? '  Number of months contract' : 'أشهر عقد'}</label>
            <InputText
              name='BrandContractMonths'
              id="BrandContractMonths"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandContractMonths}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2">      {isLang === "en" ? 'Contract documents' : 'وثائق العقد'} </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} type='button' severity="" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div>
        </div>



        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Contract documents " : 'مستندات العقد'}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('BrandContractDocuments', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                    <Image
                      preview
                      loading="lazy"
                      src={item.BrandContractDocumentPath}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />

                    <Button icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDBrandContractDocument)} />

                  </div>
                </div>
              ))}
            </div>
          </div>


        </Dialog>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/brands/contract/${id}`}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>



      </form>


    </div>
  )
}

export default UpdateBrandContract
