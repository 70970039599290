import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Tag } from 'primereact/tag'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog'
import { Image } from 'primereact/image'

const EventDetails = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    let { id } = useParams()
    const [Data, setData] = useState(null);
    const [documents, setDocuments] = useState(null);
    const toast = useRef(null);
    const fetchData = async () => {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/events/details`, { IDEvent: id },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success === true) {
            setData(data?.Response?.Event);
            setDocuments(data?.Response?.Event?.EventGallery);
        }
    }
    useEffect(() => {
        fetchData();
        window.scroll(0, 0)
    }, [isLang, id])
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const getSeverity = (status) => {
        switch (status) {
            case 'ACCEPTED':
                return 'success';

            case 'ENDED':
                return 'danger';

            case 'PENDING':
                return 'warning';

            case 'CANCELLED':
                return 'danger';

            case 'ONGOING':
                return 'warning';


        }
    };

    const getSeverity2 = (status) => {
        switch (status) {
            case 1:
                return 'success';

            case 0:
                return 'danger';
        }
    };
    const items = [
        { label: <Link to={`/events`} className='text-bluegray-400'> {t('events_name')}</Link> },
        { label: <Link to={`/events/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('details_events')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">{isLang === "en" ? 'Event Attendees' : 'الحاضرين للحدث'}</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>
        </div>
    );

    const [editingRow, setEditingRow] = useState(null);


    const statusBodyTemplate = (rowData) => {
        return editingRow === rowData?.EventAttendeeStatus ? (
            <>
            </>
        ) : (
            <Tag
                rounded
                value={`${rowData?.EventAttendeeStatus === "PAID" ? t('PAID') : rowData?.EventAttendeeStatus === "PENDING" ? t('pending') : rowData?.EventAttendeeStatus === "REMOVED" ? t('REMOVED') : t('CANCELLED')}`}
                severity={getSeverity3(rowData.EventAttendeeStatus)}
            />
        );
    };
    const getSeverity3 = (status) => {
        switch (status) {
            case 'PAID':
                return 'success';
            case 'PENDING':
                return 'warning';
            case 'CANCELLED':
                return 'danger';
            case 'REMOVED':
                return 'danger';
            case '':
                return 'info';
        }
    };
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button icon="pi pi-trash" disabled={rowData?.EventAttendeeStatus === "REMOVED"} rounded outlined severity="danger" onClick={() => confirmDeleteusers(rowData)} />
            </div>
        );
    };
    // delete users
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [IdDeleteusers, setIdDeleteusers] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const confirmDeleteusers = async (users) => {
        console.log(users?.IDEventAttendee);
        setDeleteusersDialog(true);
        setIdDeleteusers(users?.IDEventAttendee)
    };
    const deleteusers = async (rowData) => {
        setdeleteLoadind(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/events/attendee/remove/${IdDeleteusers}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                setTimeout(() => {
                    setDeleteusersDialog(false);
                    fetchData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
        </div>
    );
    return (
        <>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <div className="mb-3 px-8 mt-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="app__PersonalData mt-0 pt-0">

                <div className="info flex flex-row gap-8 mt-35">
                    <Tag
                        rounded
                        value={`${Data?.EventStatus === "ACCEPTED" ? t('ACCEPTED') : Data?.EventStatus === "ENDED" ? t('ENDED') : Data?.EventStatus === "ONGOING" ? t('ONGOING') : Data?.EventStatus === "CANCELLED" ? t('CANCELLED') : Data?.EventStatus === "CANCELLED" ? t('CANCELLED') : t('pending')}`}
                        severity={getSeverity(Data?.EventStatus)}
                    />
                </div>
                <div className="info_contactus mt-35">
                    <div className="grid  mt-5  ">

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-exclamation-circle'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('title_en')}   </div>
                                    <div className="body" dir='ltr'> {Data?.EventTitleEn}  </div>
                                </div>
                            </div>
                        </div>

                        {/*                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-exclamation-circle'></i>

                                </div>
                                <div>
                                    <div className="header"> {t('title_ar')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventTitleAr}   </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-exclamation-circle'></i>

                                </div>
                                <div>
                                    <div className="header"> {t('desc_en')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventDescEn}   </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-exclamation-circle'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('desc_ar')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventDescAr}   </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-money-bill'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('price')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventMaxNumber}   </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-money-bill'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('EventMaxNumber')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventPrice}   </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-clock'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('start_time')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventStartTime}   </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-clock'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('end_time')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventEndTime}   </div>

                                </div>
                            </div>
                        </div>

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-map-marker'></i>
                                </div>
                                <div>
                                    <div className="header flex gap-3  align-items-center"> {t('tabel-header-cities-1')}
                                  {/*       <Tag
                                            rounded
                                            value={`${Data?.CityActive === 1 ? t('active') : t('inactive')}`}
                                            severity={getSeverity2(Data?.CityActive)}
                                        /> */}
                                    </div>
                                    <div className="body" dir='ltr'> {Data?.CityNameEn}   </div>

                                </div>
                            </div>
                        </div>

                        {/*               <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-map-marker'></i>
                                </div>
                                <div>
                                    <div className="header flex gap-3  align-items-center"> {t('tabel-header-cities-1')}
                                        <Tag
                                            value={`${Data?.CityActive === 1 ? t('active') : t('inactive')}`}
                                            severity={getSeverity2(Data?.CityActive)}
                                        />
                                    </div>
                                    <div className="body" dir='ltr'> {Data?.CityNameAr}   </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-map'></i>
                                </div>
                                <div>
                                    <div className="header flex gap-3  align-items-center"> {t('tabel-header-areas-1')}
                             {/*            <Tag
                                            rounded
                                            value={`${Data?.AreaActive === 1 ? t('active') : t('inactive')}`}
                                            severity={getSeverity2(Data?.AreaActive)}
                                        /> */}
                                    </div>
                                    <div className="body" dir='ltr'> {Data?.AreaNameEn}   </div>
                                </div>
                            </div>
                        </div>

                        {/*               <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-map'></i>
                                </div>
                                <div>
                                    <div className="header flex gap-3  align-items-center"> {t('tabel-header-areas-1')}
                                        <Tag
                                            value={`${Data?.AreaActive === 1 ? t('active') : t('inactive')}`}
                                            severity={getSeverity2(Data?.AreaActive)}
                                        />
                                    </div>
                                    <div className="body" dir='ltr'> {Data?.AreaNameAr}   </div>
                                </div>
                            </div>
                        </div> */}



                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-circle'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('ClientRewardPoints')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventPoints}   </div>
                                </div>
                            </div>
                        </div>

                        {/*                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-circle'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('ReferralPoints')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventReferralPoints}   </div>
                                </div>
                            </div>
                        </div> */}

                        {/*                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-circle'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('UplinePoints')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventUplinePoints}   </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                            <div className="conatent ">
                                <div className="icons">
                                    <i className='pi pi-user'></i>
                                </div>
                                <div>
                                    <div className="header"> {t('EventClientNumber')}</div>
                                    <div className="body" dir='ltr'> {Data?.EventClientNumber}   </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <hr />
                </div>
                <div className="info_contactus mt-35">

                    <div className="header_gallery_event"> <span>{t('gallery')}</span>   </div>

                    <div className="grid  mt-2   ">
                        {documents?.map((item, index) => (
                            <div key={index} className="lg:col-4 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                                {
                                    item?.EventGalleryType === "IMAGE" &&
                                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                                        <Image
                                            preview
                                            loading="lazy"
                                            src={item.EventGalleryPath}
                                            alt={'brnad iamge'}
                                            width='300px'
                                            height='100%'
                                            className=' mx-auto overflow-hidden '
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </div>
                                }

                                {
                                    item?.EventGalleryType === "VIDEO" &&
                                    <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                                        <iframe width={400} height={250} className="w-full h-19rem border-round-xl " src={`https://${item.EventGalleryPath}`} title="Ghalia Benali/ YA MSAFER / غالية بنعلي/ يا مسافر وحدك" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <div className="pt-5 pb-5">
                    <hr />
                </div>

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data?.EventAttendees}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="ClientName" header={t('name')} style={{ textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ textAlign: 'center' }}></Column>
                    <Column field="EventAttendeePaidAmount" header={t('price')} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                    <Column field="EventAttendeeStatus" header={t('status')} body={statusBodyTemplate} style={{ maxWidth: 'fitContent', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>

                <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                    <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        <span>
                            {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                        </span>
                    </div>
                </Dialog>
            </div>
        </>
    )
}

export default EventDetails
