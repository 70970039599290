import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateBrand = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);
  let { SalesAjex, fetchSalesAjex } = useContext(FetchApi)

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/brands'}>{t('brand_name')}</Link> },
    { label: <Link to={`/brands/edit/${id}`} className='p-2 border-round'>{t('brand_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/edit/page/${id}`)
    if (data?.Success === true) {
      console.log(data?.Response);
      setData(data?.Response);
      fetchDocuments()
    }
  }

  const fetchDocuments = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/documents/${id}`)
    if (data?.Success === true) {
      setDocuments(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
    fetchSalesAjex()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandNameEn: Data?.BrandNameEn || '',
      BrandNameAr: Data?.BrandNameAr || '',
      BrandNumber: Data?.BrandNumber || '',
      BrandEmail: Data?.BrandEmail || '',
      IDUser: Data?.IDUser || '',
      BrandPolicyAr: Data?.BrandPolicyAr || '',
      BrandPolicyEn: Data?.BrandPolicyEn || '',
      BrandDescEn: Data?.BrandDescEn || '',
      BrandDescAr: Data?.BrandDescAr || '',
      IDBrand: id,
      BrandLogo: null,
      BrandDocuments: selectMultiImage,


    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/brands')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/document/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {
        
        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchDocuments()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };
  useEffect(() => {
    formik.setFieldValue('BrandLogo', selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    formik.setValues({
      BrandNameEn: Data?.BrandNameEn || '',
      BrandNameAr: Data?.BrandNameAr || '',
      BrandNumber: Data?.BrandNumber || '',
      BrandEmail: Data?.BrandEmail || '',
      IDBrand: id,
      IDUser: Data?.IDUser || '',
      BrandPolicyAr: Data?.BrandPolicyAr || '',
      BrandPolicyEn: Data?.BrandPolicyEn || '',
      BrandDescEn: Data?.BrandDescEn || '',
      BrandDescAr: Data?.BrandDescAr || '',

    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="mt-3 image_prime2  flex justify-content-center m-auto " style={{ position: 'relative', marginBottom: '30px', width: 'fit-content' }}>
          {formik.values.BrandLogo ? (
            <Image
              preview
              loading="lazy"
              src={URL.createObjectURL(formik.values.BrandLogo)}
              alt={formik.values.BrandLogo.name}
              width='300px'
              height='300px'
              className=' rounded-circle mx-auto overflow-hidden '
              style={{ objectFit: 'cover', borderRadius: '50%' }}
            />
          ) :
            <div className='image_prime2'>
              <Image
                preview
                loading="lazy"
                src={Data.BrandLogo}
                alt={'default-image'}
                width='300px'
                height='300px'
                className=' rounded-circle mx-auto overflow-hidden '
                style={{ objectFit: 'cover', borderRadius: '50%' }}
              />
            </div>

          }
          <div style={{ position: 'absolute', right: '40px', bottom: '0px' }}>
            <input
              type="file"
              id="file-input"
              accept="image/*"
              onChange={handleImageSelect}
              style={{ display: 'none' }}
            />
            <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
              <i className='pi pi-image color-wight'></i>
            </label>
          </div>
        </div>
        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNameEn" className="font-bold block mb-2"> {t('label_brand_name_en')}    </label>
            <InputText
              name='BrandNameEn'
              id="BrandNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandNameEn}
              dir='ltr'

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandNameAr" className="font-bold block mb-2">  {t('label_brand_name_ar')}</label>
            <InputText
              value={formik.values.BrandNameAr}
              name='BrandNameAr'
              id="BrandNameAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNumber" className="font-bold block mb-2"> {t('label_brand_num')}    </label>
            <InputText
              name='BrandNumber'
              id="BrandNumber"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandNumber}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandEmail" className="font-bold block mb-2">  {t('email')}</label>
            <InputText
              value={formik.values.BrandEmail}
              name='BrandEmail'
              id="BrandEmail" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='BrandDescEn'
              id="BrandDescEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              value={formik.values.BrandDescAr}
              name='BrandDescAr'

              id="BrandDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className="grid  mt-2  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandPolicyEn" className="font-bold block mb-2">{t('Policy_en')}</label>
            <InputText
              name='BrandPolicyEn'
              id="BrandPolicyEn"
              type="text"
              dir='ltr'
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandPolicyEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandPolicyAr" className="font-bold block mb-2">{t('Policy_ar')}</label>
            <InputText
              value={formik.values.BrandPolicyAr}
              name='BrandPolicyAr'
              id="BrandPolicyAr" type="text"
              className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>
        <div className='grid  mt-2 '>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('label_brand_brand_documents')}  </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} type='button' size='small' className='w-3' severity='warning' text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="IDUser" className="font-bold block mb-2"> {t('tabel_brand_header_4')}    </label>
            <Dropdown filter  
              options={SalesAjex?.map(item => ({
                name: item.UserName,
                value: item.IDUser,
              }))}
              id="IDUser"
              name="IDUser"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDUser} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDUser", e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('user_placeholder')}
              className="w-full p-inputtext-sm"
               
            />
          </div>
        </div>
        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Brand documents" : "وثائق العلامة التجارية"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('BrandDocuments', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">

                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={item.BrandDocumentPath}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    <Button icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDBrandDocument)} />
                  </div>
                </div>
              ))}
            </div>
          </div>


        </Dialog>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/brands'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>



      </form>


    </div>
  )
}

export default UpdateBrand
