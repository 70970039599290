import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { FetchApi } from 'context/FetchApi';
 
const AddCompanyLedger = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  let { subCategoriesAccountAjex,fetchSubCategoriesAccountAjex, categoriesAccountAjex, fetchCategoriesAccountAjex } = useContext(FetchApi)

  const url = `${process.env.REACT_APP_API_URL}/company/ledger/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const CompanyLedgerType = [
    { name: isLang === "en" ? 'Credit' : '', value: 'CREDIT' },
    { name: isLang === "en" ? 'Debit' : '', value: 'DEBIT' },
  ];

  const items = [
    { label: <Link to={'/revenue'}>{t('accounts')}   </Link> },
    { label: <Link to={'/revenue/add'} className='p-2 border-round'>  {t('add_accounts')}  </Link> }
  ];


  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      CompanyLedgerAmount: '',
      CompanyLedgerDesc: '',
      CompanyLedgerType:  "DEBIT",
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values?.CategoryLogo);
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/revenue')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    fetchCategoriesAccountAjex()
    return () => {
      fetchCategoriesAccountAjex()

    }
  }, [isLang])
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="CompanyLedgerAmount" className="font-bold block mb-2">{t('amount')}</label>
            <InputText
              name='CompanyLedgerAmount'
              id="CompanyLedgerAmount"
              type='number'
              required
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={0}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="CompanyLedgerDesc" className="font-bold block mb-2">{t('desc')}</label>
            <InputText
              name='CompanyLedgerDesc'
              id="CompanyLedgerDesc"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir='ltr'
            />
          </div>
 
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="IDCategory" className="font-bold block mb-2">{t('categories_title')}</label>
            <Dropdown filter  
              options={categoriesAccountAjex?.map(item => ({
                name: item.CategoryName,
                value: item.IDCategory,
              }))}
              id="IDCategory"
              name="IDCategory"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCategory} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCategory", e.value)
                fetchSubCategoriesAccountAjex(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('Category_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div> 

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="IDSubCategory" className="font-bold block mb-2">{t('subcategories_title')}</label>
            <Dropdown filter  
              options={subCategoriesAccountAjex?.map(item => ({
                name: item.SubCategoryName,
                value: item.IDSubCategory,
              }))}
              value={formik.values.IDSubCategory} // Add this line 
              id="IDSubCategory"
              name="IDSubCategory"
              optionLabel="name"
              optionValue="value"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t('SubCategory_placeholder')}
              className="w-full p-inputtext-sm"

            />

          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col " >
            <label htmlFor="CompanyLedgerType" className="font-bold block mb-2">
              {t('type')}
            </label>
            <Dropdown filter  
              id="CompanyLedgerType"
              name="CompanyLedgerType"
              value={formik.values.CompanyLedgerType}
              options={CompanyLedgerType}
              optionLabel="name"
              required
              onChange={(e) => formik.setFieldValue("CompanyLedgerType", e.value)}
              onBlur={formik.handleBlur}
              placeholder={isLang === "en" ? 'Select a ledger type' : 'اختر النوع'}
              className="w-full p-inputtext-sm"
            />
          </div> */}
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/revenue'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}


export default AddCompanyLedger