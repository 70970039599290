import { CheckOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';

import { Avatar, Button, Dropdown, Layout, Menu, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import LogoSvg from 'constants/LogoSvg';
import { GeneralData } from 'context/General';
import { LocalizationContext } from 'context/LangChange';
import { Link, useNavigate } from 'react-router-dom';
import '../style.scss';
const { Header } = Layout;

const Navbar = () => {
    const { collapsed, setCollapsed, visible, setVisible } = useContext(GeneralData);
    let { Profile } = useContext(LocalizationContext);

    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        const currentLang = i18n.language;
        const html = document.documentElement;
        html.setAttribute('lang', currentLang);
        html.setAttribute('dir', currentLang === 'ar' ? 'rtl' : 'ltr');
    }, [i18n.language]);

    const languageMenu = (
        <Menu
            onClick={({ key }) => changeLanguage(key)}
            selectedKeys={[i18n.language]} // This will highlight the selected language
        >
            <Menu.Item
                key="en"
                icon={i18n.language === 'en' ? <CheckOutlined /> : null}
                style={{
                    paddingRight: '35px',
                    backgroundColor: i18n.language === 'en' ? '#f0f0f0' : 'transparent'
                }}
            >
                English
            </Menu.Item>
            <Menu.Item
                key="ar"
                icon={i18n.language === 'ar' ? <CheckOutlined /> : null}
                style={{
                    paddingLeft: '35px',
                    backgroundColor: i18n.language === 'ar' ? '#f0f0f0' : 'transparent'
                }}
            >
                العربية
            </Menu.Item>
        </Menu>
    );



    function logOut() {
        localStorage.removeItem("tokenBetter");
        localStorage.removeItem("IDBrand");
        localStorage.removeItem("IDUser");
        localStorage.removeItem("UserEmail");
        localStorage.removeItem("tokenBetter");
        navigate('/login');
    }
    const userMenu = (
        <Menu className='w-10rem'>
            <Menu.Item icon={<UserOutlined />}>
                <Link to={`/brands/profile/${localStorage.getItem('IDBrand')}`}>
                    {i18n.language === 'en' ? 'Profile' : 'الملف الشخصي'}
                </Link>
            </Menu.Item>
            <Menu.Item icon={<LogoutOutlined />} onClick={() => logOut()}>
                {i18n.language === 'en' ? 'Logout' : 'تسجيل خروج'}
            </Menu.Item>
        </Menu>
    );
    return (
        <Header className='flex justify-content-between px-5 align-items-center site-layout-background' style={{ background: '#fff', padding: 0 }}>
            <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => {
                    setCollapsed(!collapsed);
                    setVisible(!visible);
                }}
            />

            <Space size={16} wrap className='flex gap-5 justify-content-center align-items-center '>
                <Dropdown overlay={languageMenu} trigger={['hover']} placement="bottomRight">
                    <LogoSvg.lang style={{ width: 28, height: 28, cursor: 'pointer' }} className="bavbar_lang_btn" />
                </Dropdown>

                <Dropdown overlay={userMenu} trigger={['hover']} placement="bottomRight" >
                    <div className="navbar_user_menu" dir="ltr">
                        <Avatar
                            style={{
                                backgroundColor: '#fde3cf',
                                color: '#f56a00',
                                cursor: 'pointer',
                            }}
                        >
                            {Profile?.UserName?.charAt(0)?.toUpperCase()}
                        </Avatar>
                        <span style={{ marginLeft: 8 }}>  {Profile?.UserName}  </span>
                    </div>
                </Dropdown>
            </Space>
        </Header>
    );
};

export default Navbar;
