import {
    GoogleMap,
    Marker,
    useLoadScript
} from '@react-google-maps/api';
import { LocalizationContext } from 'context/LangChange';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';

const libraries = ['places'];

const AddGoogleMap = ({
    hideMapDialog,
    openMap,
    isLang
}) => {
    const mapContainerStyle = {
        width: '100%',
        height: '500px',
        borderRadius: '8px'
    };
    let { userLocationMap, setLoctionMap, markers, setMarkers } = useContext(LocalizationContext);


    const [selectedMarker, setSelectedMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null); // Add state for current location

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk',
        libraries,
    });


    const handleMapClick = event => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const newMarker = { lat, lng };

        if (currentLocation) {
            setCurrentLocation(null);
        }

        setMarkers([newMarker]);
        setSelectedMarker(newMarker);
        setLoctionMap(newMarker);
    };

    const handleMarkerClick = marker => {
        setSelectedMarker(marker);
    };


    const handleCurrentLocationClick = () => {
        navigator.geolocation.getCurrentPosition(
            position => {
                const { latitude, longitude } = position.coords;
                const newMarker = { lat: latitude, lng: longitude };
                setMarkers([newMarker]);
                setSelectedMarker(newMarker);
                setCurrentLocation(newMarker); // Set current location state
            
                setLoctionMap(newMarker);
            },
            error => {
                console.error(error);
            }
        );
    };
    useEffect(() => {
        handleCurrentLocationClick()
        return () => {
            handleCurrentLocationClick()
        }  
    }, [])
    const handleMapLoad = (map) => {
        setMap(map);
    };
    const handleMapDragEnd = () => {
        if (map) {
            // Get the center of the map when dragging ends
            const center = map.getCenter();
            const newMarker = { lat: center.lat(), lng: center.lng() };

            // Clear current location state only if there was a pin on the current location
            if (currentLocation) {
                setCurrentLocation(null);
            }

            setMarkers([newMarker]);
            setSelectedMarker(newMarker);
            setLoctionMap(newMarker);
        }
    };
    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';


    return (
        <Dialog
            visible={openMap}
            style={{ width: '32rem' }}
            breakpoints={{ '1000px': '1000px', '1000px': '1000px' }}
            modal
            onHide={hideMapDialog}
            header={isLang === "en" ? "Google map" : "تحديد موقعك"}
            dir={isLang === "en" ? 'ltr' : 'rtl'}
        >

            <div className=''>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={12}
                    center={userLocationMap ? userLocationMap : currentLocation} // Use current location if available
                    onClick={handleMapClick}
                    onLoad={handleMapLoad}
                    onDragEnd={handleMapDragEnd}
                >


                    {currentLocation && ( // Add marker for current location
                        <Marker position={currentLocation} />
                    )}

                    {markers.map((marker, index) => (
                        <Marker
                            key={index}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            onClick={() => handleMarkerClick(marker)}
                        />
                    ))}

                </GoogleMap>

            </div>
        </Dialog>

    );
};


export default AddGoogleMap