import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { LocalizationContext } from 'context/LangChange';
import { Modal, Button } from 'antd';
import { useContext, useState } from 'react';

const libraries = ['places'];

const ShowGoogleMapModal = ({ hideImagesProjectsDialog, openMap, isLang }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '500px',
    borderRadius: '8px',
  };
  const { userLocationMap, setLoctionMap } = useContext(LocalizationContext);

  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk', // ضع مفتاح API الخاص بك هنا
    libraries,
  });

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <Modal
      visible={openMap}
      onCancel={hideImagesProjectsDialog}
      title={isLang === 'en' ? 'Show Location' : 'موقع'}
      footer={null}
      width="1000px"
      style={{ direction: isLang === 'en' ? 'ltr' : 'rtl' }}
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={18}
        center={userLocationMap}
      >
        {userLocationMap && <Marker position={userLocationMap} />}

        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}
      </GoogleMap>
    </Modal>
  );
};

export default ShowGoogleMapModal;
