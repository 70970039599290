import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Table, Tag, Select, notification, Pagination } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const BrandReviews = () => {
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const { id } = useParams();
  const { isLang } = useContext(LocalizationContext);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const { role } = useContext(FetchApi);

  const statusOptions = [
    { label: t('SHOW'), value: 'SHOW' },
    { label: t('HIDE'), value: 'HIDE' },
  ];

  const fetchData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/brands/ratings`;
    const response = await axios.post(url, { IDPage: page + 1, IDBrand: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    });
    setData(response?.data?.Response?.BrandRatings || []);
    setPagesNum(response?.data?.Response?.Pages || 0);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };
  useEffect(() => {
    scrollToTop()
    fetchData();
  }, [isLang, page]);

  const onStatusChange = async (rowData, value) => {
    setEditingRow(null);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/brands/ratings/status`, {
        IDBrandRating: rowData?.IDBrandRating,
        BrandRatingStatus: value,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      });

      if (data?.Success === true) {
        notification.success({ message: t('Successful'), description: data?.ApiMsg });
        fetchData();
      }
    } catch (error) {
      notification.error({ message: t('Error'), description: 'Data invalid' });
    }
  };

  const getSeverity = (status) => {
    switch (status) {
      case 'HIDE': return 'error';
      case 'SHOW': return 'success';
      case 'PENDING': return 'warning';
      default: return 'default';
    }
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BrandRatingStatus === "SHOW" ? t('SHOW') : rowData?.BrandRatingStatus === "HIDE" ? t('HIDE') : t('pending');
    const placeholderText = rowData?.BrandRatingStatus !== 'SHOW' ? t('HIDE') : t('SHOW');

    return editingRow === rowData?.IDBrandRating ? (
      <Select
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.BrandRatingStatus)}
        onChange={(value) => onStatusChange(rowData, value)}
        placeholder={placeholderText}
        size="small"
      />
    ) : (
      <Tag color={getSeverity(rowData.BrandRatingStatus)}>
        <div
          className="flex justify-center align-items-center gap-2"
          onClick={() => {
            if (role?.EDIT_BRAND_REVIEWS === 1) {
              setEditingRow(rowData?.IDBrandRating);
            }
          }}
        >
          <span>{editingStatus}</span>
          {role?.EDIT_BRAND_REVIEWS === 1 && <i className='pi pi-angle-down'></i>}
        </div>
      </Tag>
    );
  };

  const columns = [
    {
      title: t('brand_name'),
      dataIndex: 'BrandName',
      key: 'BrandName',
      align: 'center',
      width: 200,
      ellipsis: true,
    },
    {
      title: t('Rating'),
      dataIndex: 'BrandRating',
      key: 'BrandRating',
      align: 'center',
      width: 200,
      ellipsis: true,
      render: (text) => <div className='flex justify-center'><span>{text}</span></div>,
    },
    {
      title: t('Review'),
      dataIndex: 'BrandReview',
      key: 'BrandReview',
      align: 'center',
      width: 200,
      ellipsis: true,
    },
    {
      title: t('name'),
      dataIndex: 'ClientName',
      key: 'ClientName',
      align: 'center',
      width: 200,
      ellipsis: true,
    },
    {
      title: t('phone'),
      dataIndex: 'ClientPhone',
      key: 'ClientPhone',
      align: 'center',
      width: 200,
      ellipsis: true,
    },
    {
      title: t('status'),
      dataIndex: 'BrandRatingStatus',
      key: 'BrandRatingStatus',
      align: 'center',
      width: 200,
      ellipsis: true,
      render: (text, record) => statusBodyTemplate(record),
    },
  ];

  return (
    <div className='mb-8'>
      <Table
        className="custom-datatable"
        columns={columns}
        dataSource={data}
        rowKey="IDBrandRating"
        pagination={false}
        bordered
        scroll={{ x: '100%' }}

      />
      <Pagination
        current={page + 1}
        total={pagesNum * 10}
        pageSize={10}
        onChange={(page) => setPage(page - 1)}
        showSizeChanger={false}
        style={{ marginTop: '16px', textAlign: 'center' }}
      />
    </div>
  );
};

export default BrandReviews;
