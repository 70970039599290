import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const UpdateAreas = () => {

  let { id } = useParams()
  const toast = useRef(null);
  let navigate = useNavigate();
  let { city, country, area, fetchArea, fetchCity, fetchCountry, setCity } = useContext(FetchApi)

  let { isLang } = useContext(LocalizationContext);
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/location/areas/edit`;
  let { t } = useTranslation()

  const [data, setData] = useState([])
  const statusList = [
    { name: t('active'), value: '1' },
    { name: t('inactive'), value: '0' },
  ];
  async function getdata() {
    const url = `${process.env.REACT_APP_API_URL}/location/areas/edit/page/${id}`;
    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data',
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      }
    })
    if (data?.Success) {
      setData(data?.Response)
    }

  }

  const [loading, setLoading] = useState(false);

  const items = [
    { label: <Link to={'/areas'}> {t('areas_title')}  </Link> },
    { label: <Link to={`/areas/edit/${id}`} className='p-2 border-round'>   {t('edit_areas')} </Link> }
  ];

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      IDArea: id || '',
      IDCity: data?.IDCity || '',
      AreaNameEn: data?.AreaNameEn || '',
      AreaNameAr: data?.AreaNameAr || '',
    },
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      try {
        setLoading(true);

        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/areas')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        setLoading(false);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    getdata()
  }, [id]);

  useEffect(() => {
    formik.setValues({
      IDArea: id || '',
      IDCity: data?.IDCity || '',
      IDCountry: data?.IDCountry || '',
      AreaNameEn: data?.AreaNameEn || '',
      AreaNameAr: data?.AreaNameAr || '',

    });
  }, [data, formik.setValues]);

  useEffect(() => {
    fetchCountry()
    fetchArea(data?.IDCity)
    return () => {
      fetchCountry()
    }
  }, [isLang])
  return (
    <div className='mb-8'>     
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="AreaNameEn" className="font-bold block mb-2"> {t('label_areas_nameEn')}   </label>
            <InputText name='AreaNameEn' id="AreaNameEn" value={formik.values.AreaNameEn} type='text' className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="AreaNameAr" className="font-bold block mb-2">   {t('label_areas_nameAr')}  </label>
            <InputText name='AreaNameAr' id="AreaNameAr" type='text' value={formik.values.AreaNameAr} className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="grid  mt-3  ">
          <div className="lg:col-6 md:col-12 sm:col-12      ">
            <label htmlFor="IDCountry" className="font-bold block mb-2"> {t('tabel-header-Countries-1')}</label>
            <Dropdown filter  
              options={country?.map(item => ({
                name: item.CountryName,
                value: item.IDCountry,
              }))}
              id="IDCountry"
              name="IDCountry"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCountry} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCountry", e.value)
                fetchCity(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('status_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12      ">
            <label htmlFor="IDCity" className="font-bold block mb-2"> {t('tabel-header-cities-1')}</label>
            <Dropdown filter  
              options={city?.map(item => ({
                name: item.CityName,
                value: item.IDCity,
              }))}
              id="IDCity"
              name="IDCity"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDCity} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDCity", e.value)
                fetchArea(e.value)
              }}
              onBlur={formik.handleBlur}
              placeholder={t('status_placeholder')}
              className="w-full p-inputtext-sm"
            />
          </div>



        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} size='small' className='mt-3' />
          </div>
          <Link to={'/areas'}>
            <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form >
    </div >
  )
}

export default UpdateAreas