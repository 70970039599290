import { Col, Image, notification, Row, Pagination, Breadcrumb, Spin, Empty } from 'antd';
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const BrandGallery = () => {
  const { id } = useParams();
  const { isLang } = useContext(LocalizationContext);
  const { t, i18n } = useTranslation();

  const [data, setData] = useState([]); // البيانات الأصلية
  const [currentPage, setCurrentPage] = useState(1); // الصفحة الحالية
  const [pageSize] = useState(8); // عدد العناصر لكل صفحة
  const [loading, setLoading] = useState(true); // حالة التحميل

  const fetchData = async () => {
    setLoading(true); // بدء التحميل
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/brands/gallery`, { IDBrand: id }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('tokenBetter')}`,
        }
      });
      setData(response?.data?.Response || []);
    } catch (error) {
      notification.error({ message: t('Error'), description: t('Data fetching failed') });
    }
    setLoading(false); // انتهاء التحميل
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, [i18n.language]);

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="mb-8">
      <div className="mb-5">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link>{t('brand_name')}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{t('brand_gallery')}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {loading ? (
        <Spin tip="Loading..." size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} />
      ) : (
        <div className="card">

          {currentData.length === 0 ? (
            <Empty description={t('empty')} style={{ marginTop: '20px' }} />
          ) : (
            <>
              <Row gutter={[30, 30]}>
                {currentData.map((item) => (
                  <Col key={item.IDBrandGallery} xs={24} sm={12} md={8} lg={6}>
                    {item.BrandGalleryType === "IMAGE" && (
                      <Image
                        src={item.BrandGalleryPath}
                        alt="Brand image"
                        width="100%"
                        height={200}
                        style={{ objectFit: 'cover', borderRadius: '10px' }}
                        preview
                      />
                    )}
                    {item.BrandGalleryType === "VIDEO" && (
                      <iframe
                        width="100%"
                        height={200}
                        className="border-round"
                        src={`${item.BrandGalleryPath}`}
                        title="Video"
                        allowFullScreen
                        style={{ borderRadius: '10px' }}
                      />
                    )}
                  </Col>
                ))}
              </Row>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data.length}
                onChange={(page) => setCurrentPage(page)}
                style={{ marginTop: '20px', textAlign: 'center' }}
              />
            </>
          )}


        </div>
      )}
    </div>
  );
};

export default BrandGallery;
