import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const AddPositionBrand = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const visitNum = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [idCategory, setIDCategory] = useState(null);
    const [idSubCategory, setIDSubCategory] = useState(null);

    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [Data, setData] = useState(null);
    let { subCategoriesAjex, fetchSubCategoriesAjex, categoriesAjex, fetchCategoriesAjex } = useContext(FetchApi)

    const items = [
        { label: <Link to={'/positions'}>{t('positions')}</Link> },
        { label: <Link to={`/positions/brands/${id}`} className='px-3 py-2 border-round'>{isLang === "en" ? 'Unique visits  ' : 'زيارات فريدة'}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className='flex gap-4'>
                <Dropdown filter  
                    options={categoriesAjex?.map(item => ({
                        name: item.CategoryName,
                        value: item.IDCategory,
                    }))}
                    id="IDCategory"
                    name="IDCategory"
                    optionLabel="name"
                    optionValue="value"
                    value={idCategory} // Add this line 
                    onChange={(e) => {
                        setIDSubCategory(null);
                        setIDCategory(e.value);
                        fetchSubCategoriesAjex(e.value)
                    }}
                    placeholder={t('Category_placeholder')}
                    className="w-14rem p-inputtext-sm"
                />
                <Dropdown filter  
                    options={subCategoriesAjex?.map(item => ({
                        name: item.SubCategoryName,
                        value: item.IDSubCategory,
                    }))}
                    id="IDSubCategory"
                    name="IDSubCategory"
                    optionLabel="name"
                    optionValue="value"
                    value={idSubCategory} // Add this line 
                    onChange={(e) => {
                        
                        setIDSubCategory(e.value);
                    }}
                    placeholder={t('SubCategory_placeholder')}
                    className="w-14rem p-inputtext-sm"

                />

            </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} />
            </span>

        </div>
    );

    const fetchData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/positions/brands`;
        let data = await axios.post(url, {
            IDPosition: id,
            IDCategory: idCategory,
            IDSubCategory: idSubCategory
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }

    useEffect(() => {
        fetchData();
    }, [isLang, idCategory, idSubCategory])



    const [brandDetailes, setBrandDetailes] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);

    const actionBodyTemplate = (rowData) => {
        const isChecked = rowData?.PositionBrandVisitNumber > 0;

        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Checkbox
                    onChange={(e) => confirmDeleteusers(rowData)}
                    checked={isChecked}
                />
            </div>
        );
    };

    // delete users
    const confirmDeleteusers = async (users) => {
        setBrandDetailes(null)
        setDeleteusersDialog(true);
        setBrandDetailes(users)
    };
    const deleteusers = async () => {
        setdeleteLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/positions/brands/status`, {
            IDPosition: id,
            IDBrand: brandDetailes?.IDBrand,
            PositionStatus: 0,
            PositionBrandVisitNumber: visitNum?.current.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {
            
            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                setTimeout(() => {
                    setDeleteusersDialog(false);
                    fetchData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" raised outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="" onClick={deleteusers} />
        </div>
    );


    useEffect(() => {
        fetchCategoriesAjex()
        return () => {
            fetchCategoriesAjex()

        }
    }, [isLang])
    return (
        <div >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <BreadCrumb model={items} home={home} />

            <div className="card mt-4">
                <DataTable className="custom-datatable"
                    ref={dt}
                    scrollable scrollHeight="100vh"
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    size='small'
                    showGridlines
                    header={header}
                >
                    <Column body={actionBodyTemplate} header={isLang === "en" ? 'Change visit numberber' : 'تغيير رقم الزيارة'} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    <Column  field="BrandName" header={t('tabel_brand_header_1')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="PositionBrandVisitNumber" header={t('visits')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>

                </DataTable>
                <Dialog
                    dir={isLang == "en" ? 'ltr' : 'rtl'}
                    visible={deleteusersDialog}
                    style={{ width: '32rem' }}
                    breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                    header={isLang == "en" ? 'Change visits number' : 'تأكيد الحذف'}
                    modal
                    footer={deleteusersDialogFooter}
                    onHide={hideDeleteusersDialog}>
                    <InputText 
                        type='text'
                        className="w-full  p-inputtext-sm"
                        ref={visitNum}
                        defaultValue={brandDetailes?.PositionBrandVisitNumber}
                    />
                </Dialog>

            </div>
            <div className="btn_gapAr group flex justify-content-center gap-4 mb-4 ">
                {
                    Data &&
                    <div className="btn_submit">
                        <Link to={`/positions`}>
                            <Button raised icon="pi pi-check" severity="warning" label={t('submit')} type="submit" size='small' className='mt-3 w-8rem' />
                        </Link>
                    </div>
                }

            </div>
        </div>
    )
}


export default AddPositionBrand