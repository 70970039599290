import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateSubCategoryAccount = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const urlUpdateData = `${process.env.REACT_APP_API_URL}/subcategories/edit`;
    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const items = [
      { label: <Link to={'/subcategory_account'}>{t('subcategories_title')}</Link> },
      { label: <Link to={`/subcategory_account/edit/${id}`} className='p-2 border-round'>{t('subcategories_edit')}</Link> }
    ];
    const fetchData = async () => {
      let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/subcategories/edit/page/${id}`,  {
        headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
    })
      if (data?.Success === true) {
        setData(data?.Response);
        console.log(data?.Response);
      }
    }
    useEffect(() => {
      fetchData()
    }, []);
    const [selectedImage, setSelectedImage] = useState(null);
    const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
    const home = { icon: 'pi pi-home', url: '/' };
    const formik = useFormik({
      initialValues: {
        SubCategoryNameEn: Data?.SubCategoryNameEn || '',
        SubCategoryNameAr: Data?.SubCategoryNameAr || '',
        IDSubCategory: id, 
      },
      onSubmit: async (values, { resetForm }) => {
        try {
          setLoading(true);
          let { data } = await axios.post(urlUpdateData, values,
            {
              headers: {
                // 'Content-Type': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
              },
            })
  
          if (data?.Success === true) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
            setTimeout(() => {
              setLoading(false);
              navigate('/subcategory_account')
              resetForm();
            }, 1000);
          } else if (data?.status === 400) {
            toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
            resetForm();
            setLoading(false);
          } else {
            setLoading(false);
  
            toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
          }
  
        } catch ({ response }) {
          toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
          setLoading(false);
  
        }
      }
    });
 
    useEffect(() => {
      formik.setValues({
        SubCategoryNameEn: Data?.SubCategoryNameEn || '',
        SubCategoryNameAr: Data?.SubCategoryNameAr || '',
        IDSubCategory: Data?.IDSubCategory || '',
  
      });
    }, [Data, formik.setValues]);
  
  
    return (
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
  
        <BreadCrumb model={items} home={home} />
        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
   
          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="SubCategoryNameEn" className="font-bold block mb-2"> {t('label_subcategories_nameEn')}     </label>
              <InputText
                name='SubCategoryNameEn'
                id="SubCategoryNameEn"
                type="text"
                className="w-full"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.SubCategoryNameEn}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SubCategoryNameAr" className="font-bold block mb-2">  {t('label_subcategories_nameAr')}  </label>
              <InputText
                value={formik.values.SubCategoryNameAr}
                name='SubCategoryNameAr'
                id="SubCategoryNameAr" type="text" className="w-full"  onChange={formik.handleChange}
                onBlur={formik.handleBlur} />
            </div>
          </div>
  
  
  
          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="warning" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={'/subcategory_account'}>
              <Button severity="warning" label={t('cancel')} type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>
    )
  }
export default UpdateSubCategoryAccount
