import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { Image } from 'primereact/image';

const UpdatePlanData = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/plans/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);

  //!hide images modal 
  const [openImanes, setOpenImanes] = useState(false);
  const [selectMultiImage, setSelectMultiImage] = useState([]);
  const hideImagesProjectsDialog = (e) => setOpenImanes(false)
  //*****Handle multi-image select 
  const handleMultiImageSelect = (event) => setSelectMultiImage([...selectMultiImage, event.target.files[0]])
  const handleDeleteImage = (index) => {
    const updatedImages = [...selectMultiImage];
    updatedImages.splice(index, 1);
    setSelectMultiImage(updatedImages);
  };
  const toast = useRef(null);
  let navigate = useNavigate()
  const DiscountType = [
    { name: 'Value', value: 'VALUE' },
    { name: 'Precent', value: 'PERCENT' },
  ];
  const items = [
    { label: <Link to={'/plans'}>{t('title_plans')}</Link> },
    { label: <Link to={`/plans/edit/${id}`} className='p-2 border-round'>{t('update_plans')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/plans/edit/page/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    if (data?.Success === true) {
      setData(data?.Response);
      setDocuments(data?.Response?.PlanGallery);

    }
  }

  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const cities = [
    { name: 'السبت', value: 'SATURDAY' },
    { name: 'الأحد', value: 'SUNDAY' },
    { name: 'الاثنين', value: 'MONDAY' },
    { name: 'الثلاثاء', value: 'TUESDAY' },
    { name: 'الأربعاء', value: 'WEDNESDAY' },
    { name: '  الخميس', value: 'THURSDAY' },
    { name: 'جمعة', value: 'FRIDAY' },
  ];
  const formik = useFormik({
    initialValues: {
      IDPlan: id,
      PlanNameEn: Data?.PlanNameEn || '',
      PlanNameAr: Data?.PlanNameAr || '',
      PlanDescEn: Data?.PlanDescEn || '',
      PlanDescAr: Data?.PlanDescAr || '',
      ChequeValue: Data?.ChequeValue || '',
      // ChequeEarnTime: Data?.ChequeEarnTime || '',
      ChequeMaxOut: Data?.ChequeMaxOut || '',
      // PlanGallery: selectMultiImage,
      ChequeEarnDay: Data?.ChequeEarnDay || '',
    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/plans')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });

  const deleteDocument = async (id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/plans/gallery/remove/${id}`,
      {
        headers: {
          // 'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      }).then(({ data }) => {
        
        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
          fetchData()
          setTimeout(() => {
          }, 1000);
        }
      }).catch(({ response }) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
      })

  };
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [totalMinutes, setTotalMinutes] = useState(null); // Example: Initial value from API
  const [days, setDays] = useState(0);
  const [months, setMonths] = useState(0);

  useEffect(() => {
    formik.setValues({
      PlanNameEn: Data?.PlanNameEn || '',
      PlanNameAr: Data?.PlanNameAr || '',
      IDPlan: id,
      PlanDescEn: Data?.PlanDescEn || '',
      PlanDescAr: Data?.PlanDescAr || '',
      ChequeValue: Data?.ChequeValue || '',
      ChequeMaxOut: Data?.ChequeMaxOut || '',
      // ChequeEarnDay: Data?.ChequeEarnDay || '',
      // ChequeEarnTime: Data?.ChequeEarnTime || '',
    });
    // setTotalMinutes(Data?.ChequeEarnTime)
    formik.setFieldValue('ChequeEarnDay', Data?.ChequeEarnDay || '');
  }, [Data, formik.setValues]);

  useEffect(() => {
    // Calculate hours, minutes, days, and months from total minutes when totalMinutes changes
    const calculatedMonths = Math.floor(totalMinutes / (30 * 24 * 60));
    const remainingDays = totalMinutes % (30 * 24 * 60);
    const calculatedDays = Math.floor(remainingDays / (24 * 60));
    const remainingHours = remainingDays % (24 * 60);
    const calculatedHours = Math.floor(remainingHours / 60);
    const calculatedMinutes = remainingHours % 60;

    // Update state with calculated values
    setMonths(calculatedMonths);
    setDays(calculatedDays);
    setHours(calculatedHours);
    setMinutes(calculatedMinutes);
  }, [totalMinutes]);

  const handleHoursChange = (e) => {
    const { value } = e;
    setHours(value);
    const total = value * 60 + minutes + days * 24 * 60 + months * 30 * 24 * 60;
    setTotalMinutes(total);
  };

  const handleMinutesChange = (e) => {
    const { value } = e;
    setMinutes(value);
    const total = hours * 60 + value + days * 24 * 60 + months * 30 * 24 * 60;
    setTotalMinutes(total);
  };
  const handleDaysChange = (e) => {
    const { value } = e;
    setDays(value);
    const total = hours * 60 + minutes + value * 24 * 60 + months * 30 * 24 * 60;
    setTotalMinutes(total);
  };

  const handleMonthsChange = (e) => {
    const { value } = e;
    setMonths(value);
    const total = hours * 60 + minutes + days * 24 * 60 + value * 30 * 24 * 60;
    setTotalMinutes(total);
  };
  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PlanNameEn" className="font-bold block mb-2">{t('label_Plan_name_en')}</label>
            <InputText
              name='PlanNameEn'
              id="PlanNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.PlanNameEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PlanNameAr" className="font-bold block mb-2">{t('label_Plan_name_ar')}</label>
            <InputText
              value={formik.values.PlanNameAr}
              name='PlanNameAr'
              id="PlanNameAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PlanDescEn" className="font-bold block mb-2">{t('label_Plan_desc_en')}</label>
            <InputText
              name='PlanDescEn'
              id="PlanDescEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.PlanDescEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PlanDescAr" className="font-bold block mb-2">{t('label_Plan_desc_ar')}</label>
            <InputText
              value={formik.values.PlanDescAr}
              name='PlanDescAr'
              id="PlanDescAr" type="text" className="w-full" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div> */}

        </div>
        <div className="grid  mt-2  ">

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeValue" className="font-bold block mb-2">{t('ChequeValue')}</label>
            <InputText
              name='ChequeValue'
              id="ChequeValue"
              value={formik.values.ChequeValue}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeMaxOut" className="font-bold block mb-2">{t('ChequeMaxOut')}</label>
            <InputText
              name='ChequeMaxOut'
              id="ChequeMaxOut"
              value={formik.values.ChequeMaxOut}
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ">
            <label htmlFor="ChequeEarnDay" className="font-bold block mb-2">{t('ChequeEarnDay')}</label>
            <MultiSelect
              value={formik.values?.ChequeEarnDay?.split(',')} // Convert the string back to an array
              onChange={(e) => {
                const selectedDaysString = e.value.join(',');
                formik.setFieldValue('ChequeEarnDay', selectedDaysString);
              }}
              options={cities}
              optionLabel="name"
              placeholder="Select Days"
              maxSelectedLabels={7}
              display="chip"
              className="w-full p-inputtext-sm"
            />
          </div>
          {/* <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}>
            <label htmlFor="ChequeEarnTime" className="font-bold block mb-2">{t('ChequeEarnTime')}</label>
            <div className='flex gap-3 flex-column'>
              <div className='  grid'>


                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                <label htmlFor="Months">{t('days')}</label>
                  <InputNumber
                    id="months"
                    value={months}
                    onChange={(e) => handleMonthsChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>

                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                <label htmlFor="days">{t('days')}</label>

                  <InputNumber
                    id="days"
                    value={days}
                    onChange={(e) => handleDaysChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-3 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}>
            <label htmlFor="ChequeEarnTime" className="font-bold block mb-2">{t('ChequeEarnTime')}</label>
            <div className='flex gap-3 flex-column'>
              <div className='  grid'>

                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                <label htmlFor="hours">{t('hours')}</label>
                  <InputNumber
                    id="hours"
                    value={hours}
                    onChange={(e) => handleHoursChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
                <div className='flex flex-column lg:col-6 md:col-12 sm:col-12 input__Col'>
                <label htmlFor="minutes">{t('minutes')}</label>

                  <InputNumber
                    id="minutes"
                    value={minutes}
                    onChange={(e) => handleMinutesChange(e)}
                    mode="decimal"
                    min={0}
                    showButtons
                  />
                </div>
              </div>
            </div>
          </div> */}


          {/* <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="link" className="font-bold block mb-2"> {t('gallery')}  </label>
            <div className="flex btn_gapAr gap-8">
              <Button label={t('image')} type='button' size='small' severity="warning" text raised icon="pi pi-image" onClick={() => setOpenImanes(!openImanes)} />
            </div>
          </div> */}
        </div>


        <Dialog visible={openImanes}
          style={{ width: '50%' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header={isLang === "en" ? "Multi Images" : "صور متعددة"}
          dir={isLang === "en" ? 'ltr' : 'rtl'}
          modal
          onHide={hideImagesProjectsDialog}
        >
          <div className="grid mt-2 flex flex-column">
            <div className="mt-3 mb-3 flex justify-content-center">
              <div className="mt-3 mb-3 flex justify-content-center">
                <label htmlFor='fileInput ' className='btn_gapAr btn_gapAr_multi_img flex gap-3' >
                  <Button size='small' label={isLang === "en" ? "Select Images " : ' أختر  صور من جهازك'} type='button' icon={'pi pi-image'} onClick={() => document.getElementById('fileInput').click()} />
                  <Button size='small' label={t('save')} type='button' icon={'pi pi-check'} onClick={() => setOpenImanes(false)} />
                </label>
                <input
                  type='file'
                  id='fileInput'
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    formik.setFieldValue('PlanGallery', [...selectMultiImage, event.target.files[0]]);
                    handleMultiImageSelect(event);
                  }}
                />
              </div>
            </div>


            {/*  Display selected images */}
            <div className="grid  mt-2   ">
              {selectMultiImage?.map((image, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-200 shadow-4 border-round p-3 flex justify-content-center align-items-center">
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(image)}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    {/* <img src={URL.createObjectURL(image)} alt={`Image ${index}`} style={{ objectFit: 'contain' }} className='w-full h-10rem  border-round-xl' /> */}
                    <Button
                      size="small"
                      type="button"
                      severity='danger'
                      className='btn__porfile bg_delete absolute '
                      icon="pi pi-trash"
                      onClick={() => handleDeleteImage(index)}
                    />

                  </div>
                </div>
              ))}
            </div>
            <div className="grid  mt-2   ">
              {documents?.map((item, index) => (
                <div key={index} className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col mb-5 relative">
                  <div className="bg-gray-100 shadow-4 border-round p-3 flex justify-content-center align-items-center">
            
                    <Image
                      preview
                      loading="lazy"
                      src={item.PlanGalleryPath}
                      alt={'brnad iamge'}
                      width='300px'
                      height='100%'
                      className=' mx-auto overflow-hidden '
                      style={{ objectFit: 'cover' }}
                    />
                    <Button icon="pi pi-times" severity="danger" aria-label="Cancel" className="btn__porfile bg_delete  absolute  " onClick={() => deleteDocument(item.IDPlanGallery)} />

                  </div>
                </div>
              ))}
            </div>
          </div>


        </Dialog>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="warning" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/plans'}>
            <Button label={t('cancel')} severity="warning" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>



      </form>


    </div>
  )
}

export default UpdatePlanData
