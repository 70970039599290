
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import { FetchApi } from 'context/FetchApi';
import PostionInfo from './PostionInfo';
const Position = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    let { role } = useContext(FetchApi)
    const inputRef = useRef(null);

    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deletepositionsDialog, setDeletepositionsDialog] = useState(false);
    const [IdDeletepositions, setIdDeletepositions] = useState(null);
    const [Data, setData] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const [open, setOpen] = useState(false);
    const [viewData, setViewData] = useState(null);
    const hideViewDialog = (data) => {
        setOpen(true)
        setViewData(data)
    };
    // Add new positions
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/positions/add">
                    <Button severity="warning" label={t('add_positions')} icon="pi pi-plus" size='small' />
                </Link>
            <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="warning" icon="pi pi-history" size='small' />

            </div>
        );
    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Button onClick={() => hideViewDialog(rowData)} className="disabled-button" icon="pi pi-eye " rounded outlined text raised severity='success' tooltip={t('positions_info')} tooltipOptions={{ position: t('tooltip') }} />
                {
                    role?.EDIT_POSITIONS == 1 &&
                    <>
                        <Link to={`/positions/edit/${rowData.IDPosition}`}>
                            <Button icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
                        </Link>
                        <Button icon="pi pi-trash" rounded outlined text raised severity="danger" onClick={() => confirmDeletepositions(rowData)} />
                    </>
                }
                <Link to={`/positions/brands/${rowData.IDPosition}`}>
                    <Button className="disabled-button" icon="pi  pi-clone " rounded outlined text raised severity='warning' tooltip={t('brand_name')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                <Link to={`/positions/clients/${rowData.IDPosition}`}>
                    <Button className="disabled-button" icon="pi pi-user " rounded outlined text raised severity='success' tooltip={t('clients_positions')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                {
                    localStorage.getItem('Role') == 1 &&
                    <Link to={`/log/${rowData.IDPosition}/POSITION `}>
                        <Button icon="pi pi-file" text raised rounded outlined severity="secondary" tooltip={t('log')} tooltipOptions={{ position: t('tooltip') }} />
                    </Link>
                }

            </div>
        );
    };
    // location path 
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        localStorage.setItem('logPath', currentPath)
    }, [])
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h2></h2>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
            type="search"
            className="w-full p-inputtext-sm"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    fetchData(e.target.value);
                }
                if (e.target.value == '') {
                    fetchData(e.target.value);
                }
            }}
            placeholder={t('search_placeholder')}
            ref={inputRef}
        />            </span>
        </div>
    );

    const fetchData = async (SearchKey) => {

        const url = `${process.env.REACT_APP_API_URL}/positions`;
        let data = await axios.post(url, { IDPage: page + 1, SearchKey },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Positions);
        setPagesNum(data?.data?.Response?.Pages)

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])


    // delete positions
    const confirmDeletepositions = async (positions) => {
        setDeletepositionsDialog(true);
        setIdDeletepositions(positions?.IDPosition)
    };
    const deletepositions = async (rowData) => {
        setdeleteLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/positions/status`, {
            IDPosition: IdDeletepositions,
            PositionStatus: 'DELETED'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                setTimeout(() => {
                    setDeletepositionsDialog(false);
                    fetchData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeletepositionsDialog = () => setDeletepositionsDialog(false);
    const deletepositionsDialogFooter = (
        <div className='btn_gapAr'>
            <Button label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeletepositionsDialog} />
            <Button label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deletepositions} />
        </div>
    );
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/positions/status`, {
            IDPosition: rowData?.IDPosition,
            PositionStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.PositionStatus === "ACTIVE" ? t('active') : rowData?.PositionStatus === "INACTIVE" ? t('inactive') : t('pending');

        return editingRow === rowData?.IDPosition ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.PositionStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.PositionStatus === "ACTIVE" ? t('active') : rowData?.PositionStatus === "INACTIVE" ? t('inactive') : t('pending')}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                severity={getSeverity(rowData.PositionStatus)}
                onClick={() => {
                    if (role?.EDIT_POSITIONS == 1) {
                        setEditingRow(rowData?.IDPosition)
                    }
                }}
                rounded
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span>{`${rowData?.PositionStatus === "ACTIVE" ? t('active') : rowData?.PositionStatus === "INACTIVE" ? t('inactive') : t('pending')}`}  </span>
                    {
                        (role?.EDIT_POSITIONS == 1) &&
                        <i className='pi pi-angle-down'></i>
                    }
                </div>
            </Tag>
        );
    };

    const resetData = () => {
        fetchData();
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال
    }
    const getSeverity = (status) => {
        switch (status) {
            case 'INACTIVE':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';

            case '':
                return 'info';
        }
    };

    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">
                {
                    role?.ADD_POSITIONS == 1 &&
                    <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
                }

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    header={header}>
                    <Column field="PositionTitle" header={t('title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="PositionStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} // Calculate the first record index based on the page
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deletepositionsDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deletepositionsDialogFooter} onHide={hideDeletepositionsDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                    </span>
                </div>
            </Dialog>
            <PostionInfo
                data={viewData}
                open={open}
                setOpen={setOpen}
                isLang={isLang}
                t={t}
            />
        </div>
    );
};


export default Position
