import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { LocalizationContext } from 'context/LangChange';
import { useContext } from 'react';

const libraries = ['places'];

const ShowGoogleMap = ({ isLang }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '500px',
    borderRadius: '8px',
    marginTop: '20px',
  };
  const { userLocationMap } = useContext(LocalizationContext);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAtUOb461InzoQoGEVKKVqqLf2NbwSjqdk',
    libraries,
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={userLocationMap}
      >
        {userLocationMap && <Marker position={userLocationMap} />}
      </GoogleMap>
    </div>
  );
};

export default ShowGoogleMap;
