import { Col, Image, Row, Typography, Descriptions, Divider, Card, Spin } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

const ProfileBrand = () => {
  let { id } = useParams();
  let { t, i18n } = useTranslation();
  const [data, setData] = useState(false);
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true); // حالة التحميل

  const fetchData = async () => {
    setLoading(true); // بدء التحميل
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/edit/page/${id}`);
    if (data?.Success === true) {
      setData(data?.Response);
      fetchDocuments();
    }
    setTimeout(() => {
      setLoading(false); // انتهاء التحميل
    }, 500);
  };

  const fetchDocuments = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/documents/${id}`);
    if (data?.Success === true) {
      setDocuments(data?.Response);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };

  useEffect(() => {
    scrollToTop();
    fetchData();
  }, [i18n.language]);

  return (
    <div className='mb-8'>
      {/* عرض مؤشر التحميل */}
      {loading ? (
        <Spin tip="Loading..." size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }} />
      ) : (
        <>
          <div className="flex justify-content-  align-items-center" style={{ margin: '20px 0' }}>
            <div style={{
              width: '200px',
              height: '200px',
              borderRadius: '50%',
              border: '6px solid #F1592A',
              overflow: 'hidden',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <Image
                src={data.BrandLogo || 'https://via.placeholder.com/200'}
                alt="default-image"
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
              />
            </div>
          </div>

          <Divider dashed />

          <Descriptions
            title={<Title level={3} style={{ color: '#F1592A' }}>{t('brand_details')}</Title>}
            bordered
            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
            layout="vertical"
            className="mb-6"
            labelStyle={{ fontWeight: 'bold', fontSize: '16px', color: '#4B4B4B' }} // تحسين شكل العناوين ولونها
            contentStyle={{ fontSize: '14px', color: '#333' }} // تحسين شكل النصوص
          >
            <Descriptions.Item label={t('label_brand_name_en')}>
              <Text strong>{data?.BrandNameEn || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('label_brand_name_ar')}>
              <Text>{data?.BrandNameAr || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('label_brand_email')}>
              <Text>{data?.BrandEmail || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('tabel_brand_header_5')}>
              <Text>{data?.SalesPhone || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('tabel_brand_header_4')}>
              <Text>{data?.SalesName || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('desc_en')}>
              <Text>{data?.BrandDescEn || 'N/A'}</Text>
            </Descriptions.Item>
            <Descriptions.Item label={t('desc_ar')}>
              <Text>{data?.BrandDescAr || 'N/A'}</Text>
            </Descriptions.Item>
          </Descriptions>

          <Divider dashed />

          <Row className="mt-6">
            <Col xl={24} xxl={24} md={24} xs={24} sm={24}>
              <Title level={3} style={{ color: '#F1592A' }}>{t('Existing')}</Title>
              <Row gutter={[16, 16]}>
                {documents?.map((item, index) => (
                  <Col key={index} xs={24} sm={12} md={8} lg={6} className='mt-3'>
                    <Card
                      hoverable
                      cover={<Image src={item.BrandDocumentPath} alt={`document-${index}`} width="100%" height={300} style={{ objectFit: 'cover' }} />}
                    >
                      <Card.Meta title={item.DocumentTitle || `${t('document_1')}  #${index + 1}`} />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProfileBrand;
